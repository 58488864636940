import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { PreventNavigationService } from '../navigation-perventor.service';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '@app/_helperServices/can-navigate.guard';


@Component({
  selector: "navigation-preventor",
  templateUrl: "navigation-preventor.component.html"
})
export class NavigationPreventorComponent implements OnInit, OnDestroy, ComponentCanDeactivate  {
  constructor(public prevent: PreventNavigationService) {}

  ngOnInit() {}
 
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.prevent.getCurrentPreventStatus();
  }

  ngOnDestroy() {}
}
