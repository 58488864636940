<div>
        <div class="modal-content" style="padding:2px !important;    box-shadow: -10px 0px 10px 1px #aaaaaa;">
            <div class="modal-header" style="padding: 0px;    background: #7ed0ff;">
                <button class="close" aria-label="Close" (click)="partyPopClosed()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" style="text-align: center;">{{masterService.partyPopUpHeaderText}}</h4>
            </div>
            <div class="col-md-12">
               
             <input #inputBox type="text" id="partysearchpopup" autofocus  style="width: 100%;/* padding: 1px; */padding-left: 0px;margin-left: -13px;"
                  [(ngModel)]="filter" (keydown)="onKeydown($event)">
         </div>
            <div class="col-md-12">
                <table style="padding: 0px;margin-left: -12px;margin-top: 2px;width: 100%;" class="Table1">
                    <tr style="background: #7ed0ff;">
                        <th style="width:60%;height: 26px;">Name</th>
                        <th>Code</th>
                        <th>Address</th> 
                        <th>Email</th>             
                    </tr>
                    <tbody *ngFor="let itemObj of partyList |filter:filter ;let i=index" [class.highlighted]="i === selectedRowIndex"
                         (dblclick)="dblClickItemList(itemObj,i)">
                        <tr class="highlight">
                            <td style="padding-top:2px;border-bottom: 1px solid #ddd;height: 23px;">{{itemObj.ACNAME}}</td>
                            <td style="border-bottom: 1px solid #ddd;height: 23px;">{{itemObj.ACCODE}}</td>
                            <td style="border-bottom: 1px solid #ddd;height: 23px;">{{itemObj.ADDRESS}}</td>
                            <td style="border-bottom: 1px solid #ddd;height: 23px;">{{itemObj.EMAIL}}</td>
                        </tr>
                    </tbody>
                </table>
       
            </div>
       
        </div>
        </div>