<style>
  .modal-content {
    padding: 1px;
    margin: 0px;
    float: right;
    width: auto;
  }

  tbody>tr:hover {
    background-color: #f3f5f4;
    cursor: pointer;
  }

  tr {
    font-size: 11px;
  }

  .selected-row {
    background-color: #ced6d3 !important;
  }

  .table>tbody>tr>td {
    line-height: 24px !important;
    padding: 5px 10px;
    vertical-align: middle;
  }

  .table>thead>tr>th {
    line-height: 5px !important;
  }

  .table>tbody>tr>td:first-child {
    text-align: left !important;
    padding: 0px 0px 0px 11px !important;
  }

  .modal-content {
    box-shadow: -10px 0px 10px 1px #aaaaaa;
  }

  .modal-content-main-area {
    max-height: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .modal-content-summary-area {
    max-height: 20%;
    height: 20%;
    overflow: auto;
    overflow-x: hidden;
  }

  .ellipsis {
    /* width: 220px;
    text-overflow: ellipsis;
    overflow: hidden; */
    white-space: nowrap;
  }
</style>
<div class="modal-sticky" [class.modal-sticky-hide]="!isActive"
  style="display : block; padding: 0px;  margin-bottom: 25px;">
  <!-- Modal content -->
  <div class="modal-content modal-content-main-area">
    <div *ngIf="popupsettings">
      <div>
        <div class="modal-header" style="padding: 0px;background: #7ed0ff;">

          <h4 class="modal-title" style="padding-left: 5px;font-size: 1.2rem;">
            {{ popupsettings.title }}
          </h4>
          <button class="close" aria-label="Close" (click)="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="row">
          <div class="col-md-12 row">
            <div class="col-md-6" style="    margin: 2px 0px;
            display: flex;
            padding: 0px;">
              <select style="width: 100%;" [(ngModel)]="filterOption" (change)="triggerSearch($event)">
                <option value="" selected>Select Filter Option</option>
                <ng-container *ngFor="let item of popupsettings.columns">
                  <option *ngIf="!item.noSearch" [value]="item.key">{{
                    item.title
                  }}</option>
                </ng-container>
              </select>
            </div>
            <div class="col-md-6" style="margin: 2px 0px;
            display: flex;
            padding-right: 0px;">
              <input #inputBox type="text" style="width: 100%;" placeholder="Enter keyword to search"
                [formControl]="filterValue"/>
                <!-- <input #inputBox type="text" style="width: 100%;" placeholder="Enter keyword to search"
                [(ngModel)] = "filterValues" (ngModelChange)="changeFilterValue($event)" /> -->
            </div>
          </div>
        </div>
        <div class="col-md-12 table-scroll">
          <table class="table">
            <thead>
              <tr style="background: #7ed0ff;">
                <ng-container *ngFor="let item of popupsettings.columns">
                  <th *ngIf="!item.hidden" [ngStyle]="{'text-align':item.alignment}">{{ item.title }}</th>
                </ng-container>
                <th *ngIf="popupsettings.showActionButton && popupsettings.actionKeys.length">Action</th>
              </tr>
            </thead>
            <tbody *ngIf="itemList.length">
              <tr class="highlight" [class.selected-row]="selectedRowIndex == i" *ngFor="
                  let itemObj of itemList
                    | paginate
                      : {
                          id: tabindex,
                          itemsPerPage: pageSize,
                          currentPage: pageNumber,
                          totalItems: totalItems
                        };
                  let i = index
                " (dblclick)="doubleClick(itemObj)" (click)="singleClick(i)" title="double click for detail view">
                <ng-container *ngFor="let item of popupsettings.columns">
                  <td *ngIf="!item.hidden"  [ngStyle]="{'text-align':item.alignment}">
                    <div *ngIf="!item.pipe && !item.format" title="{{ itemObj[item.key] }}">
                      {{ itemObj[item.key] }}
                    </div>
                    <div *ngIf="item.pipe" title="{{ itemObj[item.key] }}">
                      {{ itemObj[item.key] | currency:'INR':'Rs. ' }}
                    </div>
                    <div *ngIf="item.format" title="{{ itemObj[item.key] }}">
                      {{ itemObj[item.key] | date:'yyyy-MM-dd' }}
                    </div>
                  </td>
                </ng-container>


                <ng-container *ngIf="popupsettings.showActionButton && popupsettings.actionKeys.length">
                  <td>
                    <ng-container *ngFor="let action of popupsettings.actionKeys;let z=index">
                      <span>
                        <i class="{{action.icon}}" (click)="onActionClicked(action.text,i)"
                          title="{{action.title}}"></i>
                      </span>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
          <div class="row" style="float: center; margin : 4px 0px;">
            <div class="col-md-12" style="width:100%;margin-top: -1.8rem;" *ngIf="itemList.length">
              <pagination-controls [id]="tabindex" (pageChange)="onPageChange($event)" maxSize="9" directionLinks="true"
                autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next"
                screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page" style="margin-left: 6rem;
                margin-bottom: 0;
                font-size: 0.8rem;"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-left: 25px; margin-top: -25px;
      font-size: 12px;" *ngIf="summary" [innerHTML]="summary"></div>
    </div>
  </div>
</div>