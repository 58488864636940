import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { MasterRepo } from '@app/_services/masterRepo.service';
@Component(
    {
        selector: 'PBatchList',
        templateUrl: './PopBatch.html',
    }
)
export class PopBatchComponent {
    @Input() title: string;
    @Input() tabindex;
    @Input() inputSearch: string;


    @Output() dbClickEvent = new EventEmitter();
    @Output() BatchClosedClick = new EventEmitter();
    @Output() searchItem = new EventEmitter();
    @Output() returnBatchValue = new EventEmitter();
    @Output() multiBatchScheme = new EventEmitter();
    @ViewChild("BatchEntry") _BE: ElementRef;
    @ViewChild("SearchBox") _Search: ElementRef;

    /** Object Declaration  */
    selectPopupRowList: Function;
    selectedRow: number;
    selectedRowIndex: number;
    isFocus: boolean = true;

    /** List Declaration  */
    p: number[] = [];
    setClickedRow: Function;
    BatchObj: any = <any>{};
    BatchList: any[] = [];
    @Input() BList: any[] = [];
    unitList: any[] = [];
    @Input() voucherType: any;
    @Input() activerowIndex: number;
    @Input() AlternateUnits: any[] = [];
    @Input() activeurlpath:string;
    public filter: string;
    userSetting: any;
    keys: string[];
    headerKeys: string[];
    constructor(
        private masterRepo: MasterRepo,
        private _trnMainService: TransactionService
    ) {
        this.userSetting = this.masterRepo.userSetting;
        console.log("in popnatch", this.userSetting);
        this.selectedRowIndex = 0;
        this.setClickedRow = function (index) {
            this.selectedRow = index;
        }
    }
    ngAfterViewInit() {
    }
    ngOnInit() {
        setTimeout(()=>{
            if(document.getElementById('batchFilterPopup' + this.activerowIndex) != null){
                document.getElementById("batchFilterPopup").focus();
            }
            
        }, 1000);
    }
    SelectUnit() {
        this.BatchObj.UNIT = this.BatchObj.ALTUNITObj.ALTUNIT;
        this.BatchObj.PRATE = this.BatchObj.ALTUNITObj.PRATE;
        this.BatchObj.SRATE = this.BatchObj.ALTUNITObj.RATE;
    }
    pagingChange(value) {
        this.p[this.tabindex] = value;
    }
    setFocus() {
    }
    SearchList(value) {
    }



    PopupItemListRowClick(index) {
        this.selectedRowIndex = index;
    }

    itemBatchClosed() {
        this.BatchClosedClick.emit();
    }
    keydownClick() {

    }
    AddNewBatch() {
    }

    tabClick() {
        this.returnBatchValue.emit(this.BatchObj);
    }
    clickPlusButton() {
        this.tabClick()
    }

    needtoShowBatchColumn():boolean{

            if(this.userSetting.EnableBatch ==2){
                return true;
            }

            if(this.voucherType == 14 || this.voucherType== 1){
                return false;
            }else{                     
                if(this.BatchList[0]?.REQEXPDATE ===1 || this.activeurlpath == 'self-billing'){
                    return true;
                }
            }
            return true;
    }

    clickButtonClick() {
        if (this.BatchObj.BATCH == null) {
            alert("Please enter Batch no.");
            return;
        }
        else if (this.BatchObj.MFGDATE > this.BatchObj.EXPIRY
        ) {
            alert("Manufacture date cannot be greater than Expiry date!!")
            return;
        }
        else if (this.BatchObj.SRATE > this.BatchObj.MRP) {
        }
        else if (this.BatchObj.MFGDATE == null || this.BatchObj.EXPIRY == null) {
            alert("MFGDate and ExpDate cannot be empty!")
            return;
        }
        this.tabClick()


    }

    
    RowClickEvent(index) {
        this.selectedRowIndex = index;
    }
    
    dblClickBatchObj(value) {
        this.dbClickEvent.emit(value)
    }
    MultiBatchEntryClicked() {
        var filteredList = this.BList.filter(x => x.tQuantity > 0);
        var firstBatch = filteredList[0];
        if (firstBatch == null) { alert("Row with valid Quantity not detected..."); return; }
        if (filteredList.filter(x => x.SCHEME.schemeID != firstBatch.SCHEME.schemeID)[0] != null) {
            alert("Can not Choose multi batch of different Scheme...");
            return;
        }
        if (filteredList.filter(x => x.STOCK < x.tQuantity)[0] != null) {
            alert("Exceed Quantity than Stock detected...");
            return;
        }
        this.multiBatchScheme.emit(filteredList);
    }
    @HostListener("document : keydown", ["$event"])
    updown($event: KeyboardEvent) {
        if (this.selectedRowIndex == null) this.selectedRowIndex = 0;

        if ($event.code === "ArrowDown") {
            this.selectedRowIndex = this.selectedRowIndex + 1;
            if (this.selectedRowIndex > (this.BList.length - 1)) this.selectedRowIndex = this.BList.length - 1;
        }
        else if ($event.code === "ArrowUp") {
            this.selectedRowIndex = this.selectedRowIndex - 1;
            if (this.selectedRowIndex < 0) this.selectedRowIndex = 0;
        }
        if ($event.code === "Enter" || $event.code == "NumpadEnter") {
            if (this.selectedRowIndex != null) {
                if (this.BList[this.selectedRowIndex] != null) {
                    this.dblClickBatchObj(this.BList[this.selectedRowIndex]);
                }
            }
        } else if ($event.code === "Escape") {
            this.itemBatchClosed();
        }


    }

    ngOnChanges(changes: any) {
        if (changes && changes.BList) {
            this.BList = changes.BList.currentValue;
            // this.keys = [];
            this.headerKeys = [];
            this.BList.forEach(x => {
                if (x.hasOwnProperty("VARIANTDETAIL") && x.VARIANTDETAIL != null) {

                    x.VARIANTDETAIL = x.VARIANTDETAIL;
                } else {
                    x['VARIANTDETAIL'] = null;
                }
            });

            this.BList.forEach(batch => {
                batch.VariantValues = <any>[];

                for (var attribute in batch.VARIANTDETAIL) {
                    let attr = attribute.toUpperCase()
                    if (this.headerKeys.indexOf(attr) == -1 ) {
                    this.headerKeys.push(attr);
                    }
                    batch.VariantValues.push(batch.VARIANTDETAIL[attribute]);
                }

            });
        }
    }

    getAttributeValues = (BObject, key): string => {
        if (BObject.VARIANTDETAIL != null && BObject.VARIANTDETAIL[key] && BObject.VARIANTDETAIL[key].hasOwnProperty("NAME")) {
            return BObject.VARIANTDETAIL[key].NAME;

        } else {
            return "";
        }
    }
}