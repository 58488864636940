<style>
    td {
      border: 1px solid #e6e6e6;
    }
  
    input {
      padding: 8px;
    }
  
    .form-group {
      margin-bottom: 0rem;
    }
  
    .party_container {
      position: fixed !important;
      transform: translate(0px, -35px);
      background: #ffffff;
      z-index: 99999;
      width: 77%;
      top: 22.5%;
      left: 10%;
    }
   
  </style>
  
  
  <div *ngIf="isHelpActive" class="party_container" role="document" 
    style="max-width: 100%; max-height: 500px; overflow:hidden">
    <div class="modal-content" style="padding: 14px 16px 10px 16px;margin: 0;width: 77vw; height:70vh;">
  
      <div class="modal-header row">
        <div class="col-md-5">
          <!-- background-color: #e9e9e9 -->
          <ul class="nav nav-tabs " style="     background-color: #17a2b8 !important;height: 33px;  width:95px;">
            <!-- width: 413px; -->
  
            <!-- <li class="nav-item " style="padding-top: 5px;padding-left: 10px;">
              <a class="nav-link"  data-toggle="tab"  href="#Description" style="color:black">Description</a>
            </li> -->
            <!-- <li class="nav-item" style="padding-top: 5px;padding-left: 10px;">
              <a  class="nav-link"  data-toggle="tab"  href="#LabelInputs" style="color:black">Label and Inputs</a>
            </li> -->
            <!-- <li class="nav-item" style="padding-top: 5px;padding-left: 10px;">
              <a class="nav-link"  data-toggle="tab"  href="#KeysFeatures" style="color:black">Keys & Features</a>
            </li> -->

            <li class="nav-item" style="padding-top:2px; padding-left: 10px;">
              <a class="nav-link"  data-toggle="tab"  href="#Settings" style="color:black; font-size: 15px;font-weight: 500;">Settings</a>
       
            </li>

          </ul>
        </div>
        <div class="col-md-2"> 
          B.N. {{appVersion}}
        </div>

        <div class="col-md-5"> 
          <button type="button" class="btn btn-info pull-right" style="width:80px;"
            (click)="cancelHelpPopup()">Cancel</button>
      
        </div>

      </div>
      <div class="tab-content">
        <div class="tab-pane active" id="Settings">  
          <h4>Title</h4>    
            <div class="col-md-12 row" style="width: 100%;">
              
              <div style="overflow-y: auto; width: 101%; height:379px">
                <table style="margin-bottom: 5px; font-size: 13px; ">
                  <thead>
                    <tr 
                      style="
                        border-bottom: 2px solid #e6e6e6;
                        border-top: 2px solid #e6e6e6;
                        height: 30px;
                        background: #17a2b8;
                        color: black;">
  
                      <th style="width: 1%; text-align:center;">SNo.</th>
                      <th style="width: 25%; padding-left: 1%; text-align:center;">Setting</th>
                      <th style="width: 45%; padding-left: 1% ;text-align:center;">Description</th>
                      <th style="width: 25%; padding-left: 1%; text-align:center;">Form Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr   *ngFor="let user of SettingList let i = index" style="border: 1px; max-height: 25px">
  
                      <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                      {{i+1}}
                      </td>
          
                      <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                        <!-- <input class="form-control"style="height: 25px; width: 95% !important"/> -->
                        {{user.COLUMN_NAME}}
                      </td>
                      <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                        <input class="form-control" style="height: 25px; width: 95% !important"  [(ngModel)]="user.DESCRIPTION" /> 
                        <!-- {{user.DESCRIPTION}} -->
                      </td>
                      <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                        <select style="height: 25px;  " class="form-control input-text">
                          <option></option>
                          <option></option>
                        </select>
                      </td>
                    </tr>
               
                  </tbody>
                </table>
  
              </div>
            </div>
          
        </div>


        <div class="tab-pane" id="LabelInputs">
          <h4>Master Area</h4>
          <div class="col-md-12 ">
            <div>
              <table style="margin-bottom: 5px; font-size: 13px; width:99%">
                <thead>
                  <tr
                    style="
                      border-bottom: 2px solid #e6e6e6;
                      border-top: 2px solid #e6e6e6;
                      height: 30px;
                      background: #17a2b8;
                      color: black;">

                    <th style="width: 1%; text-align:center;">SNo.</th>
                    <th style="width: 4%; padding-left: 1%; text-align:center;">Label</th>
                    <th style="width: 4%; padding-left: 1% ;text-align:center;">Input Type</th>
                    <th style="width:20%; padding-left: 1%; text-align:center;">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border: 1px; max-height: 25px">

                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">
                    1
                    </td>
        
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control"style="height: 25px; width: 95% !important"/> --> PI No.
                    </td>
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control" style="height: 25px; width: 95% !important"/> --> Label
                    </td>
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;"> Display the cureent PI No. of Vouchers
                      <!-- <input class="form-control" style="height: 25px; width: 95% !important"type="text"/> --> 
                    </td>
                  </tr>
                  <tr style="border: 1px; max-height: 25px">
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">2</td>
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">Entry Date </td>
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">Date</td>
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">Shows Current date in AD and BS. It can be changed into AD to Bs or Vice Versa</td>
                  </tr>

                  <tr style="border: 1px; max-height: 25px">
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">3</td>
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">Is Pan Bill</td>
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">Checkbox</td>
                    <td style="line-height: 21px; text-align: Left; border: 1px solid #e6e6e6;">By clicking on checkbox, one insure voucher is a pan bill</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

          <h4>Transaction</h4>
          <div class="col-md-12">
            <div>
              <table style="margin-bottom: 5px; font-size: 13px; width:99%">
                <thead>
                  <tr
                    style="
                      border-bottom: 2px solid #e6e6e6;
                      border-top: 2px solid #e6e6e6;
                      height: 30px;
                      background: #17a2b8;
                      color: black;">

                    <th style="width: 1%; text-align:center;">SNo.</th>
                    <th style="width: 4%; padding-left: 1%; text-align:center;">Label</th>
                    <th style="width: 4%; padding-left: 1% ;text-align:center;">Input Type</th>
                    <th style="width: 20%; padding-left: 1%; text-align:center;">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border: 1px; max-height: 25px">

                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                    1
                    </td>
        
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control"style="height: 25px; width: 95% !important"/> --> Code
                    </td>
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control" style="height: 25px; width: 95% !important"/> --> Label
                    </td>
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;"> Displays the Item Code
                      <!-- <input class="form-control" style="height: 25px; width: 95% !important"type="text"/> --> 
                    </td>
                  </tr>
                  <tr style="border: 1px; max-height: 25px">
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">2</td>
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">Description</td>
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">Popup</td>
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">Enter on Input box for popup List</td>
                  </tr>

                  <tr style="border: 1px; max-height: 25px">
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">3</td>
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">Unit</td>
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">Drop Dowwn</td>
                    <td style="line-height: 21px; text-align: left; border: 1px solid #e6e6e6;">Select the Unit of List</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>
        


        <div class="tab-pane" id="KeysFeatures">
          <div class="col-md-12 row">
            <div class= "col-md-4" style="width: 25%;">
              <input type="search" class="form-control" style="height: 1.8rem; margin-top: 8px ;" placeholder="Type to Search in Key Bindings" name="filter" [(ngModel)]="filter">
            </div>
          </div>
        </div>



        <div class="tab-pane" >
          <!-- id="Settings" -->
          <div  class="col-md-12"
          style="display: flex;">
            <h4>Title</h4>
            <div class= "col-md-4" style="width: 25%;">
              <input type="search" class="form-control" style="height: 1.5rem; margin-top: 8px ;" placeholder="Search..." name="filter" [(ngModel)]="filter">
            </div>

          </div>
          
          <div class="col-md-12 row">
            <div>
              <table style="margin-bottom: 5px; font-size: 13px; width:240%">
                <thead>
                  <tr
                    style="
                      border-bottom: 2px solid #e6e6e6;
                      border-top: 2px solid #e6e6e6;
                      height: 30px;
                      background: #17a2b8;
                      color: black;">

                    <th style="width: 1%; text-align:center;">SNo.</th>
                    <th style="width: 10%; padding-left: 1%; text-align:center;">Name</th>
                    <th style="width: 7%; padding-left: 1% ;text-align:center;">Setting Name</th>
                    <th style="width: 7%; padding-left: 1%; text-align:center;">Status</th>
                    <th style="width: 7%; padding-left: 1%; text-align:center;">Dependency</th>
                    <th style="width: 7%; padding-left: 1%; text-align:center;">Remarks</th>

                  </tr>
                </thead>
                <tbody>
                  <tr style="border: 1px; max-height: 25px">

                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">
                    1
                    </td>
        
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control"style="height: 25px; width: 95% !important"/> --> currency
                    </td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control" style="height: 25px; width: 95% !important"/> --> ENABLEMULTICURRENCY
                    </td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control" style="height: 25px; width: 95% !important"type="text"/> --> 1
                    </td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control" style="height: 25px; width: 95% !important"type="text"/> -->
                    </td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">
                      <!-- <input class="form-control" style="height: 25px; width: 95% !important"type="text"/> -->
                    </td>
                    
                  </tr>
                  <tr style="border: 1px; max-height: 25px">
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">2</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">Bill format</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">ENABLEMULTISPRINTFORMAT</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">1</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;"></td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;"></td>
                  </tr>

                  <tr style="border: 1px; max-height: 25px">
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">3</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">Ref No</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">ENABLEMULTISPRINTFORMAT</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">1</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">PI,PO</td>
                    <td style="line-height: 21px; text-align: center; border: 1px solid #e6e6e6;">Show Prefix</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      
      </div>
  </div>
  
  </div>
