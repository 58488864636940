import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login';
import { AuthGuard } from './_helperServices';
import { NgModule } from '@angular/core';

const routes: Routes = [
    { path: '', redirectTo:'/login',pathMatch:"full" },
    { path: 'pages', loadChildren: './pages/pages.module#PagesModule', canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },

    // otherwise redirect to pages
    { path: '**', redirectTo: 'pages' }
    
];


@NgModule({
    imports: [RouterModule.forRoot(routes,{ preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled', useHash: true, relativeLinkResolution: 'legacy' })],

    exports: [RouterModule]
})

export class AppRoutingModule {
}
