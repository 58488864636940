<div>
 <div class="modal-content" style="padding:2px !important;    box-shadow: -10px 0px 10px 1px #aaaaaa;">
     <div class="modal-header" style="padding: 0px;    background: #7ed0ff;">
         <button class="close" aria-label="Close" (click)="schemeListClosed()">
             <span aria-hidden="true">&times;</span>
         </button>
         <h4 class="modal-title" style="text-align: center;">{{title}}</h4>
     </div>
     <div class="col-md-12">
         <table style="padding: 0px;margin-left: -12px;margin-top: 2px;width: 100%;" class="Table1">
             <tr style="background: #7ed0ff;">
                 <th style="width:60%;height: 26px;">Scheme Name</th>
                 <th>Scheme Type</th>
                 <th>Discount</th>
                 <th>Dis. Type</th>
             </tr>
             <tbody *ngFor="let itemObj of schemeList ;let i=index" [class.active]="i == selectedRow"
                  (dblclick)="dblClickItemList(itemObj)">
                 <tr class="highlight">
                     <td style="padding-top:2px;border-bottom: 1px solid #ddd;height: 23px;">{{itemObj.schemeName}}</td>
                     <td style="border-bottom: 1px solid #ddd;height: 23px;">{{itemObj.schemeType}}</td>
                     <td style="border-bottom: 1px solid #ddd;height: 23px;">{{itemObj.discountRateType==2?'Buy '+ itemObj.minQty + 'Get '+itemObj.schemeDisRate +' free': itemObj.schemeDisRate}}</td>
                     <td style="border-bottom: 1px solid #ddd;height: 23px;">{{itemObj.discountRateType==0?'%':itemObj.discountRateType==1?'Amount':itemObj.discountRateType==2?'Quantity':'Type Not Found'}}</td>
                 </tr>
             </tbody>
         </table>

     </div>

 </div>
 </div>