<div class="modal fade" bsModal #alertModeal="bs-modal" [config]="{ backdrop: 'static' }" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true" style="z-index: 9999999999999;height: 100%;background: rgba(0, 0, 0, 0.43);">
  <div class="modal-dialog modal-sm" *ngIf="message" style="   min-width: 475px; position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);">
    <div class="modal-content" style="min-height: 200px;">
      <div class="modal-header" style="border: none;">

        <h5 class="modal-title" *ngIf="displayHeading">
          <i *ngIf="message.type == 'success'" class="fa fa-check-square" aria-hidden="true" style="color: green"></i>
          <i *ngIf="message.type == 'warning'" class="fa fa-exclamation-triangle" aria-hidden="true" style="color: yellow"></i>
          <i *ngIf="message.type == 'error'" class="fa fa-exclamation-triangle" aria-hidden="true"
            style="color: rgb(212, 149, 149)"></i>
          <i *ngIf="message.type == 'info'" class="fa fa-exclamation-triangle" aria-hidden="true"
            style="color: rgb(169, 195, 233)"></i>
          {{ displayHeading }}
        </h5>
        <button class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="displayMessage">{{ displayMessage }}</p>
        <span *ngIf="displayLink" [innerHTML]="displayLink" style="text-decoration: underline;"></span>
      </div>
      <div class="modal-footer" style="border: none;">
        <button type="button" class="btn btn-info" (click)="close()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>