import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/_helperServices/authService.service';
import { GlobalState } from '@app/_helperServices/global.state';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({providedIn:'root'})
export class NotificationService {

  idForUPdate:any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private state: GlobalState
  ) {}

  private get apiUrl(): string {
   // let url = this.state.getGlobalSetting("apiUrl");    
    let url = environment.apiUrl;

    

    let apiUrl = "";
    if (!!url && url.length > 0) {
      apiUrl = url;
    }
    return apiUrl;
  }
  getNotificationCount() {
    
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject(); 
    this.http
      .get(this.apiUrl + "/getNotificationCount")
      .subscribe(
        data => {
          if (data['status'] == "ok") {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = "error";
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject; 
  }

  public markAsRead(id : number) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject(); 
    this.http
      .get(`${this.apiUrl}/notificationMarkAsRead/${id}`)
      .subscribe(
        retData => {
          if (retData['status'] == "ok") {
            res.status = "ok";
            res.result = retData['result']; 
            this.idForUPdate = null;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData['result']; 
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }


  markAsReadAfterSave(){
    return this.markAsRead(this.idForUPdate);
  }
}
