import { Component, OnInit, Inject, HostListener, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit} from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { VoucherTypeEnum } from '@app/common/interfaces/TrnMain';
import { environment } from '@environments/environment';

@Component({
  selector: 'create-customer-dialog',
  templateUrl: './create-customer-dialog.component.html',
  styleUrls: ['./create-customer-dialog.component.css'],
})
export class CreateCustomerDialogComponents implements OnInit {
  form: FormGroup;
  isSubmitting = false;
  isSubmitted = false;
  @Output('onSave') onSave = new EventEmitter();
  isActive:boolean;
  @ViewChild('customerName') customerName: ElementRef;
  mode:string = "add";
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;
  activerowIndex: number = 0;
  AppMode: string;

  constructor(
    public fb: FormBuilder, public masterservice: MasterRepo,
    public _trnMainService: TransactionService, 
    private alertService: AlertService,
    public spinnerService:SpinnerService
  ) {
    this.AppMode = environment.appMode;
    this.masterservice.getAllDivisions().subscribe((res:any) =>{
      this.dropdownList = res;
      
    });
    this.forminit();
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'INITIAL',
      textField: 'NAME',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      maxHeight: 100
    };
   }


  forminit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      address: [''],
      pan: ['', [Validators.minLength(9), Validators.maxLength(9)]],
      phoneNo: [''],
      mobNo: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      email: [''],
      createMember : [''],
      MOBILENO_REG_IN: [''],
      FurtherProceed:[''],
      divisionMapping:[''],
      STATUS: [1]
    });

    if(this.masterservice.userSetting.CREATE_CPROFILE_AS_MEMBER == 1){
      this.form.controls['createMember'].patchValue(1);
    }else{
      this.form.controls['createMember'].patchValue(0);
    }
  }

  submit(fd) {
    this.isSubmitting = true;  
    if(this.masterservice.userSetting.CREATE_CPROFILE_AS_MEMBER == 1 && this.form.value.createMember==1   && (this._trnMainService.TrnMainObj.VoucherType !==  14 && this._trnMainService.TrnMainObj.VoucherType !==  1)){
      if(this.form.value.mobNo==''){
        this.alertService.info("Please Enter Mobile No. First!")
        return;
      }
    }
    if(this.form.value.mobNo=='' ||this.form.value.mobNo===null||this.form.value.mobNo===undefined){
      this.form.value.MOBILENO_REG_IN='';
    }
    this.spinnerService.show("Please Wait Data is Saving");
    console.log("saveing cutomer prodile", this.form.value);
    this.masterservice.saveCustomerProfile(this.form.value,this.mode).subscribe(
      res => {
        if (res['status'] == 'ok') {          
          this.closeDialog();
          this.spinnerService.hide();
          if(res['result']=='ProceedConfirmation'){
            if(confirm("Member is not Created in  Server.Do you want to Process..")){
              this.form.value.FurtherProceed = 'ProceedConfirmationYes';
              this.submit(this.form.value);
            }else{
              this.onclear();
              this.alertService.hide();
            }
          }else{
            this.onclear();
            this.alertService.info(res['result']);
            setTimeout(()=>{
              this.alertService.hide();
            },500);
            this.alertService.emitShowHideSubject.subscribe(
              (res) => {
                setTimeout(() => {
                  document.getElementById("barcode" + this.activerowIndex).focus();
                }, 0);
              }
            );
            this.onSave.emit(res['result2']);
          
          }      
   
        }
        else {
          this.alertService.error(res['result'])
          this.spinnerService.hide();
        }
      },
      error =>{
        this.spinnerService.hide();
      }
    )
  }

  onclear() {
    this.form.reset();
    if(this.masterservice.userSetting.CREATE_CPROFILE_AS_MEMBER == 1){
      this.form.controls['createMember'].patchValue(true);
    }
  }
  closeDialog() {
    this.isActive=false;
    this.masterservice.customerpopup = false;
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode((event as KeyboardEvent).charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  show() {
    this.isActive = true;
    this.masterservice.customerpopup = true;
    setTimeout(() => {
      this.customerName.nativeElement.focus();
    }, 10);
}



hide() {
    this.isActive = false;
    this.masterservice.customerpopup = false;
}

  @HostListener("document : keydown", ["$event"])
  handleKeyDownboardEvent($event: KeyboardEvent) {
    if ($event.code == "Escape") {
      $event.preventDefault();
        this.closeDialog();
    }
    if ($event.code == "End") {
      if(!this.form.invalid){
        $event.preventDefault();
        this.submit(this.form.value);
      }
      
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
}
