import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';

@Component(
    {
        selector: 'PSalesSchemeList',
        templateUrl: './popscheme.html',

    }
)
export class PopSchemeChooserComponent {
    /** Input/Output  */
    @Output() dbClickEvent = new EventEmitter();
    @Output() schemeCloseClick = new EventEmitter();
    @ViewChild("inputBox") _el: ElementRef;
    @Input() schemeList: any[] = [];
    title:string="Available Active Schemes on Item";
    constructor(public masterService: MasterRepo,public _trnMainService: TransactionService) {
    }
    ngAfterViewInit(){
      
    }
    ngOnInit() {
        
    }
   
    SearchList(value) {
        // this.itemChanged(value);

    }

    
    setFocus() {
        this._el.nativeElement.focus();
        }
  
    dblClickItemList(value) {
        this.dbClickEvent.emit(value);

    }
    schemeListClosed() {
        this.schemeCloseClick.emit();
    }
}