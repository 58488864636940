import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'login-dialog',
    styleUrls: ['./logindialog.component.css'],
    template: ``
})
export class LoginDialog {
    constructor(public dialogref: MatDialogRef<LoginDialog>) {

    }

    public loginResult(res) {
        this.dialogref.close(res);
    }

}