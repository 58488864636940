import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs/Observable';

export interface DialogInfo {
    header: string;
    message: Observable<string>;
}

@Component({
    selector: 'result-dialog',
    template: `
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header custom-modal-header">
                <h4 class="modal-title">{{heading}}</h4>
                <div class="close" aria-label="Close" (click)="authResult(true)">&times;</div>
                </div>

                <div class="modal-body">
                    <div style="display:inline-block"></div>
                    {{message$|async}}
                </div>
            </div>
        </div>
`
})
export class MessageDialog {
    heading = 'Information';
    message$: Observable<string>;

    constructor(public dialogref: MatDialogRef<MessageDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogInfo) {
        this.heading = data.header;
        this.message$ = data.message;
    }

    public authResult(res) {
        this.dialogref.close(res);
    }

}