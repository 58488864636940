import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { AuthService } from '@app/_helperServices/authService.service';

@Component({
  selector: 'load-purchase-order',
  templateUrl: './load-purchase-order.component.html',
  styles: [`
      input,
      select,
      textarea {
        border: 1px solid #cbcbcb;
        border-radius: 3px;
        height: 23px;
        color: black;
      }
    `]
})

export class LoadPurchaseOrderComponent implements OnInit {

  public mode: string;
  isActive: boolean;
  sameQty:boolean;
  sameRate:boolean;
  userSetting:any;
  
  @Output('onLoadPO') onLoadPO = new EventEmitter();

  constructor(public masterService: MasterRepo,
    private alertService: AlertService,
    public _trnMainService: TransactionService,
    public spinnerService: SpinnerService,
    private _authService: AuthService,
  ) { 
    this.userSetting = _authService.getSetting()
  }


  ngOnInit() {
  }

  show() {
    this.isActive = true;
  }

  hide() {
    this.isActive = false;
  }

  CalculateAmount(i) {
    
    if (this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE) {
      this._trnMainService.TrnMainObj.PODetailList[i].AMOUNT = this._trnMainService.TrnMainObj.PODetailList[i].DELIVERQTY * this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE;
    } else {
      // this.alertService.info("Please Enter Deliver Rate First !");
      // return;
    }
  }

  ValidateDeliverRate(i) {
    if(this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE){
      if (this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE < this._trnMainService.TrnMainObj.PODetailList[i].RATE) {
        if (confirm("Deliver Rate is less than Order Rate.Do you want to continue?")) {
          if(this.masterService.is_PurchaseOrder == true&& this.userSetting.HALTSPRICECHAGEDASKED === 1 ){
            this._trnMainService.showPriceChangeDetail = true;
            this._trnMainService.showPriceChangeDetailINDisc = true;
            this._trnMainService.getAllSelingPriceOfItem(i);  
           }
           this.CalculateAmount(i);
  
        }
        else {
          return;
        }
      }
    }
    
    if (this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE > this._trnMainService.TrnMainObj.PODetailList[i].RATE) {
      this.alertService.info("Deliver Rate is greater than Order Rate");
      this.alertService.emitShowHideSubject.subscribe(
              (res) => {
                setTimeout(() => {
                  this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE=0;
                  this.CalculateAmount(i);
                }, 0);
              }
            )
      return;
    }

    this.CalculateAmount(i);
  }

  
  ValidateDeliverQty(i) {
    if(this._trnMainService.TrnMainObj.PODetailList[i].DELIVERQTY){
      if (this._trnMainService.TrnMainObj.PODetailList[i].DELIVERQTY < this._trnMainService.TrnMainObj.PODetailList[i].ORDERQTY) {
        if (confirm("Deliver Qty is less than Order Qty.Do you want to continue?")) {
          this.CalculateAmount(i);
        }
        else {
          return;
        }
      }
    }
   
    if (this._trnMainService.TrnMainObj.PODetailList[i].DELIVERQTY > this._trnMainService.TrnMainObj.PODetailList[i].ORDERQTY) {
      this.alertService.info("Deliver Qty is greater than Order Qty");
      this.alertService.emitShowHideSubject.subscribe(
        (res) => {
          setTimeout(() => {
            this._trnMainService.TrnMainObj.PODetailList[i].DELIVERQTY=0;
            this.CalculateAmount(i);
          }, 0);
        }
      )
      return;
    }

    this.CalculateAmount(i);
  }

  OkCommand() {
    this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.MCODE=x.MCODE);
    this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.VCHRNO=this.masterService.PO_Voucherno);
    let RateChanged = this._trnMainService.TrnMainObj.PODetailList.filter(x=>x.DELIVERRATE==x.RATE);
   
    if(RateChanged.length > 0){
      for (let i in RateChanged) {
        if(RateChanged[i].DELIVERRATE == RateChanged[i].DELIVERRATE){
          if(confirm(`Same rate detected in ${RateChanged[i].DESCA} . Do you want to continue?`)){
          }else{
            return;
          }
        }
      }
    }
    let EmptyRate= this._trnMainService.TrnMainObj.PODetailList.filter(x=>x.DELIVERRATE==0 || x.DELIVERRATE==null || x.DELIVERRATE==undefined);
    if(EmptyRate.length>0){
      for (let i in EmptyRate) {
          if(confirm(`Rate not entered in ${EmptyRate[i].DESCA} . Do you want to assign it with Order Rate?`)){
          }else{
            return;
          }
      }
      EmptyRate.forEach(x=>x.DELIVERRATE = x.RATE);
      let EmptyQty= EmptyRate.filter(x=>x.DELIVERQTY==0 || x.DELIVERQTY==null || x.DELIVERQTY==undefined);
      if(EmptyQty.length>0){
        EmptyRate.forEach(x=>x.DELIVERQTY = x.ORDERQTY);
      }
      EmptyRate.forEach(x=>x.AMOUNT = x.DELIVERRATE * x.DELIVERQTY);
    }
    this.onLoadPO.emit(this._trnMainService.TrnMainObj.PODetailList);
  }

  QtySameAsOrderQty(sameQty){
    if(sameQty == true){
      this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.DELIVERQTY=x.ORDERQTY);
      this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.AMOUNT=x.DELIVERQTY*x.DELIVERRATE);
    }
    else{
      this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.DELIVERQTY=0);
      this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.AMOUNT=x.DELIVERQTY*x.DELIVERRATE);
    }
   
  }

  QtySameAsOrderRate(sameRate){
    if(sameRate == true){
      this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.DELIVERRATE=x.RATE);
      this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.AMOUNT=x.DELIVERQTY*x.DELIVERRATE);
    }
    else{
      this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.DELIVERRATE=0);
      this._trnMainService.TrnMainObj.PODetailList.forEach(x=>x.AMOUNT=x.DELIVERQTY*x.DELIVERRATE);
    }
    
  }

  DiscountPercentChange(i){
  this._trnMainService.TrnMainObj.PODetailList[i].DISCOUNT_AMOUNT = (this._trnMainService.TrnMainObj.PODetailList[i].DISCOUNT_RATE/100)*this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE; 
  this._trnMainService.TrnMainObj.PODetailList[i].NET_RATE = (this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE)-((this._trnMainService.TrnMainObj.PODetailList[i].DISCOUNT_RATE/100)*this._trnMainService.TrnMainObj.PODetailList[i].DELIVERRATE); 
  this._trnMainService.TrnMainObj.PODetailList[i].AMOUNT = this._trnMainService.TrnMainObj.PODetailList[i].NET_RATE  * this._trnMainService.TrnMainObj.PODetailList[i].DELIVERQTY;
  }

}