
<div class="simpleSelect" [ngStyle]="{'text-align':textAlign}" [ngClass]="{'down':align.toUpperCase()==='DOWN','top':align.toUpperCase()==='TOP'}"
  (click)="clickedInside($event)">

  <!-- <span class="search-lens-icon" aria-hidden="true">
    <svg viewBox="0 0 24 24" role="presentation" aria-hidden="true" focusable="false" style="height: 18px; width: 18px;display:block;fill:#767676;">
      <path d="m10.4 18.2c-4.2-.6-7.2-4.5-6.6-8.8.6-4.2 4.5-7.2 8.8-6.6 4.2.6 7.2 4.5 6.6 8.8-.6 4.2-4.6 7.2-8.8 6.6m12.6 3.8-5-5c1.4-1.4 2.3-3.1 2.6-5.2.7-5.1-2.8-9.7-7.8-10.5-5-.7-9.7 2.8-10.5 7.9-.7 5.1 2.8 9.7 7.8 10.5 2.5.4 4.9-.3 6.7-1.7v.1l5 5c .3.3.8.3 1.1 0s .4-.8.1-1.1"
        fill-rule="evenodd"></path>
    </svg>
  </span> -->
  <input type="text" autocomplete="off" id="ngx-simple-suggest"   (input)="filterWord($event.target.value)"  tabindex="0" (focus)="expand=true" (keypress)="expand=true" (keydown.arrowup)="keyUp()" (keydown.arrowdown)="keyDown()"
    (keydown.enter)="setSearchKeyword();expand=false" class=" simplesuggestformControl" [formControl]="search" [ngClass]="{'expanded':suggestions?.length && expand}" placeholder="{{default}}">



  <div class="optionsContainer" *ngIf="suggestions?.length && expand">
    <ul class="options">
      <li *ngFor="let option of suggestions; let i = index" [ngClass]="{'active':selectedIndex==i}" tabindex="0"
        (click)="setSearch(option);expand=false" (keypress)="search.setValue(option);expand=true">
        {{option[labelKey]}}
      </li>
    </ul>
  </div>
</div>