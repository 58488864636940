import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/_helperServices/authService.service';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { Router } from '@angular/router';
import { AlertService } from '../shared/alert/alert.service';

@Component({
  selector: 'inventry-yearend-dialog',
  templateUrl: './InventryYearEnd.component.html',
  styleUrls: ['./yearEndDialog.css']

})
export class InventryYearEndDialog {
  constructor(public dialogref: MatDialogRef<InventryYearEndDialog>, public authSerice: AuthService, public masterService: MasterRepo,
    public spinnerService: SpinnerService, public router: Router, public alertService: AlertService) {

  }
  YesClick() {
    if (this.masterService.userSetting.ENABLEOFFLINESALE === 1) { return; }
    this.spinnerService.show('Processing...Please Wait...')
    this.masterService.masterGetmethod('/yearEndingInventory').subscribe((res) => {
      if (res.status === 'ok') {
        this.authSerice.setSessionVariable('isIYE', 1);
        this.dialogref.close();
        this.alertService.success('The year end for inventory are done successfully...');
        setTimeout(() => {
          console.log("from logout");
          this.router.navigate(['login', { logout: 'logout' }]).then(() => {
            window.location.reload();
          });
        }, 5000);

      } else {
        this.spinnerService.hide();
        alert(res.result.error);
      }
    }, error => {
      this.spinnerService.hide();
      alert(JSON.parse(error._body));
    })

  }
  NoClick() {
    this.authSerice.setSessionVariable('isIYE', 2);
    this.dialogref.close();
  }
  offlinelineOK() {
    this.authSerice.setSessionVariable('isIYE', 2);
    this.dialogref.close();
  }

}