<style>
    td {
      border: 1px solid #e6e6e6;
    }
  
    input {
      padding: 8px;
    }
  
    .form-group {
      margin-bottom: 1rem;
    }
  
    .membership_container {
      position: fixed !important;
      transform: translate(0px, -35px);
      background: #ffffff;
      z-index: 99999;
      width: 65%;
      top: 22.5%;
      left: 16%;
    }

    label{
      
      font-size: 14px;
      margin-left: 5rem;
      margin-top: 10px;
    }
  </style>
  
  
  <div *ngIf="isActive" class="membership_container" role="document"
    style="max-width: 100%; max-height: 500px; overflow:hidden">
    <div class="card">
      <div class="card-header">
        <h5 class="page-title">CREATE MEMBERSHIP</h5>
        <div class="button-group">
          <form [formGroup]="membershipForm" (ngSubmit)="onSave()">
          <button title="Save" type="submit" class="btn btn-info"  
            style="margin-right:2px" [disabled]="!membershipForm.valid">SAVE</button>
          <button type="button" title="Cancel" class="btn btn-info" (click)="cancelPopup()">CANCEL</button>
          </form>
        </div>
      </div>

      <div class="card-body">
        <form class="row" [formGroup]="membershipForm" class="form-horizontal" style="margin-top: 15px;">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">First Name:</label>
            <div class="col-sm-5" style="padding-left: 1px;">
              <input  type="text"  class="form-control" formControlName="FNAME" autocomplete="off">
  
              <!-- <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)">
                <span *ngIf="form.controls['name'].errors.required">Name is required.</span>
              </div> -->
              <!-- Error before submitting -->
              <!-- <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['name'].invalid">
                <span *ngIf="form.controls['name'].errors.required">Name is required.</span>
              </div> -->
              <!-- Error after submitting -->
  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label" >Last Name:</label>
            <div class="col-sm-5" style="padding-left: 1px;">
              <input type="text" class="form-control" formControlName="LNAME" autocomplete="off">
  
              <!-- <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['address'].invalid && (form.controls['address'].dirty || form.controls['address'].touched)">
                <span *ngIf="form.controls['address'].errors.required">Address is required.</span>
              </div> -->
              <!-- Error before submitting -->
              <!-- <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['address'].invalid">
                <span *ngIf="form.controls['address'].errors.required">Address is required.</span>
              </div> -->
              <!-- Error after submitting -->
  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label" >Mobile No:</label>
            <div class="col-sm-5" style="padding-left: 1px;">
              <input type="text" class="form-control"   formControlName="MOBILE" autocomplete="off">
  
              <!-- <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['pan'].invalid && (form.controls['pan'].dirty || form.controls['pan'].touched)">
                <span *ngIf="form.controls['pan'].errors.required">Pan No is required.</span>
              </div> -->
              <!-- Error before submitting -->
              <!-- <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['pan'].invalid">
                <span *ngIf="form.controls['pan'].errors.required">Pan No is required.</span>
              </div>
              <div class="invalid-feedback d-block"
              *ngIf="!isSubmitting && form.controls['pan'].invalid && (form.controls['pan'].dirty || form.controls['pan'].touched)"> 
                <span *ngIf="form.controls['pan'].errors.minlength || form.controls['pan'].errors.maxlength">PAN No must be 9 digit long.</span>
              </div>  -->
              <!-- Error after submitting -->
  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label" >Address:</label>
            <div class="col-sm-5" style="padding-left: 1px;">
              <input type="text" class="form-control"  formControlName="ADDRESS" autocomplete="off">
  
              <!-- <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['phoneNo'].invalid && (form.controls['phoneNo'].dirty || form.controls['phoneNo'].touched)">
                <span *ngIf="form.controls['phoneNo'].errors.required">Phone No is required.</span>
              </div> -->
              <!-- Error before submitting -->
              <!-- <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['phoneNo'].invalid">
                <span *ngIf="form.controls['phoneNo'].errors.required">Phone No is required.</span>
              </div> -->
              <!-- Error after submitting -->
  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label" >Scheme:</label>
            <div class="col-sm-5" style="padding-left: 1px;">
              <select class="form-control" style="padding:0px 10px" formControlName="SCHEMEID" >
                <ng-container *ngFor = "let item of schemeList">
                    <option [ngValue]="item.SCHEMEID">{{item.NAME}}</option>    
                </ng-container>
                </select>
              <!-- <div class="invalid-feedback d-block"
                *ngIf="!isSubmitting && form.controls['mobNo'].invalid && (form.controls['mobNo'].dirty || form.controls['mobNo'].touched)">
                <span *ngIf="form.controls['mobNo'].errors.required">Mobile No is required.</span>
              </div> -->
              <!-- Error before submitting -->
              <!-- <div class="invalid-feedback d-block" *ngIf="isSubmitting && form.controls['mobNo'].invalid">
                <span *ngIf="form.controls['mobNo'].errors.required">Mobile No is required.</span>
              </div>
              <div class="invalid-feedback d-block"
              *ngIf="!isSubmitting && form.controls['mobNo'].invalid && (form.controls['mobNo'].dirty || form.controls['mobNo'].touched)"> 
                <span *ngIf="form.controls['mobNo'].errors.minlength || form.controls['mobNo'].errors.maxlength">Mobile No must be 10 digit long.</span>
              </div>  -->
              <!-- Error after submitting -->
  
            </div>
          </div>

          <div class="form-group row" >
            <label class="col-sm-2 form-control-label" >Account:</label>
            <div class="col-sm-5" style="padding-left: 1px;">
              <input type="text" class="form-control" formControlName="ACCNAME" autocomplete="off" > 
            </div>
          </div>

  
        </form>
  
  
      </div>
      
  
  
  
    
  </div>
  </div>