import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Router, NavigationEnd, Routes } from "@angular/router";
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { GlobalState } from '@app/_helperServices/global.state';
import { BaMenuService } from '@app/_helperServices/baMenu.service';
import { AuthenticationService } from '@app/_services';
import { MENU, PAGES_MENU } from '@app/_helperServices/pages.menu';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { NotificationPopUpComponent } from '@app/common/popup/notification/notification-popup-grid.component';
import { HelpdescriptionFormComponent } from '@app/common/popup/helpdescription-form/helpdescription-form.component';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { SpinnerComponent } from '@app/common/shared/spinner/directives/spinner.component';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { environment } from '@environments/environment';
import { FormControl } from '@angular/forms';
import _ from 'lodash';
import { PAGES_MENU_LITE } from '@app/_helperServices/pagesLite.menu';
import { AuthService } from '@app/_helperServices/authService.service';
import * as data from 'src/assets/appConfig.json'

@Component({
  selector: 'navigation',
  templateUrl: './nav-layout.component.html',
  styleUrls: ['./nav-layout.component.scss']
})
export class NavigationLayout implements OnInit, OnDestroy {
  public menu = [];
  public activePageTitle: string = '';
  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = true;
  public offlineSyncStatus: boolean = false;
  public menuItems: MENU[];
  public selectedMenuItems: MENU;
  protected _menuItemsSub: Subscription;
  protected _onRouteChange: Subscription;
  @ViewChild("notification") notification: NotificationPopUpComponent;
  isActive: boolean = false;
  @ViewChild("CreateHelpParty") CreateHelpParty: HelpdescriptionFormComponent;
  menuSearched: FormControl = new FormControl();
  notificationCount: number = 0;
  public userData = {
    username: "",
    password: "",
    newPassword: ""
  }
  ORGANIZATION_INFO: any = <any>{};
  ORG_TYPE: string = "";
  orgEnum: number;
  menuRights: any[] = [];
  isHelpActive: boolean = false;
  public icon = '../../../assets/dashboard_logo_small.png';
  public _SYSTEMSETTING: any = <any>{};
  divisionName: any;
  phiscalYearInfo: any;
  AppMode: string;
  menuList: any;
  appVersion: string = "";
  constructor(@Inject(DOCUMENT) public document: any,
    public _state: GlobalState,
    public router: Router,
    public http: HttpClient,
    public _service: BaMenuService,
    public authenticationService: AuthenticationService,
    public masterRepo: MasterRepo,
    public alertService: AlertService,
    public loadingservice: SpinnerService,
    public authService: AuthService
  ) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
    this._state.subscribe('menu.activeLink', (activeLink) => {
      if (activeLink) {
        this.activePageTitle = activeLink.title;
      }
    });



    if (localStorage.getItem("USER_PROFILE")) {
      this.ORGANIZATION_INFO = JSON.parse(localStorage.getItem("USER_PROFILE"))
      this.ORG_TYPE = this.ORGANIZATION_INFO.CompanyInfo ? this.ORGANIZATION_INFO.CompanyInfo.ORG_TYPE : "";
      this.menuRights = this.ORGANIZATION_INFO.menuRights;
      if (!Array.isArray(this.menuRights) && this.ORGANIZATION_INFO.menuRights?.result) {
        this.menuRights = this.ORGANIZATION_INFO.menuRights.result;
      }
      if (this.ORG_TYPE == 'central') { this.orgEnum = 1 }
      else if (this.ORG_TYPE == 'superdistributor') { this.orgEnum = 2 }
      else if (this.ORG_TYPE == 'distributor') { this.orgEnum = 3 }
      else if (this.ORG_TYPE == 'retailer') { this.orgEnum = 4 }
    }
    if (localStorage.getItem("setting")) {
      this._SYSTEMSETTING = JSON.parse(localStorage.getItem("setting"));
    }
    // this._SYSTEMSETTING.ENABLEOFFLINESALE = 0; 
    // else {
    //   this._SYSTEMSETTING.ENABLEOFFLINESALE = 0;
    // }

    this.divisionName = this.ORGANIZATION_INFO.divisionName;
    this.phiscalYearInfo = '20' + this.ORGANIZATION_INFO.CompanyInfo.PhiscalID;
    this.AppMode = environment.appMode;

    if (this.AppMode == 'lite') {
      this.menuList = PAGES_MENU_LITE;
    } else {
      this.menuList = PAGES_MENU;
    }
    this.getPathForGlblSearch();

    this.appVersion = environment.appVersion;
  }
  onHelpclicked() {
    this.CreateHelpParty.show();
    // this.isHelpActive = true;
  }



  cancelHelpPopup() {
    this.CreateHelpParty.hide();
  }
  onDivisionClicked() {
    try {
      if (confirm("Are you sure you want to  change division ?")) {
        this.router.navigate(['/pages/reports/utilities/changedivision']);
      }
      else { return; }
    }
    catch (ex) {

    }
  }
  onPhiscalYearClicked() {
    try {
      if (confirm("Are you sure you want to change fiscal year ?")) {
        this.router.navigate(['/pages/reports/utilities/changePhiscalYear']);
      }
      else {
        return;
      }

    }
    catch (ex) {

    }
  }

  public ngOnInit(): void {
    this._onRouteChange = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        if (this.menuItems) {
          this.selectMenuAndNotify();
        } else {
          // on page load we have to wait as event is fired before menu elements are prepared
          setTimeout(() => this.selectMenuAndNotify());
        }
      }
    });

    this._menuItemsSub = this._service.menuItems.subscribe(this.updateMenu.bind(this));
    this.routeToMenuSelected();
  }

  routeToMenuSelected() {
    this.menuSearched.valueChanges.subscribe((res) => {
      this.router.navigate([`./pages/${res}`]);
    });
  }

  toggleNotificationPopup() {
    // this.masterRepo.disableQuantityEdit = true;

    this.notification.toggle();
  }


  setSelectedMenu(menuItem) {
    // console.log("mniyrm", menuItem)
    // console.log("Hello", menuItem.children?.forEach(x => x.title == "Additional Report"))
    // {
    //     alert("SAD")
    //   }
    if (menuItem.title == "Financial Account") {
      window.open(window.location.origin + "/account/#/pages/dashboard");
    } else if (menuItem.title == "Multi Business") {
      window.open(window.location.protocol + "//" + window.location.hostname + ":8020");
    }else if(menuItem.title == "Accounting"){
      this.directToAccounting();
    }
    //  else if (menuItem.title == 'Report'){
    //   console.log(this.masterRepo.port)
    //   if (this.masterRepo.port != null){

    //     window.open(window.location.protocol + "//" + window.location.hostname + ":"+this.masterRepo.port.REPORTPORT+"/#/pages/dashboard"+"?returnPort=" +  this.masterRepo.port.TRANSACTIONPORT +"&menu=Dynamic_Report_WebPos"+"&fiscal="+ this.masterRepo.userProfile.PhiscalYearInfo.PhiscalID ); 
    //   }else{
    //         window.open(window.location.protocol + "//" + window.location.hostname + ":"+"4400/#/pages/dashboard");
    //       }      
    // }
    else {
      this.selectedMenuItems = menuItem;
    }
  }

  directToAccounting() {
    console.log("direct to accounting", this.authService.getAuth().token)
    window.open(window.location.protocol+"//"+window.location.hostname + `:${environment.accountPort}/#/login?token=`+this.authService.getAuth().token);

  }
  
  // setSelectedMenuChild(menuItem){
  //   if (menuItem.title == 'Additional Report'){
  //     console.log(this.masterRepo.port)
  //     if (this.masterRepo.port != null){

  //       window.open(window.location.protocol + "//" + window.location.hostname + ":"+this.masterRepo.port.REPORTPORT+"/#/pages/dashboard"+"?returnPort=" +  this.masterRepo.port.TRANSACTIONPORT +"&menu=DYNAMIC_REPORT"+"&fiscal="+ this.masterRepo.userProfile.PhiscalYearInfo.PhiscalID ); 
  //     }else{
  //           window.open(window.location.protocol + "//" + window.location.hostname + ":"+"4400/#/pages/dashboard");
  //         }      
  //   }
  //   else {
  //     this.selectedMenuItems = menuItem;
  //   }
  // }
  logoClickEvent() {
    this.selectedMenuItems = this.menuItems.filter(x => x.title == "IMSPOS")[0];

  }
  public selectMenuAndNotify(): void {
    if (this.menuItems) {
      this.menuItems = this._service.selectMenuItem(this.menuItems);
      this._state.notifyDataChanged('menu.activeLink', this._service.getCurrentItem());
    }
  }

  public hoverItem($event): void {

  }

  public toggleSubMenu($event): boolean {
    $event.item.expanded = !$event.item.expanded;
    return false;
  }

  public ngOnDestroy(): void {
    this._onRouteChange.unsubscribe();
    this._menuItemsSub.unsubscribe();
  }

  public updateMenu(newMenuItems) {
    this.selectedMenuItems = newMenuItems.length ? newMenuItems[0] : null;
    this.menuItems = newMenuItems;
  }

  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

  onHoverItem() {
  }

  updateNotificationCount(count) {
    this.notificationCount = count;
  }


  EnableOfflineMenu(menu) {
    return true;
    // if (menu.title == "Multi Business" && this.ORGANIZATION_INFO.ENABLEMULTIBUSINESS != 1) {
    //   return false;
    // }
    // if (this._SYSTEMSETTING.ENABLEOFFLINESALE == 1) {
    //   if ((menu.title == "IMSPOS") || (menu.title == "Transaction") || menu.title == "Report") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return true;
    // }
    // return true;
  }


  EnableOfflineMenuChild(child) {
    // commented by roshan 
    // if (this._SYSTEMSETTING.DISPLAY == 1) {
    //   if (child.title == "Miscellaneous Report" || child.title == "Utilities") {
    //     return false;
    //   } else {
    //     return true;
    //   }

    // }
    // commented by roshan end 
    if (this._SYSTEMSETTING.RECURRING_TRANSACTION == 0 && child.title == "Recurring Report") {
      return false;
    }
    if (this._SYSTEMSETTING.RECURRING_TRANSACTION == 0 && child.title == "Recurring Transaction") {
      return false;
    }
    return true;
  }




  EnbaleChildOfChildren(children) {
    // commented by roshan  start
    // if (this._SYSTEMSETTING.DISPLAY == 1) {

    //   if (children.title == "Color Master" || children.title == "Miscellaneous Report") {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }
    //commented by roshan end
    return true;
  }

  EnbaleOfflineChildren(children) {
    if (this._SYSTEMSETTING.ENABLERATEGROUP == 0 && children.title == "Rate Group Master") {
      return false;
    }

    if (this._SYSTEMSETTING.EnableMembershipSales == 0 && children.route.path == "add-membership-sales-invoice") {
      return false;
    }

    if (this._SYSTEMSETTING.EnableRequisitionApproval == 0 && children.route.path == "requisition-approval") {
      return false;
    }

    if (this._SYSTEMSETTING.EnablePanBill == 1 && children.route.path == "add-sales-invoice") {
      return false;
    }

    if (this._SYSTEMSETTING.EnablePanBill == 1 && children.route.path == "abbreviated-sales-invoice") {
      children.title = "Sales Invoice";
    }

    if (this._SYSTEMSETTING.EnablePanBill == 1 && children.route.path == "add-sales-invoice-wholesale") {
      return false;
    }

    //commented by roshan  start


    // if (this._SYSTEMSETTING.DISPLAY == 1) {
    //   if (children.title == "Membership Master") {
    //     return false;
    //   }
    //   if (children.title == "Point Master") {
    //     return false;
    //   }
    //   if (children.title == "Customer Profile Master") {
    //     return false;
    //   }

    //   if (children.title == "Sales Invoice(wholeSale)" || children.title =="Self Billing"|| children.title =="Quotation") {
    //     return false;
    //   }
    //   if (children.title == "Sales-Man Master" || children.title == "Route Master" || children.title == "Route Coverage Plan") {
    //     return false;
    //   }


    // }
    // if(this._SYSTEMSETTING.EnableBackendBilling == 1){
    //   if(children.title == "Sales Invoice" || children.title == "Sales Return"){
    //     return false;
    //   }
    // }
    // if (children.title == "Item Property Setting") {
    //   if (this.ORGANIZATION_INFO.username.toLowerCase() == "patanjali_user") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    //commented by roshan end


    // if (this._SYSTEMSETTING.ENABLEOFFLINESALE == 1) {
    //   if (children.title == "Sales Invoice" || children.title == "Sales Return" || children.title == "Closing Stock") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else if (this._SYSTEMSETTING.ENABLEOFFLINESALE == 2) {
    //   if (children.title == "Sales Invoice" || children.title == "Sales Return") {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }

    //commented by roshan
    // else {
    //   return true;
    // }
    // commented by roshan end 
    return true;
  }




  isShowPageMenu(menuItem: any): boolean {
    let res: boolean = false;
    if (menuItem.route) {
      if (menuItem.route.menuType) {
        if (menuItem.route.menuType.filter(x => x == 0 || x == this.orgEnum)[0] != null && this.getMenuRights(menuItem.route.path)) return true;
      }
      else {
        return true;
      }
    }
    else if (menuItem.children) {
      let childrens = menuItem.children.filter(x => x.route.menuType.filter(y => y == 0 || y == this.orgEnum));
      if (childrens.length == menuItem.children.length) {
        return false;
      }
      return true;
    }
    else {
      return true;
    }
    return res;
  }

  public scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }
  public logout() {
    console.log("from logout");
    if (confirm('Are you sure to log out?')) {
      this.authenticationService.logout();
      this.router.navigate(["/login"]);
    }
  }
  public forgetPassword: boolean = false
  changePasswordPrompt() {
    this.forgetPassword = true
  }
  public changepwd: boolean = false

  changePassword() {
    console.log("change password credentials", this.userData, this.masterRepo.userProfile.username);
    if (this.userData.username.toLowerCase() !== this.masterRepo.userProfile.username.toLowerCase()) {
      this.alertService.warning("Please enter the username of current logged in user");
      return;
    }

    if (this.userData.password === null || this.userData.password === "" || this.userData.password === undefined) {
      this.alertService.warning("Please enter old password to continue");
      return;
    }

    if (this.userData.newPassword === null || this.userData.newPassword === "" || this.userData.newPassword === undefined) {
      this.alertService.warning("Please enter new password to continue");
      return;
    }

    this.loadingservice.show("Changing password, please wait..");
    this.masterRepo.changePassword(this.userData).subscribe((res: any) => {
      if (res.status == "ok") {
        this.loadingservice.hide();
        this.forgetPassword = false;
        this.alertService.success(res.result);
      } else if (res.status = "error2") {
        this.loadingservice.hide();
        this.alertService.error(res.result.error.result);
      }
    }, error => {
      console.log("eror", error);
      this.loadingservice.hide();
      this.alertService.error(error);
    })
  }



  cancelPassword() {
    this.forgetPassword = false
  }

  public downloadPrintService() {
    if (confirm('Are you sure to configure printer?')) {
      this.document.location.href = 'http://14.192.18.81:8010/printService/PrintSetup.exe';
    }
  }

  getMenuRights(menuname: string): boolean {
    // if (this.ORGANIZATION_INFO.username == "Admin" || this.ORGANIZATION_INFO.username.toLowerCase() == "patanjali_user") { return true; }
    if (this.ORGANIZATION_INFO.username.toLowerCase() == "patanjali_user") { return true; }

    // return true;
    let right = false;
    let men = this.menuRights.filter(x => x.menu == menuname)[0];
    if (men != null) {

      right = men.right.indexOf('view') > -1;
    }

    if (menuname == "") {
      right = true;
    }
    return right;
  }

  BackUp() {
    this.masterRepo.getBackup();
  }

  getPathForGlblSearch() {
    let convertedMenuObject: Routes = _.cloneDeep(<Routes>this.menuList)[0].children;
    console.log('convertd menu object', convertedMenuObject);
    this.menu = [];
    convertedMenuObject.forEach(child => {
      child.children.forEach(children => {
        if (children.hasOwnProperty("children")) {
          children.children.forEach(subchildren => {
            this.menu.push({
              name: subchildren.data.menu.title,
              path: (`${child.path}/${children.path}/${subchildren.path}`).replace("//", "/")
            })
          })
        } else {
          this.menu.push({
            name: children.data.menu.title,
            path: (`${child.path}/${children.path}`).replace("//", "/")
          })
        }
      })
    });

    console.log("final menu lidt", this.menu);
  }
}
