import { Component, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { AuthenticationService } from './_services';
import { User } from './_models';
import { BaMenuService } from './_helperServices/baMenu.service';
import { PAGES_MENU } from './_helperServices/pages.menu';
import { GlobalState } from './_helperServices/global.state';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs/Rx';
import { AlertService } from './common/shared/alert/alert.service';
import { environment } from '@environments/environment';
import { PAGES_MENU_LITE } from './_helperServices/pagesLite.menu';


@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
  
    currentUser: User;
    constructor(
        public _menuService: BaMenuService,
        public router: Router,
        public authenticationService: AuthenticationService,
        public _state: GlobalState,
        public _http: HttpClient,
        // public masterRepo: MasterRepo,
        public alertService: AlertService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        console.log("CHECK MODE IN APP COMP", environment.appMode);
        let menu = environment.appMode == "pro" ? PAGES_MENU : PAGES_MENU_LITE;
       
          this._menuService.updateMenuByRoutes(<Routes> menu);
      
        this.startTimer();
    }

    logout() {
        console.log("cal from app")
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    @HostListener("document : keydown", ["$event"])
    updown($event: KeyboardEvent) {
    if ($event.code == "F10" && $event.ctrlKey) {
      $event.preventDefault();
      if(this.currentUser){
        
        this.updateDatabase();
      }
    }

    if ($event.code == "F11" && $event.ctrlKey) {
      $event.preventDefault();
      if(this.currentUser){
        this.dataBaseUpdate();
      }
    }
  }




    ticks = 0;
    sub: Subscription;
    private startTimer() {
      try {
        let header = new Headers({ 'content-type': 'application/json' });
        let timer = Observable.timer(1, 60000);
        this.sub = timer.subscribe(
          t => {
            this.ticks = t;
            var apicall = this._state.getGlobalSetting("apiUrl") + "/CheckConnection";
            this._http.get(apicall).subscribe(x => { }),
              () => { },
              error => { };
          }
        );
      } catch (ex) { }
    }


    updateDatabase(){
      this.authenticationService.updateDatabase().subscribe(res=>{
        if(res.status == 'ok'){
          console.log("success", res.result);
          this.alertService.success(res.result);
        }else{
          this.alertService.error(res.result);
        }
      },error=>{
        console.log(error);
      })
    }

    dataBaseUpdate(){
      this.authenticationService.databaseUpdate().subscribe(res=>{
        if(res.status == 'ok'){
          this.alertService.success(res.result);
        }else{
          this.alertService.error(res.result);
        }
      },error=>{
        console.log(error);
      })
    }

}