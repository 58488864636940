import { Component, OnInit } from '@angular/core';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-helpdescription-form',
  templateUrl: './helpdescription-form.component.html',
  // styleUrls: ['./helpdescription-form.component.css']
})
export class HelpdescriptionFormComponent implements OnInit {
  isHelpActive:boolean = false;
  filter:any;
  SettingList: any[] = [];
  appVersion:string ="";

  constructor(public masterService: MasterRepo,){

    this.masterService.getSettings().subscribe((response: any) => {
      if (response) {        
        this.SettingList = response ? response : [];
      } else {
        this.SettingList = [];
      }
    });
    this.appVersion = environment.appVersion;
  }


  show(){
    this.isHelpActive=true;
  }

  hide(){
    this.isHelpActive=false;
  }

  cancelHelpPopup(){
    this.isHelpActive=false;
  }



  ngOnInit(): void {
  }

}
