import { decimalDigest } from '@angular/compiler/src/i18n/digest';
import { NumberValueAccessor } from '@angular/forms';
import { StringNullableChain } from 'lodash';
import { TAcList } from './Account.interface';
import { BarCodeInterface } from './BarCodeInsertInterface';
import { Product } from './ProductItem';
import { commissionItem, recurringItems } from './RecurringLog.interface';
export interface TrnMain {
    TOTALECS: number;
    AFTERFLATDISCRATE: number;
    BEFOREFLATDISCRATE: number;
    ISMANUALRETURN: boolean;
    COMPANYID: any;
    JOBNO: string;
    TOTALNETWEIGHT: number;
    TOTALCLDQUANTITY: number;
    shipToDetail: any;
    printStringForPos: string;
    CHALANNOPREFIX: any;
    PTYPE: number;
    Mode: string;
    NextVno: string;
    TotalWithIndDiscount: number;
    guid: string;
    ReplaceIndividualWithFlatDiscount: number;
    TOTALINDDISCOUNT: number;
    TOTALLOYALTY: number;
    TOTALPROMOTION: number;
    TOTALFLATDISCOUNT: number;
    COSTCENTER: string;
    RECEIVEBY: string;
    STATUS: number;
    CONFIRMEDBY: string;
    CONFIRMEDTIME: string;
    PhiscalID: string;
    Stamp: number;
    Customer_Count: number;
    DBUSER: string;
    HOSTID: string;
    ORDERS: number;
    REFORDBILL: string;
    INDDIS: number;
    CREBATE: number;
    CREDIT: number;
    DUEDATE: Date | string;
    TRNAC: string;
    PARAC: string;
    TRNACName: string;
    PARTRNAMNT: number;
    RETTO: string;
    REFBILL: string;
    ADJUSTMENT:string;
    VARIANCE:string;
    BATCH:string;
    BATCHDATE:Date|string;
    MAIN_RECURRING_ID: string;
    RECURREFBILL:string;
    
    CHEQUENO: string;
    CHEQUEDATE: Date | string;
    EDATE: Date | string;
    POST: number;
    POSTUSER: string;
    FPOSTUSER: string;
    SHIFT: string;
    EXPORT: number;
    CHOLDER: string;
    CARDNO: string;
    EditUser: string;
    MEMBERNO: string;
    REFERMEMBERNO:string;
    MEMBERNAME: string;
    REFERMEMBERNAME:string;
    EDITED: number;
    VMODE: number;
    BILLTOTEL: string;
    BILLTOMOB: string;
    TRN_DATE: Date | string;
    BS_DATE: string;
    STAX: number;
    TOTALCASH: number;
    TOTALCREDIT: number;
    TOTALCREDITCARD: number;
    TOTALGIFTVOUCHER: number;
    CardTranID: string;
    ReturnVchrID: string;
    TranID: number;
    VoucherStatus: boolean;
    PRESCRIBEBY: string;
    DISPENSEBY: string;
    Longitude: string;
    Latitude: string;
    MobileNo: string;
    DeliveryDate: Date | string;
    DeliveryAddress: string;
    DeliveryTime: string;
    DeliveryMiti: string;
    OrderNo: string;
    ADJWARE: string;
    BATCHNO: string;
    BATCHNAME: string;
    DPSNO: string;
    EDITTIME: string;
    EID: string;
    EXTRACHARGE: string;
    ISSUEWARE: string;
    ADVANCE: number;
    VoucherType: VoucherTypeEnum;
    VoucherAbbName: string;
    VoucherName: string;
    VoucherPrefix: string;
    Nextnumber: number;
    VCHRNO: string;
    CHALANNO: string;
    DIVISION: string;
    TRNDATE: Date | string;
    PODATE: Date | string;
    BSDATE: string;
    TRNTIME: string;
    TOTAMNT: number;
    GROSSAMNT:number;
    CONSUMPTIONGROSS:number;

    DCAMNT: number;
    FLATDCAMNT:number;
    DCRATE: number;
    VATAMNT: number;
    NETAMNT: number;
    TRNMODE: string;
    SETTLEMENMODEDECREASE:number;
    TAXABLE: number;
    NONTAXABLE: number;
    BILLTO: string;
    BILLTONAME: string;
    BILLTOADD: string;
    BILLTOADDNAME: string;
    TRNUSER: string;
    TERMINAL: string;
    TENDER: number;
    CHANGE: number;
    ROUNDOFF: number;
    NETWITHOUTROUNDOFF: number;
    ServiceCharge: number;
    IsTable: boolean;
    IsVATBill: boolean;
    VATBILL: number;
    REMARKS: string;
    Vouchernumber: number;
    MWAREHOUSE: string;
    TrntranList: Trntran[];
    AdditionTranList: Trntran[];
    ProdList: TrnProd[];
    AdditionProdList: TrnProd[];
    ProdListWithDetailInfo: TrnProd[];
    ConsumptionProdList: TrnProd[];
    // BarCodes:BarCodeInterface[];
    
    FCurrency: string;
    EXRATE: number;
    TOTALDISCOUNT: number;
    TOTALDISCOUNT_VATINCLUDED: number;
    //AddedByBzu - for purchase invoice
    CREDITDAYS: number;
    InvAmount: number;
    DiscRate: number;
    RoundOffAmount: number;
    ManualDiscount: number;
    BRANCH: string;
    BALANCE: number;
    TOTALQTY: number;
    TOTALCONSUMPTIONQTY: number;
    TOTALECSAMOUNT:number;
    TOTALWEIGHT: number;
    AdditionalObj: TrnMain_AdditionalInfo
    SHIPNAME: string;
    INVOICETYPE: string;
    TRNTYPE: string;
    TenderList: TBillTender[];
    RecurringItemList: recurringItems[];
    CommissionItemList: commissionItem[];
    PARTY_ORG_TYPE: string;
    HOLDBILLID: number;
    TransporterEway: Transporter_Eway;
    IsAccountBase: boolean;
    hasShipName: boolean;
    tag: string;
    CUS_FIX_DISCOUNT_PER: number;
    DmsSchemesApplied: any
    SALESMANID: string;
    SALESMAN: string;
    CUS_Remote_Discount: number;
    totalAlternateQuantity: number;
    totalBaseQuantity: number;
    ROUTE: string;
    SHIPNAMEOBj: any
    salesmanName: string;
    PCL: string;
    REFBILLDATE: string;
    AdvanceAdjustmentObj: any;
    VATNO:string;
    DOFULLRETURN: number;
    DOMANUALSALESRETURN: number;
    DONONSALEABLERETURN:number;
    TOTALPRIMARYDISCOUNT: number;
    TOTALSECONDARYDISCOUNT: number;
    TOTALLIQUIDATIONDISCOUNT: number;
    TOTALQPSDISCOUNT:number;
    SUBMENU:string;
    //ProductDetails: any[];
    SCHEMENAME:string;
    EARNPOINS:number;
    ORDEXPDATE: Date | string;
    ORDEXPMITI: string;
    PODetailList: PurchaseOrderDetail[];
    CNDN_MODE:number;
    IMPORTDETAILS: IMPORT_DETAILS;
    ISLOADFROMORDER:number;
    LOADFULLFROMQT:number;
    TOTALSQTY : number;
 

    STOCKMESSAGE : string;
    BATCHADJUSTMENTMODE: number;
    BILLMODE: string;
    AUTOLOAD: number;
}
export interface TrnProd {
    ISAMOUNTWISEBILL: number;
    GROSSAMNT: number;
    discountratecheck: number;
    FulfillBatchQty: boolean;
    SELECTEDBATCH: any;
    EDITEDBILLQUANTITY: number;
    OtherDiscount: number;
    INDODAMT: number;
    UPLOADEDNETAMOUNT: number;
    ADJUSTEDAMNT: number;
    backgroundcolor: string;
    HOLDINGSTOCK: number;
    TRANSACTIONMODE: string;
    PrimaryDiscountPercent: number;
    SecondaryDiscountPercent: number;
    LiquiditionDiscountPercent: number;
    SellingPrice: number;
    TOTALDISCOUNTINRETRUN: number;
    INDCESS_PER: number;
    INDCESS_AMT: number;
    BATCHID: any;
    NWEIGHT: any;
    TOTALOUTWARDSTOCK: number;
    ADJUSTBATCH:string;
    ADJUSTBATCHID:string;
   
    rowMode: string;
    RowMode: string;
    isDisabled: boolean;
    PTYPE: number;
    NATURETYPE:number;
    ProductDetails: any;
    INDDISCOUNT: number;
    FLATDISCOUNT: number;
    NETAMOUNT: number;
    ECSAMOUNT: number;
    LOYALTY: number;
    PROMOTION: number;
    ISSERVICECHARGE: number;
    ISVAT: number;
    HASECSCHARGE:number;
    ADDTIONALROW: number;
    COSTCENTER: string;
    VCHRNO: string;
    CHALANNO: string;
    MCODE: string;
    UNIT: string;
    Quantity: number;
    RealQty: number;
    AltQty: number;
    RATE: number;
    DiscountOnAltUnit: boolean ;
    BASERATE:number;
    AMOUNT: number;
    DISCOUNT: number;
    VAT: number;
    REALRATE: number;
    EXPORT: number;
    IDIS: string;
    OPQTY: number;
    REALQTY_IN: number;
    ALTQTY_IN: number;
    WAREHOUSE: string;
    REFBILLQTY: number;
    SPRICE: number;
    EXPDATE: Date | string;
    REFOPBILL: number;
    ALTUNIT: string;
    TRNAC: string;
    PRATE: number;
    PREV_PRATE: number;
    VRATE: number;
    ALTRATE: number;
    VPRATE: number;
    VSRATE: number;
    TAXABLE: number;
    NONTAXABLE: number;
    SQTY: number;
    REMARKS: string;
    INVRATE: number;
    EXRATE: number;
    NCRATE: number;
    CRATE: number;
    SNO: number;
    CUSTOM: number;
    WEIGHT: number;
    DRATE: number;
    CARTON: number;
    INVOICENO: string;
    ISSUENO: string;
    BC: string;
    GENERIC: string;
    BATCH: string;
    PBATCH:string;
    MFGDATE: Date | string;
    MANUFACTURER: string;
    SERVICETAX: number;
    BCODEID: number;
    VoucherType: number;
    SALESMANID: number;
    PhiscalID: string;
    STAMP: number;
    ITEMDESC: string;
    MENUCODE: string;
    TOTAL: number;
    Ptype: number;
    Product: Product;
    unitQty: UnitAndQty;
    Mcat: string;
    Mcat1: string;
    ITEMTYPE: string;
    HASITEMTYPE: number;
    RECEIVEDTYPE: string;
    inputMode: boolean;
    index: number;
    MRP: number;
    GODOWN: string;
    INDDISCOUNTRATE: number;
    SELECTEDITEM: any;
    BRANCH: string;
    Supplier: string;
    STOCK: number;
    BSTOCK:number;
    ALLSCHEME: any;
    BATCHSCHEME: any;
    PrimaryDiscount: number;
    SecondaryDiscount: number;
    LiquiditionDiscount: number;
    BasePrimaryDiscount: number;
    BaseSecondaryDiscount: number;
    BaseLiquiditionDiscount: number;
    Sn:number;
    ALTUNITObj: any;
    ALTRATE2: number;
    RATE2: number;
    GSTRATE: number;
    CONFACTOR: number;
    ACNAME: string;
    GSTRATE_ONLYFORSHOWING: number;
    VAT_ONLYFORSHOWING: number;
    PClist: any[];
    INDIVIDUALDISCOUNT_WITHVAT: number;
    IsApproveStockSettlement: boolean;
    ALTMRP: number;
    BASEQUANTITY: number;
    ALTERNATEQUANTIY: number;
    BASEUNIT: string;
    ALTERNATEUNIT: string;
    ALTCONFACTOR: number;
    PWEIGHT: number;
    PRICEID: string;
    SHELFLIFE: number;
    Weighable: string;
    FixedDiscount: number;
    RemoteDiscount: number;
    isApproved: number;
    visible: boolean;
    BARCODE: string;
    SELLRATEBEFORETAX: number;
    W_SELLRATEBEFORETAX:number;
    WSRATE_DISCOUNT:number;
    W_MRP:number;
    NETRATE:number;
    REQEXPDATE:number;
    ISUNKNOWN: number;
    RECRATE:number;
    isItemDesReadonly:boolean;
    isQuantityReadonly:boolean;
    isCarriedOnStock:number;
    SUPCODE:number;
    WARRANTY:number;
    isBarcodeOfPtypeSix:boolean;
    DISMODE:string;
    isPurchaseOrder:boolean;
    isPurchaseOrderItem:boolean;
    MINLEVEL:number;
    ROLEVEL:number;
    POSTOCK:number;
    PDESCA:string;
    ROUTQTY:number;
    OUTQTY: number;
    PUNIT: string;
    PMCODE:string;
    REPACKRATE:number;
    MAXLEVEL:number;
    ROWARN:number;
    MINWARN:number;
    HASVARIANT:number;
    VARIANTDESCA:string;
    VDESCA:string;
    VARIANTLIST: any[];
    VaraintDetailS:any[];
    HasMulitplePrice : number;
    PriceSelected : boolean;
    IsMrpSelected: boolean;
    HasSellingPriceChange:boolean;
    PORATE: number;
    ENABLEITEMWISEBATCH: number;
    ProductRates(ProductRates: any, i: string);
    TEMP_ALTRATE: number;
    ISRECURRING:number;
    HASCOMMISSIONCHARGE:number;
    EDITABLEQTY:number;
    BILLEDQTY:number;
    BatchWiseEditList : BatchWiseEdit[];
    TOTSTOCK:number;
    COMMENTS : string;
    ECSRATE : number;
    ADJUSTBC : string;
    PSTOCK:number;
    APPROVEDQUANTITY: number;
    APPROVEDALTQTY: number;
    REQUESTALTQTY: number;
    REQUESTTOTALQTY: number;
    LABELMRP:number;
    isConfirmed:boolean;
    EnableAutoBatch:number;
    IsAuthorizeForNegative:boolean;
    CSTOCK: number;
    HS_Code:string;

}




export class Trntran {

    ACNAME: string;
    SubledgerTranList: TSubLedgerTran[];
    AccountItem: TAcList;
    VCHRNO: string;
    CHALANNO: string;
    DIVISION: string;
    A_ACID: string;
    DRAMNT: number;
    CRAMNT: number;
    B_ACID: string;
    NARATION: string;
    TOACID: string;
    NARATION1: string;
    VoucherType: VoucherTypeEnum;
    ChequeNo: string;
    ChequeDate: string;
    CostCenter: string;
    MultiJournalSno: number;
    PhiscalID: string;
    SNO: number;
    ROWMODE: string;
    acType: string;
    hasDebit: boolean;
    hasCredit: boolean;
    drBGColor: string;
    crBGColor: string;
    drColor: string;
    crColor: string;
    inputMode: boolean;
    editMode: boolean;
    acitem: any;// to hold the select acount Item value;
    PartyDetails: PartyOpeningDetail[] = [];
}

export class PartyOpeningDetail {
    VCHRNO: string;
    DIVISION: string;
    REFVNO: string;
    ACID: string;
    REFDATE: string;
    AMOUNT: number;
    CLRAMOUNT: number;
    DUEAMT: number;
    DUEDATE: string;
    REFSNO: string;
    PHISCALID: string;
    STAMP: string
}


export interface TSubLedgerTran {
    SNO: number;
    VCHRNO: string;
    CHALANNO: string;
    DIVISION: string;
    A_ACID: string;
    DRAMNT: number;
    CRAMNT: number;
    B_ACID: string;
    NARATION: string;
    TOACID: string;
    VoucherType: VoucherTypeEnum;
    PhiscalID: string;
    MultiJournalSno: number;
    SubledgerItem: TSubLedger;
    ACNAME: string;
    Amount: number;
    ROWMODE: string;
    inputMode: boolean;
    editMode: boolean;
}
export interface TSubLedger {
    SERIAL: number;
    ACID: string;
    ACCODE: string;
    ACNAME: string;
    PARENT: string;
    TYPE: string;
    OPBAL: number;
    MAPID: string;
    IsBasicAc: number;
    ADDRESS: string;
    PHONE: string;
    FAX: string;
    EMAIL: string;
    VATNO: string;
    PType: string;
    CRLIMIT: number;
    CRPERIOD: number;
    SALEREF: number;
    LEVELS: number;
    FLGNEW: number;
    COMMON: number;
    PATH: string;
    INITIAL: string;
}

export interface UnitAndQty {
    Unit: string;
    Qty: number;
    Rate: number;
    BaseUnit: string;
    BaseQty: number;
    BaseRate: number;
    ConversionFactor: number;
}

export interface AlternateUnit {
    SNO: number;
    MCODE: string;
    ALTUNIT: string;
    UNIT:string;
    CONFACTOR: number;
    RATE: number;
    ISDEFAULT: number;
    ISDEFAULTPUNIT:number;
    ISBASEUNIT:number;
    ISDEFAULTPRATE: number;
    BRCODE: string;
    PRATE_A: number;
    RATE_A: number;
    RATE_B: number;
    RATE_C: number;
    RATE_D: number;
    PRATE: number;
    ISDISCONTINUE: number;
    EX_RATE : number;
    EX_RATE_B : number;
    BASEUOM : string;
    Ex_Prate_A:number;
    IN_PRATE:number;
    WSRATE_DISCOUNT:number;
    SRATE_DISCOUNT:number;
}
export interface CostCenter {
    CostCenterName: string;
    DIVISION: string;
    Parent: string;
    TYPE: string;
    CCID: number;
}

export interface Division {
    ACID: string;
    BRANCH: string;
    BRANCHNAME: string;
    COMADD: string;
    COMID: number;
    COMNAME: string;
    DESCRIPTION: string;
    ID: number;
    INITIAL: string;
    MAIN: number;
    MSG1: number;
    MSG2: number;
    MSG3: number;
    NAME: string;
    REMARKS: string;
    RID: number;
    RateGroupID: number;
    SHORTNAME: string;
    STAMP: string;
    STATUS: string;
    TYPE: string;
    isCheck : boolean;
    isDefault:boolean;
    isCheck_trn:boolean;
    isDisable:boolean;
    isDisable_trn:boolean;
}

export interface Warehouse {
    NAME: string;
    SNAME: string;
    ADDRESS: string;
    PHONE: string;
    REMARKS: string;
    ISDEFAULT: boolean;
    IsAdjustment: number;
    AdjustmentAcc: string;
    ISVIRTUAL: number;
    VIRTUAL_PARENT: string;
    DIVISION: string;
    WarehouseType: string;
    WID: number;
    COMPANYID: number;
}

export interface Branch {
    COMPANYID: string;
    companyAlias: string;
    name: string;
    type: string;
    chanel: string;
    margin: string;
    remarks: string;
    PARENTBRANCHID: string;
}

export enum VoucherTypeEnum {
    Default = 0, Sales = 1, 
    SalesReturn = 2, Purchase = 3, PurchaseReturn = 4, 
    StockIssue = 5, StockReceive = 6, BranchTransferIn = 7,
    BranchTransferOut = 8, 
    StockSettlement = 9, Stockadjustment = 10, Receipe = 11, 
    Journal = 12, Delivery = 13, TaxInvoice = 14,
    CreditNote = 15, DebitNote = 16, PaymentVoucher = 17, 
    ReceiveVoucher = 18, 
    PurchaseOrder = 19, SalesOrder = 20, DeliveryReturn = 21,
    AccountOpeningBalance = 22, PartyOpeningBalance = 23, 
    OpeningStockBalance = 24, 
    SubLedgerOpeningBalance = 25,
    GoodsReceived = 31,
    PerformaSalesInvoice = 57,
    PurchaseOrderCancel = 58, RequestIndent = 59,
    StockSettlementEntryApproval = 60,
    TaxInvoiceCancel = 61,
    ContraVoucher = 62,
    Quotation = 64,
    Repack = 74,
    ReversePurchase = 37,
    RequisitionEntry=80,
    ProductionEntry = 88,
    ReverseTaxInvoice = 43,
    ReverseCreditNote = 41,
    ReverseDebitNote = 40,
    StockIssueReturn = 81,
    BatchAdjustment = 89,
    CancelProforma =73,
    RequisitionApproval =90
}

export enum UnitRequireEnum{
        Purchase =3,
        OpeningStockBalance =24,
        RequisitionEntry = 80,
        GoodsReceived = 31,
        ProductionEntry = 88,
        PurchaseOrder = 19,
        Quotation = 64 ,
        TaxInvoice = 14,
        SalesOrder = 20,
        StockIssueReturn = 81,
        StockIssue = 5,
        BranchTransferIn = 7,
        BranchTransferOut = 8,
        PerformaSalesInvoice = 57,
        DebitNote = 16,
        Sales =1,
        Repack = 74,        
}

export enum BatchRequireEnum{
    Purchase =3,
    OpeningStockBalance =24,
    ProductionEntry = 88,
    TaxInvoice = 14,
    StockIssue = 5,
    BranchTransferOut = 8,
    DebitNote = 16,
    Sales =1        
}

export enum AllowNegativeEditEnum{
    Purchase =3,
       
}



export enum checkBatchInSystemEnum{
    Purchase =3,
    OpeningStockBalance =24,
}


export enum ProductType {
    inventoryItem = 0,
    mobile = 6,
    serviceItem = 10
}

export enum RATE_AVoucherEnum{
    RequisitionEntry = 80,
    Quotation = 64 ,
    TaxInvoice = 14,
    PerformaSalesInvoice = 57,    
    Sales =1  
}

export enum PRATE_AVoucherEnum{
    StockIssue = 5,
    BranchTransferOut = 8,    
    DebitNote = 16,    
    ProductionEntry = 88,
    Repack = 74,
}


export enum EditableVoucherType{
    Purchase =3,
    OpeningStockBalance = 24,
    
}

export enum EditableVoucherTypeOutQty{    
    BranchTransferOut = 8,
    DebitNote = 16

}

export enum StockOutTypeVoucherEnum{
    TaxInvoice = 14,
    StockIssue = 5,
    BranchTransferOut = 8

}

export enum DisableDuplicateItemVoucher{
    Purchase = 3,
    GoodsReceived = 31,
    PurchaseOrder = 19,
    RequisitionEntry =80
}


export interface VoucherSatus {
    vouchertype: VoucherTypeEnum;
    VCHRNO: string;
    DIVISION: string;
    PhiscalID: string;
    BillMode: string;
}

export interface DialogInfo {
    TRANSACTION: string;
    PARAC: string;
    DIVISION: any;
    DELEVERYLIST: string;
    SALESMODE: string;
    WARRENTYTODATE: Date;
}
export interface TrnMain_AdditionalInfo {
    AFTERFLATDISCRATE: number;
    BEFOREFLATDISCRATE: number;
    CESS_PER: any;
    CREATION_TYPE: string;
    INVOICEREFBILL: any;
    tag: string;
    DSMNAME: any;
    DSMCODE: any;
    BEAT: any;
    VCHRNO: string;
    STAMP: number;
    SHIPNAME: string;
    SHIPNAMEVIEW: string,
    TRNTYPE: string;
    CFORM: string;
    PAYMENTTYPE: string;
    RETURNMODE: string;
    PAYMENTMODE: string;
    PAYMENTTERMS: string;
    INVNO: string;
    PONO: string;
    INVDATE: Date;
    INVAMOUNT: number;
    TOTALGST: number;
    TOTALEXTRACESS: number;
    FREIGHT: string;
    CREDITDAYS: string;
    SOSTOCKSTATUS: string;
    INVOICETYPE: string;
    COUPONBALANCE: string;
    BILLFORMATS: string;
    BILLUNITS: string;
    VEHICLENO: string;
    RECEIVEDDATE: Date | string;
    RETURNTYPE: string;
    CHALANNO: string;
    CHALANNOPREFIX: string;
    SONO:string;
    CREFBILL:string; //confirmation Ref bill for reverse entry
    CREMARKS:string; //confirmation Remarks for reverse entry
    PREVBILL:string;
    REFORDID:string;
    AFTERVATDISCOUNT:number;
    TDSAMOUNT:number;
    JOBCARDNO:string

}
export class TenderObj {
    CREDIT: number;
    SAMRIDHICARDNO: string;
    CHEQUE: number;
    CASH: number;
    WALLET: number;
    CARD: number;
    COUPON: number;
    CASHAMT: number;
    CARDAMT: number;
    CARDNO: string;
    CARDNAME: string;
    APPROVALCODE: string;
    CARDHOLDERNAME: string;
    CREDITAMT: number;
    CHEQUEAMT: number;
    CHEQUENO: string;
    DATE: Date;
    BANK: string;
    TOTAL: number;
    OUTSTANDING: string;
    ADVANCE: number;
    TENDERAMT: number;
    BALANCE: number;
    LOYALTYAMT: number;
    COUPONNAME: string;
    COUPONAMT: number;
    WALLETAMT: number;
    WALLETTYPE: string;
    WALLETCARDNO: string;
    TRNMODE: string;
    REMARKS: string;
    ACID: string;
    SAMRIDHICARDAMT: number;
    SAMRIDHICARDHOLDERNAME: string;
    

}

export class TBillTender {
    VCHRNO: string;
    DIVISION: string;
    TRNMODE: string;
    ACCOUNT: string;
    REMARKS: string;
    SN: number;
    AMOUNT: number;
    PHISCALID: string;
    CHANGE: number;
    STAMP: number;
    guid: string;
    TRANSACTIONID: string;
    OTP: string;
    PROVIDERNAME: string;
    PROVIDERACCOUNT: string;
    SALESMANID: number;
    // added by bzu
    CARDNO: string
    APPROVALCODE: string;
    CARDHOLDERNAME: string;
    SAMRIDHICARDNO: any;
    SAMRIDHICARDHOLDERNAME: any;
    ACTUALAMOUNT: number;
}

export interface BatchWiseEdit{
      MCODE:string;
      BC : string;
      BATCH : string;
      WAREHOUSE : string;
      INVOICEQTY : number;
      INVOICEQTYA : number;
      TOTALINWARDQTY :number;
      TOTALOUTWARDSTOCK : number;
      TOTALOUTWARDSTOCKA : number;
      EDITABLEQTY : number;
      EDITABLEQTYA : number;
      ADJUSTQTY : number;
      isDisabled : Boolean;
      BilledQty: number;
      ALTUNIT : string;
      BASEUNIT : string;
      ALTCONFACTOR : number;
}

export interface ExcelImportConfig {
    ImportName: string;
    ColumnName: string;
    SNO: number;
    ColumnSize: string;
    DataType: boolean;
    ColumnValue: number;
    Mandatory: string;
    AddToSheet: number;
}
export interface Transporter_Eway {
    TRANSPORTERID: string;
    ID: number;
    VCHRNO: string;
    TRANSPORTER: string;
    VEHICLENO: string;
    PERSON: string;
    VEHICLENAME: string;
    DRIVERNAME: string;
    DRIVERNO: string;
    TOTALBOX: string;
    MODE: string;
    LRNO: string;
    LRDATE: Date;
    STAMP: Date;
    DISTANCE: number;
    EWAYNO: string;
    TOTALWEIGHT: number;
    COMPANYID: string;
}

export interface PurchaseOrderDetail {
    MENUCODE:string;
    DESCA:string;
    UNIT:string;
    RATE:number;
    ORDERQTY:number;
    DELIVERQTY:number;
    DELIVERRATE:number;
    AMOUNT:number;
    EXPIRYDATE:Date | string;
    MFGDATE:Date | string;
    MCODE:string;
    VCHRNO:string;
    DISCOUNT_RATE:number;
    DISCOUNT_AMOUNT:number;
    NET_RATE:number;
    SELLRATEBEFORETAX: number;
    W_SELLRATEBEFORETAX:number;
}

export interface IMPORT_DETAILS {
    DOCUMENTNO: string;
    LCNUM: string;
    TOTALVAT: number;
    TOTALQTY: number;
    TOTALTAXABLE: number;
    TOTALNONTAXABLE: number;
    NETAMOUNT: number;
    TRNAC:string;
    TRNACNAME:string;
    prodList: IMPORT_DETAILS_PROD[]

}

export interface IMPORT_DETAILS_PROD {
    ITEMNAME: string;
    QUANTITY:number
    TAXABLE: number;
    NONTAXABLE: number;
    VAT: number;
    NETAMOUNT: number;
}


export enum ITEMTYPE {
    INVENTORYITEM = 0,
    RAWITEM = 1,
    MATRIXITEM = 2,
    BULKITEM = 3,
    REPACKITEM = 4,
    SERVICEITEM = 10,
    COCKTAILITEM = 11,
    INTERMEDIATEITEM = 12,
    COMBOITEM = 13,
}