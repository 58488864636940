<div>
    <div class="modal-content" style="padding:2px !important;    box-shadow: -10px 0px 10px 1px #aaaaaa;margin-left: 57pc !important;
     width: 40% !important; height: 415px !important">
        <div class="modal-header" style="padding: 0px; background: #7ed0ff;">
            <button class="close" aria-label="Close" (click)="itemCategoryClosed()">
                <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" style="text-align: center;">{{title}}</h4>
        </div>
        <div class="col-md-12">
            <input #SearchBox type="text" style="width: 105%;/* padding: 1px; */padding-left: 0px;margin-left: -13px;"
                [(ngModel)]="filter" placeholder="Search">
        </div>
        <table style="padding: 0px;margin-top: 2px;width: 100%;" class="Table1">
            <tr style="background: #7ed0ff;">
                <th>Name</th>
                <th></th>
            </tr>
            <tbody aria-disabled="true" *ngFor="let CObject of CatList | filter:filter  | paginate: {id:tabindex, itemsPerPage: 12,
                currentPage: p[tabindex] } ;let i=index" [class.active]="i == selectedRow"
                (dblclick)="dblClickCatObj(CObject)">
                <tr class="highlight">

                    <td style="padding-top:2px;border-bottom: 1px solid #ddd;height: 23px;">
                        {{CObject.MENUCAT}}</td>
                </tr>
            </tbody>
            <tr>
                <div class="col-md-12" style="width:200%">
                    <pagination-controls [id]="tabindex" (pageChange)="pagingChange($event)"></pagination-controls>
                </div>
            </tr>
        </table>
    </div>
</div>