
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[IMSINPUTREADONLY]'
})
export class IMSINPUTREADONLY {
    constructor(public el: ElementRef) { }
    @HostListener('keydown', ['$event']) onKeyPress(event) {
            let e = <KeyboardEvent>event;
            e.preventDefault();
    }
}
