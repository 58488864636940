import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { MasterRepo } from '@app/_services/masterRepo.service';
@Component(
    {
        selector: 'itemwisebatch',
        templateUrl: './BatchListPopup.component.html',
    }
)

export class BatchListPopup {
    @Input() Blist: any[] = [];
    @Input() HASVARIANT:number;
    @Output() BatchClosedEmit = new EventEmitter();
    // @Output() returnBatchValue = new EventEmitter();
    @Output() dbClickEvent = new EventEmitter();
    showPopup: boolean = false;
    selectedRowIndex: number;
    filter:any;

    constructor(
        private masterRepo: MasterRepo,
      public _trnMainService: TransactionService
    ){

    }

    ngOnInit(){
        console.log("batch popup shown",this.Blist);
    }

    RowClickEvent(index) {
        this.selectedRowIndex = index;
    }
    dblClickBatchObj(value) {
        this.dbClickEvent.emit(value)
    }

    

    itemBatchClosed(){
        this.BatchClosedEmit.emit()
    }

    @HostListener("document : keydown", ["$event"])
    updown($event: KeyboardEvent) {
        if (this.selectedRowIndex == null) this.selectedRowIndex = 0;

        if ($event.code === "ArrowDown") {
            this.selectedRowIndex = this.selectedRowIndex + 1;
            if (this.selectedRowIndex > (this.Blist.length - 1)) this.selectedRowIndex = this.Blist.length - 1;
        }
        else if ($event.code === "ArrowUp") {
            this.selectedRowIndex = this.selectedRowIndex - 1;
            if (this.selectedRowIndex < 0) this.selectedRowIndex = 0;
        }
        if ($event.code === "Enter" || $event.code == "NumpadEnter") {
            if (this.selectedRowIndex != null) {
                if (this.Blist[this.selectedRowIndex] != null) {
                    this.dblClickBatchObj(this.Blist[this.selectedRowIndex]); 
                }
            }
        } else if ($event.code === "Escape") {
            this.itemBatchClosed();
        }


    }
}
