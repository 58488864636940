<div class="customer_container" *ngIf="isActive">
    <div class="card">
      <div class="card-header">
        <h5 class="page-title">Reverse Entry Confirmation</h5>
        <div class="button-group">
            <button title="Save" type="submit" class="btn btn-info" (keydown.F6)="submit()" (click)="submit()" 
              style="margin-right:2px">SAVE</button>
          
            <button type="button" title="Cancel" class="btn btn-info" (click)="closeDialog()">CANCEL</button>
          </div>
      </div>
      <div class="card-body">
          <div class="form-group row" style="margin-top: 1rem;">
            <label class="col-sm-3 form-control-label" >Invoice No :</label>
            <div class="col-md-7">
                <input type="text" class="form-control" [(ngModel)]="_trnMainService.TrnMainObj.AdditionalObj.CREFBILL">
            </div> 
          </div>

          <div class="form-group row">
              <label class="col-md-3 form-control-label" >Remarks of Reverse Entry:</label>
              <div class="col-md-7">
                  <textarea class="form-control" style="width: 100%;" [(ngModel)]="_trnMainService.TrnMainObj.AdditionalObj.CREMARKS"></textarea>
              </div>
          </div>

          
       
  
  
      </div>
    </div>
  
  </div>