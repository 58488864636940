import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '@app/login';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        public authenticationService: AuthenticationService, 
        public alertService: AlertService,
        public router: Router) { }

    intercept(request: HttpRequest<any>,
         next: HttpHandler): Observable<HttpEvent<any>> {
        //     this.dialog.closeAll();
        //     this.dialog.open(LoginComponent, {
        //     hasBackdrop: false,
        //     data: { header: 'Information', message: '' }
        //   });
        return next.handle(request).pipe(catchError(err => {
                      
            this.HandleError(err);
            return throwError(err);
        }))
    }

    HandleError(err){
        
        // this.dialog.open(LoginDialog, { disableClose: true });
        let message = "";
        if (err.error == null) {
            message = err.message;
        } else {
            message = err.error;
        }
        if (err.status === 401) {
        
        }
        else if(err.statusText == "Unknown Error"){
         
        }
        else if(err.status == 400){
          if(err.error.result)
           this.alertService.error(err.error.result)
           if(!err.error.result)
           this.alertService.error(err.error)
        }
        else if (err.status == 500){
         
        }
        else if(err.status == 404){
            
        }
        else if (err.status == 0){
         
        }
         else {
            // this.alertService.error(message)
            this.alertService.error(message)
        }
    }
}