<style>
  .modal-content {
    padding: 1px;
    margin: 0px;
    float: right;
  }

  tbody>tr:hover {
    background-color: #f3f5f4;
    cursor: pointer;
  }

  tr {
    font-size: 11px;
  }

  .selected-row {
    background-color: #ced6d3 !important;
  }


  .table>thead>tr>th {
    line-height: 5px !important;
  }

  .table>tbody>tr>td:first-child {
    text-align: left !important;
  }

  .modal-content-main-area {
    max-height: 100%;
    height: 100%;
    overflow: hidden;
  }

  .modal-content-summary-area {
    max-height: 20%;
    height: 20%;
    overflow: auto;
    overflow-x: hidden;
  }

  .table td,
  .table th {
    padding: 10px !important;
    width: 200px;
  }

  input,
  select {
    height: 25px !important;
    padding: 0px 5px !important;
  }
</style>
<div class="row" style="margin: 5px !important;">
  <div class="modal-content modal-content-main-area">
    <div *ngIf="popupsettings"  style="float:right;width:100%" class="report-table" #prodlistTable id="prodlistTable">
      <!-- <div class="" style="padding: 0px">

        <h5>
          {{ popupsettings.title }}
        </h5>
      </div> -->

      <div class="col-md-12 row table-container" style="padding:0">
        <table id="BlueHeaderResizableTable" #reportHtmlTable style="font-size:12px; width: 100%; overflow-x :auto;"
        class="reportTabel">
          <thead>
            <tr style="background: #D5D5D5;">
              <ng-container *ngFor="let item of popupsettings.columns">
                <th *ngIf="!item.hidden" title="{{item.title}}" width="{{item.width?item.width:'200px'}}">{{ item.title }}</th>
              </ng-container>
              <th *ngIf="popupsettings.showActionButton && popupsettings.actionKeys.length" width="200px">Action</th>
            </tr>
            <tr>
              <ng-container *ngFor="let item of popupsettings.columns;let c=index">
                <td *ngIf="!item.hidden" style="    padding: 5px 10px !important;" width="{{item.width?item.width:'200px'}}">
                  <ng-container *ngIf="(item.type=='string'|| item.type==null) && (!item.noSearch)">
                    <input type="text" class="form-control" (input)="onValueChange($event,$event.target.value,item.key)"
                      placeholder="{{item.title}}">
                  </ng-container>
                  <ng-container *ngIf="(item.type=='list') && (!item.noSearch)">
                    <select (change)="onValueChange($event,$event.target.value,item.key)" class="form-control">
                      <option value="%">Select</option>
                      <ng-container *ngIf="item.filter && item.filter.list.length">
                        <option *ngFor="let l of item.filter.list;let a=index" value="{{l.value}}">{{l?.title}}</option>
                      </ng-container>
                    </select>
                  </ng-container>
                </td>
              </ng-container>
              <td *ngIf="popupsettings.showActionButton && popupsettings.actionKeys.length" width="200px"
                style="    padding: 5px 10px !important;"></td>

            </tr>
          </thead>
          <tbody *ngIf="itemList.length" style="width: fit-content;">
            <tr class="highlight" [class.selected-row]="selectedRowIndex == i" *ngFor="
                  let itemObj of itemList
                    | paginate
                      : {
                          id: tabindex,
                          itemsPerPage: pageSize,
                          currentPage: pageNumber,
                          totalItems: totalItems
                        };
                  let i = index"
                (click)="singleClick(i)">
              <ng-container *ngFor="let item of popupsettings.columns">
                <td *ngIf="!item.hidden" width="{{item.width?item.width:'200px'}}">
                  <div title="{{ itemObj[item.key] }}">
                    {{ getPreparedValue(item,itemObj[item.key])}}
                  </div>
                </td>
              </ng-container>


              <ng-container *ngIf="popupsettings.showActionButton && popupsettings.actionKeys.length">
                <td width="67px">
                  <ng-container *ngFor="let action of popupsettings.actionKeys;let z=index">
                    <span>
                      <a (click)="onActionClicked(action.type,i)" title="{{action.title}}">{{action.text}}&nbsp;&nbsp;</a>
                    </span>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12  mx-auto offset-md-4" *ngIf="itemList.length">
            <pagination-controls [id]="tabindex" (pageChange)="onPageChange($event)" maxSize="9" directionLinks="true"
              autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next"
              screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
              screenReaderCurrentLabel="You're on page"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>