import { Injectable } from '@angular/core';
import { AppSettings } from './AppSettings';
import { AuthService } from './authService.service';
@Injectable({ providedIn: 'root' })
export class SettingService {
    appSetting: AppSettings

    public cacheSetting;
    userProfile: any;
    constructor(public authService: AuthService, public apSetting: AppSettings) {
        this.appSetting = apSetting;
        this.userProfile = authService.getUserProfile()
        this.cacheSetting = authService.getSetting();
    }
}