import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TAcList } from "../../interfaces/Account.interface";
import { MasterRepo } from '@app/_services/masterRepo.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';

import { Membership } from '../../../pages/masters/components/membership/membership';

@Component({
    selector: 'create-membership-dialog',
    templateUrl: './create-membership-dialog.component.html',
    styles: [`
      input,
      select,
      textarea {
        border: 1px solid #cbcbcb;
        border-radius: 2px;
        height: 30px;
        color: black;
      }

      .page-title {
        text-align: center;
      }
      
      .form-control {
        font-size: 14px;
      }
      
      .button-group {
        position: absolute;
        right: 3%;
        top: 3%;
      }
      
      .card-body {
        line-height: 0.4rem;
      }
    `]
})

export class CreateMembershipComponent implements OnInit{
    isActive:boolean;
    membershipForm:FormGroup;
    schemeList:any = [];
    mode: string = 'add';
    membershipObj: Membership = <Membership>{}
    @Output('onsubmit') onsubmit = new EventEmitter();

constructor(private _trnMainService: TransactionService,
  private fb: FormBuilder, 
  private masterService: MasterRepo,
  private spinnerService: SpinnerService,
  private alertService: AlertService,
){
    this.masterService.getMemCatList().subscribe(res =>{
      this.schemeList = res
    });
}

ngOnInit():void{
  this.membershipForm = this.fb.group({
    FNAME: ['',[Validators.required]],
    LNAME: ['',[Validators.required]],
    MOBILE: ['',[Validators.required]],
    ADDRESS: [''],
    SCHEMEID: [''],
    ACCNAME:[''],
  });
}

show() {
  this.isActive = true;
  
}

cancelPopup(){
  this.isActive = false;
  this._trnMainService.inputCode=false;
  this.membershipForm.reset();
}

closeDialog(){
  this.isActive = false;
}

onSave(){
    
  
  let memSaveObj = Object.assign(this.membershipObj,this.membershipForm.value);

  const data={
    mode: this.mode,
    data: memSaveObj
  };
  

  this.spinnerService.show('Data is Saving ...');
  this.masterService.postMembershipGroup(data).subscribe(
    res => {
      if (res['status'] == 'ok') {          
        this.closeDialog();
        this.spinnerService.hide();
        if(res['result']=='ProceedConfirmation'){
          if(confirm("Member is not Created in  Server.Do you want to Process..")){
            this.membershipForm.value.FurtherProceed = 'ProceedConfirmationYes';
            this.onSave();
          }else{
            this.membershipForm.reset();
            this.alertService.hide();
          }
        }else{
          this.membershipForm.reset();
          this.alertService.info(res['result']);
          setTimeout(()=>{
            this.alertService.hide();
          },500);
          this.onsubmit.emit(res['result2']);
        
        }      
 
      }
      else {
        this.alertService.error(res['result'])
        this.spinnerService.hide();
      }
    },
    error =>{
      this.spinnerService.hide();
    }
  )
}
   
    

}