export const PAGES_MENU_LITE = [
  {
    path: 'pages',
    menuType: [0],
    children: [
      {
        path: '',
        menuType: [0],
        data: {
          menu: {
            title: 'IMSPOS',
            icon: 'ion-android-home',
            selected: true,
            expanded: false,
            order: 0
          }
        },
        children: [
          {
            path: 'dashboard',
            menuType: [0],
            data: {
              menu: {
                title: 'Dashboard',
                icon: 'ion-navicon-round',
                selected: true,
                expanded: false,
                order: 0
              }
            }
          },
          // {
          //   path: 'terminal',
          //   menuType: [],
          //   data: {
          //     menu: {
          //       title: 'Terminal Setup',
          //       icon: 'ion-gear-b',
          //       selected: false,
          //       expanded: false,
          //       order: 700
          //     }
          //   }
          // },
          // {
          //   path: 'backup-restore',
          //   menuType: [],
          //   isOnlyCentral: true,
          //   data: {
          //     menu: {
          //       title: 'Backup & Restore',
          //       icon: 'ion-android-upload',
          //       selected: false,
          //       expanded: false,
          //       order: 0
          //     }
          //   }
          // }
        ]
      },
      {
        path: 'masters',
        menuType: [0],
        data: {
          menu: {
            title: 'Masters',
            icon: 'ion-edit',
            selected: false,
            expanded: false,
            order: 2
          }
        },
        children: [
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: 'Masters',
                icon: 'ion-gear-b',
                selected: false,
                expanded: false
              }
            },
            children: [
              {
                path: 'productmaster',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Product Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'partymasterledger',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Party Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
              
              {
                path: 'category',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Category Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
            
              {
                path: 'customer',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Customer Profile Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
             
            
              // {
              //   path:'schedule',
              //   menuType: [0],
              //   data:{
              //     menu:{
              //       title: 'Scheme Schedule',
              //       selected: false,
              //       expanded: false
              //     }
              //   }
              // },
              // {
              //   path:'scheme',
              //   menuType: [0],
              //   data:{
              //     menu:{
              //       title: 'Scheme Masterc',
              //       selected: false,
              //       expanded: false
              //     }
              //   }
              // },
             
             
                 
            ]
          },
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: "Others"
              }
            },
            children: [
              {
                path: 'unit',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Unit Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'norm',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Norms Setting',
                    icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                },
              },
              {
                path: 'barcodemapping',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Barcode Mapping',
                    icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                },
              },
              // {
              //   path: 'master-settings',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Setting Info',
              //       // icon: 'ion-gear-b',
              //       selected: false,
              //       expanded: false
              //     }
              //   }
              // },
             
           
            
              
            
             
            ]
          },
        ]
      },

      {
        path: 'transaction',
        menuType: [0],
        data: {
          menu: {
            title: 'Transaction',
            icon: 'ion-stats-bars',
            selected: false,
            expanded: false,
            order: 3
          }
        },
        children: [
          {
            path: 'sales',
            menuType: [0],
            data: {
              menu: {
                title: 'Sales',
                icon: 'ion-arrow-graph-up-right',

                selected: false,
                expanded: false,
                order: 4
              }
            },
            children: [
              
              {
                path: 'abbreviated-sales-invoice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Abbreviated Sales Invoice'
                  }
                }
              },
              {
                path: 'add-sales-invoice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Tax Invoice'
                  }
                }
              },
             

              {
                path: 'add-creditnote-itembase',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Credit Note'
                  }
                }
              },
              {
                path: 'cash-handover',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Cash Handover'
                  }
                }
              }
              
                         
            ]
          },         
          {
            path: 'purchases',
            menuType: [0],
            data: {
              menu: {
                title: 'Purchase',
                icon: 'ion-arrow-graph-up-right',
                selected: false,
                expanded: false,
                order: 4
              }
            },
            children: [          
              {
                path: 'add-purchase-invoice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Purchase Invoice'
                  }
                }
              },
              
              { 
                path: 'add-debitnote-itembase',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Purchase Return'
                  }
                }
              }
            
            
            ]
          },
          {
            path: 'inventory',
            menuType: [0],
            data: {
              menu: {
                title: 'Inventory Movement'
              }
            },
            children: [
             
              {
                path: 'openingstockentry',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Opening stock entry'
                  }
                }
              },
              {
                path: 'production-entry',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Item Unit Conversion'
                  }
                }
              }
              

              
            ]
          },
          // {
          //   path: 'payment',
          //   menuType: [0],
          //   data: {
          //     menu: {
          //       title: 'Payment & Receipt'
          //     }
          //   },
          //   children: [
              
          //     {
          //       path: 'receipt-entry',
          //       menuType: [0],
          //       data: {
          //         menu: {
          //           title: 'Receipt Entry'
          //         }
          //       }
          //     },
          //     {
          //       path: 'payment-collection',
          //       menuType: [0],
          //       data: {
          //         menu: {
          //           title: 'Payment Entry'
          //         }
          //       }
          //     },
              
          //   ]
          // },
        
        ]
        
      },
     
      {
        path: 'reports',
        menuType: [0],
        data: {
          menu: {
            title: 'Report',
            icon: 'ion-ios-list-outline',
            selected: false,
            expanded: false,
            order: 1
          }
        },

        children: [
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: "Purchase Report"
              }
            },
            children: [
              {
                path: "purchasebookreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Book Report",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "debitnotebook-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Debit Note Book Report"
                  }
                }
              },
              {
                path: "purchasereport-itemwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Report - Item Wise"
                  }
                }
              },
              {
                path: "purchasereport-itemwise-detail",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Report - Item Wise Detail"
                  }
                }
              },
             
            ],
    
          },
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: "Sales Report"
              }
            },
            children: [
              {
                path: "salesbook-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Book Report"
                  }
                }
              },
              {
                path: "creditnotebook-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Credit Note Book Report"
                  }
                }
              },
              {
                path: "salesreport-itemwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Item Wise",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "salesreport-itemwise-detail",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Item Wise Detail"
                  }
                }
              },
             
              {
                path: "salesprofitability-analysis",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Profitability Analysis Report"
                  }
                }
              }, 
              {
                path: "sales-collection-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Collection Report"
                  }
                }
              }
            ]
          },
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: 'Inventory Report',
                selected: false,
                expanded: false
              }
            },
            children: [
              {
                path: 'stock-summary-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Summary Report'
                  }
                }
              },
              {
                path: 'stock-ledger-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Ledger Report'
                  }
                }
              },
              {
                path: 'stock-valuation-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Valuation Report'
                  }
                }
              },
             
              {
                path: 'stock-abcanalysis-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Movement Analysis Report'
                  }
                }
              },
              
               {
                path: 'openingstock-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Opening Stock Report'
                  }
                }
              }
                        
              
            ]
            
          },

          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: 'VAT Report',
                selected: false,
                expanded: false
              }
            },
            children: [
              {
                path: 'vat-saleseregisterreport-new',
                menuType: [0],
                data: {
                  menu: {
                    title: 'VAT Sales Register Report'
                  }
                }
              },
            
              {
                path: 'vat-purchaseregisterreport-new',
                menuType: [0],
                data: {
                  menu: {
                    title: 'VAT Purchase Register Report'
                  }
                }
              },
              {
                path: 'annexure13report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Annexure 13 Report'
                  }
                }
              },
           
              {
                path: 'vat-other-adjustment-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'VAT Other Adjustment Report'
                  }
                }
              },
             
             
              {
                path: "datereport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Materialized View Report (Annex 5)",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "transreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Transaction Activity Log",
                    selected: false,
                    expanded: false
                  }
                }
              },
              
              
            ]
          },
         
          
        
         
        ]
      },

      {
        path: 'account',
        menuType: [0],
        data: {
          menu: {
            title: 'Accounting',
            icon: 'ion-gear-b',
            selected: false,
            expanded: false,
            order: 2
          }
        },
        children: [
          {
            path: "account",
            data: {
              menu: {
                title: "Account",
                icon: "ion-android-people",
                selected: false,
                expanded: false,
                order: 1000
              }
            }

          }
        ]

      },
      // {
      //   // path: "masters",
      //   path: 'account',
      //   menuType: [0],
      //   data: {
      //     menu: {
      //       title: 'Financial Account',
      //       icon: 'fa fa-usd'
      //     }
      //   }, children: [
      //     {
      //       path: 'test',
      //       data: {
      //         menu: {
      //           title: 'Test',
      //           icon: 'ion-android-people',
      //           selected: false,
      //           expanded: false,
      //           order: 1000
      //         }
      //       }
      //     }]
      // },

      
      {
        path: 'configuration',
        menuType: [0],
        data: {
          menu: {
            title: 'Configuration',
            icon: 'ion-gear-b',
            selected: false,
            expanded: false,
            order: 2
          }
        },
        children: [
          {
            path: 'userManager',
            menuType: [0],
            data: {
              menu: {
                title: 'User Manager',
                icon: 'ion-android-people',
                selected: false,
                expanded: false,
                order: 90
              }
            },
            children: [
              {
                path: 'userlist',
                menuType: [0],
                data: {
                  menu: {
                    title: 'User List'
                  }
                }
              }
              
            ]
          },
          // {
          //   path: "master-migration",
          //   menuType: [0],
          //   data: {
          //     menu: {
          //       title: "Master Migration"
          //     }
          //   }
          // }     
         
       
        ]
      }

      // {
      //   path: "help",
      //   data: {
      //     menu: {
      //       title: "Help",
      //       icon: "ion-android-home",
      //       url: "./../../../assets/help.pdf",
      //       target: "_blank",
      //       selected: false
      //     }
      //   }
      // }



      // {
      //   path: "configure-printer",
      //   data: {
      //     menu: {
      //       title: "Configure Printer",
      //       icon: "ion-android-print",
      //       url: "http://www.imsnepal.com:1060/PrintSetup.exe",
      //       target: "_blank",
      //       selected: false
      //     }
      //   }
      // }

    ]
  }
]



export interface MENU {
  expanded?: boolean;
  icon?: string;
  order?: number;
  selected?: boolean;
  target?: string;
  title?: string;
  children: CHILDREN[];
}


export interface CHILDREN {
  expanded?: boolean;
  icon?: string;
  order?: number;
  selected?: boolean;
  target?: string;
  title?: string;
  pathMatch?: string;
  route?: Router[];
}



export interface Router {
  children?: RouterData[];
  data?: any;
  menuType?: string[];
  path?: string;
  paths?: string[];
}


export interface RouterData {
  data?: any;
  menuType?: string[];
  path?: string;
  paths?: string[];
}
