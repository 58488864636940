import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { Router } from '@angular/router';
import { AlertService } from '@app/common/shared/alert/alert.service';
import { GlobalState } from '@app/_helperServices/global.state';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(public http: HttpClient,
        public router:Router,
        private gblstate: GlobalState,
        public alertService:AlertService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('USER_PROFILE')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
       return this.currentUserSubject.value;
    }
    public setCurrentUserValue(value) {
        this.currentUserSubject.next(value);
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/jwt`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('USER_PROFILE', JSON.stringify(user.profile));
                localStorage.setItem('setting', JSON.stringify(user.setting));
                sessionStorage.setItem('USER_PROFILE', JSON.stringify(user.profile));
                localStorage.setItem('TOKEN', JSON.stringify(user.token));
                localStorage.setItem('port',JSON.stringify(user.ports))
                this.currentUserSubject.next(user);
                if(user.license.message && user.license.message!=""){
                    this.alertService.info(user.license.message, user.license.link);
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        console.log("call form aunthenticaiton");
        this.currentUserSubject.next(null);
        this.http.get(`${environment.apiUrl}/logout`).subscribe((res) => {
            if (res['status'] == "ok") {
                localStorage.removeItem('USER_PROFILE');
                localStorage.removeItem('TOKEN');
                localStorage.removeItem('setting');
            }
        },error=>{
            this.alertService.error(error.error)
            console.log("Logout error",error);
        });
        // localStorage.removeItem('USER_PROFILE');
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('setting');

        window.sessionStorage.removeItem('currentUser');
        this.gblstate.notifyDataChanged("LoggedIn", false);
        this.gblstate.setGlobalSetting("LoggedIn", [false]);
    }
    InitialLoad(){
        this.currentUserSubject.next(null);
        window.sessionStorage.removeItem('currentUser');
        this.gblstate.notifyDataChanged("LoggedIn", false);
        this.gblstate.setGlobalSetting("LoggedIn", [false]);
    }

    updateDatabase(){
        const res = { status: 'error', result: '',message:'' };
        const returnSubject: Subject<any> = new Subject();

        this.http.get(`${environment.apiUrl}/updateDatabase`).subscribe((data:any)=>{
            if(data.status === 'ok'){
                res.status = 'ok';
                res.result = data.result;
                res.message = data.message;
                returnSubject.next(res);
                returnSubject.unsubscribe();
            }else{
                res.status = 'error';
                res.result = data.result;
                returnSubject.next(res);
                returnSubject.unsubscribe();
            }
        },
        error =>{
            (res.status = 'error2'),(res.result = error);
            returnSubject.next(res);
            returnSubject.unsubscribe();
        }
        );
        return returnSubject;
    }

    databaseUpdate(){
        const res = { status: 'error', result: '',message:'' };
        const returnSubject: Subject<any> = new Subject();

        this.http.get(`${environment.apiUrl}/webposBackendDatabaseUpdate`).subscribe((data:any)=>{
            if(data.status === 'ok'){
                res.status = 'ok';
                res.result = data.result;
                res.message = data.message;
                returnSubject.next(res);
                returnSubject.unsubscribe();
            }else{
                res.status = 'error';
                res.result = data.result;
                returnSubject.next(res);
                returnSubject.unsubscribe();
            }
        },
        error =>{
            console.log("error", error);
        }
        );
        return returnSubject;
    }
}