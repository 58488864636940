import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiselectGenericPopUpComponent } from "./multiselect-generic-grid.component";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  declarations: [MultiselectGenericPopUpComponent],
  exports: [MultiselectGenericPopUpComponent]
})
export class MultiselectGenericPopupGridModule {
  static forRoot(): ModuleWithProviders<MultiselectGenericPopupGridModule> {
    return {
      ngModule: MultiselectGenericPopupGridModule    };
  }
}
