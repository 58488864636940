import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { JwtInterceptor, ErrorInterceptor } from './_helperServices';
import { LoginComponent } from './login';
import { Footer } from './_layout/nav/footer.component';
import { NavigationLayout } from './_layout/nav';
import { SpinnerService } from './common/shared/spinner/spinner.service';
import { AlertComponent } from './common/shared/alert/directives/alert.component';
import { SpinnerComponent } from './common/shared/spinner/directives/spinner.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppConfigService, AppConfigModule } from './_helperServices/app-config.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { NavigationPreventorComponent } from './common/services/navigation-perventor/directives/navigation-preventor.component';
import { MessageDialog } from './common/EntryComponents/messageDialog/messageDialog.component';
import { LoginDialog } from './common/EntryComponents/logindialog/logindialog.component';
import { InventryYearEndDialog } from './common/YearEndDialog/InventryYearEnd.component';;
import { ServiceWorkerModule } from '@angular/service-worker'
;
import { environment } from '../environments/environment'
import { AlertService } from './common/shared/alert/alert.service';

import { ProductMasterService } from './pages/masters/components/ProductMaster/ProductMaster.Service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { popupListModule } from './common/popup/popuplist.module';
import { GenericPopupGridModule } from './common/popup/generic-grid/generic-popup-grid.module';
import { CreateCustomerProfileModule } from './common/popup/customer-profile-popup/create-customer-dialog.module';
import { QuickPartyMasterModule } from './common/popup/quick-party-master/quick-party-master.module';
import { CreateMembershipModule } from './common/popup/create-membership-popup/create-membership-dialog.module';
import { HotkeyModule } from 'angular2-hotkeys';
import { NotificationPopupGridModule } from './common/popup/notification/notification-popup-grid.module';
import { LoadPurchaseOrderModule } from './common/popup/load-purchase-order/load-purchase-order.module';
import { ImportPurchaseDetailsModule } from './common/popup/import-purchase-details/import-purchase-details.module';
import { ReverseEntryModule } from './common/popup/reverse-entry-popup/reverse-entry.module';
import { AngularMultiSelectModule } from './node_modules/angular4-multiselect-dropdown/index';
import { HelpdescriptionFormModule } from './common/popup/helpdescription-form/helpdescription-form.module';
import { NotificationComponent, SimpleNotificationsComponent, SimpleNotificationsModule } from './common/notification';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MultiselectGenericPopupGridModule } from './common/popup/multiselect-generic-grid/multiselect-generic-grid.module';
// import { NepaliDatePickerModule } from './common/nepali-date-picker/nepali-date-picker.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SimpleSuggestComponent } from './common/simple-suggest/simple-suggest.component';

export function loadModuleConfig(config: AppConfigService) {

}

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        popupListModule,GenericPopupGridModule,NotificationPopupGridModule,CreateCustomerProfileModule,QuickPartyMasterModule,CreateMembershipModule,LoadPurchaseOrderModule,  ImportPurchaseDetailsModule, ReverseEntryModule,
        HelpdescriptionFormModule,        
        HotkeyModule.forRoot(),
        AngularMultiSelectModule,
        SimpleNotificationsModule ,
         NgxDaterangepickerMd.forRoot(),
         MultiselectGenericPopupGridModule,
         ScrollingModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        NavigationLayout,
        Footer,
        AlertComponent,
        SpinnerComponent,
        NavigationPreventorComponent,
        MessageDialog,
        LoginDialog,
        InventryYearEndDialog,
        SimpleSuggestComponent
    ],
    entryComponents: [MessageDialog,LoginDialog,InventryYearEndDialog],
    providers: [
        SpinnerService,
        AppConfigService,
        AlertService,
        ProductMasterService,
        AppConfigModule.init(),  
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    
})
export class AppModule { }