import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { PoplistComponent } from './PopItemList/PopItems.component';
import { PopBatchComponent } from './PopBatchList/PopBatch.component';
import { PopSchemeChooserComponent } from './PopScheme/popscheme';
import { PopPartyChooserComponent } from './PopPartyList/popPartyList';
import { PopCategoryComponent } from './PopupCategoryList/PopCategory.component';
import { PopItemGrpComponent } from './PopupItemGrpList/PopItemGrp.component';
import { BatchListPopup } from './BatchListPopup/BatchListPopup.component';
import { SharedModule } from '../shared/shared.module';
import { SearchPipe } from './BatchListPopup/searchPipe';
// import { GenericPopupGridModule } from './generic-grid/generic-popup-grid.module';
// import { HelpdescriptionFormComponent } from './helpdescription-form/helpdescription-form.component';



@NgModule({
    imports: [
        SharedModule,
        ModalModule.forRoot(),
        NgxPaginationModule,
        // GenericPopupGridModule.forRoot()
    ],
    declarations: [PoplistComponent, PopBatchComponent, PopCategoryComponent,
        PopItemGrpComponent, PopSchemeChooserComponent
        , PopPartyChooserComponent, BatchListPopup,SearchPipe

    ],
    exports: [
        PoplistComponent, PopBatchComponent, PopCategoryComponent,
        PopItemGrpComponent, PopSchemeChooserComponent
        , PopPartyChooserComponent, BatchListPopup,SearchPipe

    ],
    providers: []

})

export class popupListModule { }
