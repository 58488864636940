import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './_helperServices/authService.service';
import { GlobalState } from './_helperServices/global.state';
import { environment } from '@environments/environment';

export abstract class AppComponentBase {

    public _authService: AuthService;
    public _state: GlobalState;
    public _http: HttpClient;

    constructor(injector: Injector) {
        this._authService = injector.get(AuthService);
        this._state = injector.get(GlobalState);
        this._http = injector.get(HttpClient);
    }

    canActiveMenu(menu: string, right: string) {
        return true;
        // this._authService.checkMenuRight(menu, 'list');
    }

    // end accountlist
    public get apiUrl(): string {
       // const url = this._state.getGlobalSetting('apiUrl');

        const url = environment.apiUrl;
         
        let apiUrl = '';
        if (!!url && url.length > 0) { apiUrl = url; }
        return apiUrl;
    }
}
