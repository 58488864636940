import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateMembershipComponent } from './create-membership-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CreateMembershipComponent],
  exports: [CreateMembershipComponent]
})
export class CreateMembershipModule {
  static forRoot(): ModuleWithProviders<CreateMembershipModule> {
    return {
      ngModule: CreateMembershipModule,
    };
  }
}
