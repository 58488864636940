<div
  class="modal fade"
  bsModal
  #alertModeal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true" 
>

</div>
