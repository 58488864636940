import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
  public subject = new Subject<any>();
  public showHideSubject = new Subject<boolean>();
  public keepAfterNavigationChange = false;
  public autoDisplay: boolean = true;
  public emitShowHideSubject = new Subject<boolean>(); 


  constructor(public router: Router) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  success(message: string, link: string = '',autoDisplay = true) {
    return new Promise((resolve, reject) => {
      this.autoDisplay = autoDisplay;
      this.subject.next({ type: "success", text: message, link: link });
      resolve();
    }); 
  }

  error(message: string, link: string = '',autoDisplay = false) {
    return new Promise((resolve, reject) => {
      this.autoDisplay = autoDisplay; 
      this.subject.next({ type: "error", text: message, link: link });
      this.show();
      resolve();
    }); 
  }

  warning(message: string, link: string = '',autoDisplay = false) {
    return new Promise((resolve, reject) => {
      this.autoDisplay = autoDisplay;
      this.subject.next({ type: "warning", text: message, link: link });
      this.show()
      resolve();
    });
  }

  info(message: string, link: string = '',autoDisplay = false) {
    return new Promise((resolve, reject) => {
      this.autoDisplay = autoDisplay;
      this.subject.next({ type: "info", text: message, link: link });
      this.show();
      resolve();
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  getShowHide(): Observable<boolean> {
    return this.showHideSubject.asObservable();
  }

  show() {
    this.showHideSubject.next(true);
  }

  hide() {
    this.showHideSubject.next(false);
  }
}
