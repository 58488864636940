export const PAGES_MENU = [
  {
    path: 'pages',
    menuType: [0],
    children: [
      {
        path: '',
        menuType: [0],
        data: {
          menu: {
            title: 'IMSPOS',
            icon: 'ion-android-home',
            selected: true,
            expanded: false,
            order: 0
          }
        },
        children: [
          {
            path: 'dashboard',
            menuType: [0],
            data: {
              menu: {
                title: 'Dashboard',
                icon: 'ion-navicon-round',
                selected: true,
                expanded: false,
                order: 0
              }
            }
          },
          {
            path: 'terminal',
            menuType: [],
            data: {
              menu: {
                title: 'Terminal Setup',
                icon: 'ion-gear-b',
                selected: false,
                expanded: false,
                order: 700
              }
            }
          },
          {
            path: 'backup-restore',
            menuType: [],
            isOnlyCentral: true,
            data: {
              menu: {
                title: 'Backup & Restore',
                icon: 'ion-android-upload',
                selected: false,
                expanded: false,
                order: 0
              }
            }
          }
        ]
      },
      {
        path: 'masters',
        menuType: [0],
        data: {
          menu: {
            title: 'Masters',
            icon: 'ion-edit',
            selected: false,
            expanded: false,
            order: 2
          }
        },
        children: [
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: 'Masters',
                icon: 'ion-gear-b',
                selected: false,
                expanded: false
              }
            },
            children: [
              {
                path: 'productmaster',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Product Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'partymasterledger',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Party Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
              
              {
                path: 'category',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Category Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
            
              {
                path: 'warehouse',
                menuType: [0],
                isOnlyCentral: false,
                data: {
                  menu: {
                    title: 'Warehouse Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'membership',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Membership Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'point',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Point Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'customer',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Customer Profile Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'salesman',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Sales-Man Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'route-master',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Route Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path:'routecoverageplan',
                menuType: [0],
                data:{
                  menu:{
                    title: 'Route Coverage Plan',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path:'schedule',
                menuType: [0],
                data:{
                  menu:{
                    title: 'Schedule',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path:'scheme',
                menuType: [0],
                data:{
                  menu:{
                    title: 'Scheme',
                    selected: false,
                    expanded: false
                  }
                }
              },
              // {
              //   path:'marginScheme',
              //   menuType: [0],
              //   data:{
              //     menu:{
              //       title: 'Margin Scheme',
              //       selected: false,
              //       expanded: false
              //     }
              //   }
              // },
              {
                path:'rategroup',
                menuType: [0],
                data:{
                  menu:{
                    title: 'Rate Group Master',
                    selected: false,
                    expanded: false
                  }
                }
              }, 
              {
                path:'Contract-price',
                menuType: [0],
                data:{
                  menu:{
                    title: 'Contract Price',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path:'Companyrate',
                menuType: [0],
                data:{
                  menu:{
                    title: 'Company Rate',
                    selected: false,
                    expanded: false
                  }
                }
              }, 
              {
                path:'location',
                menuType: [0],
                data:{
                  menu:{
                    title: 'Location Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path:'location-mapping',
                menuType: [0],
                data:{
                  menu:{
                    title: 'Location Item Mapping',
                    selected: false,
                    expanded: false
                  }
                }
              },       
            ]
          },
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: 'Inventory Info',
                icon: 'ion-gear-b',
                selected: false,
                expanded: false
              }
            },
            children: [           
              {
                path: 'departmentvscategory',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Department Vs Categories',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'categorymaster',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Variant Category Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'productwise',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Product Variant',
                    selected: false,
                    expanded: false
                  }
                }
              },

              {
                path: 'barcode',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Barcode Printing',
                    icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                },
              },
              {
                path: 'norm',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Norms Setting',
                    icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                },
              },
              {
                path: 'barcodemapping',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Barcode Mapping',
                    icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                },
              },


            ]
          }
          ,{
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: "Others"
              }
            },
            children: [
              {
                path: 'unit',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Unit Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'color',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Color Master',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'area-master',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Area Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'cost-center',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Cost Center Master',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'bulkprice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Bulk Sales Price Update',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'master-settings',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Setting Info',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'branchwise-price',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Branch Wise Price ',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'manualstockentry',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Manual Stock Entry',
                    // icon: 'ion-gear-b',
                    selected: false,
                    expanded: false
                  }
                }
              },
             
            ]
          },
        ]
      },

      {
        path: 'transaction',
        menuType: [0],
        data: {
          menu: {
            title: 'Transaction',
            icon: 'ion-stats-bars',
            selected: false,
            expanded: false,
            order: 3
          }
        },
        children: [
          {
            path: 'sales',
            menuType: [0],
            data: {
              menu: {
                title: 'Sales',
                icon: 'ion-arrow-graph-up-right',

                selected: false,
                expanded: false,
                order: 4
              }
            },
            children: [
              {
                path: 'performa-invoice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Proforma Invoice'
                  }
                }
              },
              {
                path: 'add-sales-invoice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Sales Invoice'
                  }
                }
              },
              {
                path: 'add-membership-sales-invoice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Abbreviated-Membership Sales Invoice'
                  }
                }
              },
              {
                path: 'abbreviated-sales-invoice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Abbreviated Sales Invoice'
                  }
                }
              },
              {
                path: 'add-sales-invoice-wholesale',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Sales Invoice(wholeSale)'
                  }
                }
              },

              {
                path: 'add-creditnote-itembase',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Sales Return'
                  }
                }
              },
              {
                path: 'salesorder',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Sales Order'
                  }
                }
              },
              {
                path: 'self-billing',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Self Billing'
                  }
                }
              },
              {
                path: 'quotation',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Quotation'
                  }
                }
              },
              {
                path: 'deliver-chalan',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Load Sheet'
                  }
                }
              },
                         
            ]
          },         
          {
            path: 'purchases',
            menuType: [0],
            data: {
              menu: {
                title: 'Purchase',
                icon: 'ion-arrow-graph-up-right',
                selected: false,
                expanded: false,
                order: 4
              }
            },
            children: [          
              {
                path: 'add-purchase-invoice',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Purchase Invoice'
                  }
                }
              },
              {
                path: 'purchase-import',
                menuType: [0],
                data:{
                  menu :{
                    title: "Import Purchase"
                  }
                }
              },
              { 
                path: 'add-debitnote-itembase',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Purchase Return'
                  }
                }
              },
              {
                path: 'add-purchase-order',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Purchase Order'
                  }
                }
              },
              {
                path: 'purchase-import-order',
                menuType: [0],
                data:{
                  menu :{
                    title: "Import Purchase Order"
                  }
                }
              },
              {
                path:'goods-received-note',
                menuType:[0],
                data:{
                  menu:{
                    title: 'Goods Received Note'
                  }
                }
              }
            
            
            ]
          },
          {
            path: 'inventory',
            menuType: [0],
            data: {
              menu: {
                title: 'Inventory Movement'
              }
            },
            children: [
              {
                path: 'add-stock-issue',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Issue'
                  }
                }
              },
              {
                path: 'stock-issue-return',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Issue Return'
                  }
                }
              },
              
              // {
              //   path: 'stock-issue-approval',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Stock Issue Approval'
              //     }
              //   }
              // },
              // {
              //   path: 'StockSettlementEntry',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Stock Settlement Entry'
              //     }
              //   }
              // },
              // {
              //   path: 'StockSettlementEntryApproval',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Stock Settlement Approval'
              //     }
              //   }
              // },
              {
                path: 'openingstockentry',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Opening stock entry'
                  }
                }
              },
              {
                path: 'add-branch-out',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Transfer Out'
                  }
                }
              },
              // {
              //   path: 'transfer-out-approval',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Transfer Out Approval'
              //     }
              //   }
              // },
              {
                path: 'add-branch-in',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Transfer In'
                  }
                }
              },
              {
                path: 'repack-entry',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Repack Entry'
                  }
                }
              },
              {
                path: 'requisition-entry',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Requisition Entry',
                  }
                }
              },
              {
                path: 'requisition-approval',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Requisition Approval'
                    // selected: false,
                    // expanded: false
                  }
                }
              },
              {
                path: 'production-entry',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Production Entry'
                  }
                }
              },
              {
                path: "stock-adjustment",
                menuType: [0],
                data: {
                  menu:{
                    title: 'Stock Adjustment',
                    selected: false,
                    expanded: false
                  }
                }
                
              },
              {
                path: "variant-adjustment",
                menuType: [0],
                data: {
                  menu:{
                    title: 'Variant Adjustment',
                    selected: false,
                    expanded: false
                  }
                }
                
              },
              {
                path: "batch-adjustment",
                menuType: [0],
                data: {
                  menu:{
                    title: 'Batch Adjustment',
                    selected: false,
                    expanded: false
                  }
                }
                
              },
              // {
              //   path: 'consumption-entry',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Consumption Entry'
              //     }
              //   }
              // }

              
            ]
          },
          {
            path: 'recurringTransaction',
            menuType: [0],
            data: {
              menu: {
                title: 'Recurring Transaction'
              }
            },
            children: [
              {
                path: "recurring-item-log",
                menuType: [0],
                data: {
                  menu:{
                    title: 'Recurring Transaction Log',
                    selected: false,
                    expanded: false
                  }
                }
                
              },
              
            ]
          },
          {
            path: 'jobcard',
            menuType: [0],
            data: {
              menu: {
                title: 'JobCard'
              }
            },
            children: [
              {
                path: 'jobcard-registration',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Job Card Registration'
                  }
                }
              },
              
            ]
          },
          
        
        ]
        
      },
     
      {
        path: 'reports',
        menuType: [0],
        data: {
          menu: {
            title: 'Report',
            icon: 'ion-ios-list-outline',
            selected: false,
            expanded: false,
            order: 1
          }
        },

        children: [
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: "Purchase Report"
              }
            },
            children: [
              {
                path: "purchasebookreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Book Report",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "debitnotebook-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Debit Note Book Report"
                  }
                }
              },
              {
                path: "purchasereport-itemwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Report - Item Wise"
                  }
                }
              },
              {
                path: "purchasereport-itemwise-detail",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Report - Item Wise Detail"
                  }
                }
              },
              {
                path: "purchase-flow-report-monthwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Flow Report - Month Wise"
                  }
                }
              },
              {
                path: "purchasereport-supplierwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Report - Supplier Wise"
                  }
                }
              },
              {
                path: "PurchaseReportCategoryWise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Report - Category Wise"
                  }
                }
              },
              {
                path: "PurchaseReportProductTypeWise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Report - Product Type Wise"
                  }
                }
              },
              {
                path: "purchaseorderreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Order Report"
                  }
                }
              },
              {
                path: "purchaseorder-outstanding",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Order - Outstanding Report"
                  }
                }
              },
              {
                path: "grn-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "GRN Report"
                  }
                }
              },
            ],
    
          },
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: "Sales Report"
              }
            },
            children: [
              {
                path: "salesbook-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Book Report"
                  }
                }
              },
              {
                path: "creditnotebook-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Credit Note Book Report"
                  }
                }
              },
              {
                path: "salesreport-itemwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Item Wise",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "salesreport-itemwise-detail",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Item Wise Detail"
                  }
                }
              },
              {
                path: "sales-flow-report-monthwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Flow Report - Month Wise",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "salesreport-customerwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Customer Wise"
                  }
                }
              },
              {
                path: "SalesReportCategoryWise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Category Wise"
                  }
                }
              },
              {
                path: "SalesReportProductTypeWise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Product Type Wise"
                  }
                }
              },
              {
                path: "salesreport-supplierwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Supplier Wise"
                  }
                }
              },  
              {
                path: "salesprofitability-analysis",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Profitability Analysis Report"
                  }
                }
              }, 
              {
                path: "quotationreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Quotation Report"
                  }
                }
              },
              {
                path: "salesorderreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Order Report"
                  }
                }
              },
              {
                path: "salesorderoutstanding",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Order - Outstanding Report"
                  }
                }
              },
              {
                path: "sales-collection-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Collection Report"
                  }
                }
              },
              {
                path: "shift-close-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Shift Close Report"
                  }
                }
              },
              {
                path: "salesrevenue-analysisreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Revenue Analysis Report"
                  }
                }
              },
              {
                path: "salesreport-salesmanwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report -  Salesman Wise"
                  }
                }
              },
              {
                path: "proformainvoicereport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Proforma Invoice Report"
                  }
                }
              }, 
              {
                path: "salesreport-membershipwise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Sales Report - Membership Wise"
                  }
                }
              }, 
              {
                path: "worsesales-analysisreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Worse Sales Analysis Report"
                  }
                }
              }, 
              {
                path: "topnsales-analysisreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Top N Sales Analysis Report"
                  }
                }
              }
            ],
          },
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: 'Inventory Report',
                selected: false,
                expanded: false
              }
            },
            children: [
              {
                path: 'stock-summary-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Summary Report'
                  }
                }
              },
              {
                path: 'stock-ledger-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Ledger Report'
                  }
                }
              },
              {
                path: 'stock-valuation-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Valuation Report'
                  }
                }
              },
              {
                path: 'branch-transfer-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Branch Transfer Report'
                  }
                }
              },
              {
                path: 'item-expiry-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Item Expiry Report'
                  }
                }
              },
              {
                path: 'max-stocklevel-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Maximum Stock Level Report'
                  }
                }
              },
              {
                path: 'minimum-stocklevel-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Minimum Stock Level Report'
                  }
                }
              },
              {
                path: 'reorder-levelstock-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Reorder Level Stock Report'
                  }
                }
              },
              {
                path: 'stock-abcanalysis-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Movement Analysis Report'
                  }
                }
              },
              {
                path: "currentstock-warehousewise",
                menuType: [0],
                data: {
                  menu: {
                    title: "Consolidated Stock Report - Warehouse Wise"
                  }
                }
              },
              {
                path: 'consolidatedstock-report-branchwise',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Consolidated Stock Report - Division Wise'
                  }
                }
              },
              {
                path: "repack-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Repack Report"
                  }
                }
              },
              {
                path: "requisition-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Requisition Report"
                  }
                }
              },
              {
                path: "stockageing-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Stock Ageing Report"
                  }
                }
              },
              {
                path: 'branch-transferout-vs-inreport',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Branch Transfer Out Vs In Report'
                  }
                }
              },
              {
                path: 'stock-variance-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Variance Report'
                  }
                }
              },
              // {
              //   path: 'stock-report-warehouse-wise',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Stock Report - Warehouse Wise'
              //     }
              //   }
              // }, 
              // {
              //   path: 'branch-transfer-inreport-itemwise',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Branch Transfer In Report - Item Wise'
              //     }
              //   }
              // },
              // {
              //   path: 'branch-transferout-itemwise',
              //   menuType: [0],
              //   data: {
              //     menu: {
              //       title: 'Branch Transfer Out Report - Item Wise'
              //     }
              //   }
              // },
               {
                path: 'openingstock-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Opening Stock Report'
                  }
                }
              },
                        
              {
                path: 'stock-issue-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Stock Issue Report'
                  }
                }
              },
              {
                path: 'consolidatedsales-report-branchwise',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Consolidated Sales Report - Division Wise'
                  }
                }
              },
              {
                path: 'consolidatedpurchase-report-branchwise',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Consolidated Purchase Report - Division Wise'
                  }
                }
              },
            ]
            
          },
          {
            path: '',
            menuType: [0],
            data: {
              menu: {
                title: 'VAT Report',
                selected: false,
                expanded: false
              }
            },
            children: [
              {
                path: 'vat-saleseregisterreport-new',
                menuType: [0],
                data: {
                  menu: {
                    title: 'VAT Sales Register Report'
                  }
                }
              },
              {
                path: 'credit-note-sales-return-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Credit Note (Sales Return) Register Report'
                  }
                }
              },
              {
                path: 'vat-purchaseregisterreport-new',
                menuType: [0],
                data: {
                  menu: {
                    title: 'VAT Purchase Register Report'
                  }
                }
              },
              {
                path: 'annexure13report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Annexure 13 Report'
                  }
                }
              },
              {
                path: 'debitnote-purchasereturn-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Debit Note (Purchase Return) Register Report'
                  }
                }
              },
              {
                path: 'vat-other-adjustment-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'VAT Other Adjustment Report'
                  }
                }
              },
              {
                path: "onelakhsalesreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "One lakh above sales report",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "onelakhpurchasereport",
                menuType: [0],
                data: {
                  menu: {
                    title: "One lakh above purchase report",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "datereport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Annex 5 Report",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: "transreport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Transaction Activity Log",
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'saleseregisterreport-dialog',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Sales Register Report - Old Format'
                  }
                }
              },
              {
                path: "purchaseRegisterReport",
                menuType: [0],
                data: {
                  menu: {
                    title: "Purchase Register Report - Old Format",
                    selected: false,
                    expanded: false
                  }
                }
              },
              
            ]
          },
          {
            path: '',
            menuType: [0],
            data:{
              menu:{
                title: 'Miscellaneous Report',
                selected: false,
                expanded: false
              }
            },
            children:[
              {
                path:"membershippointreport",
                menuType:[0],
                data:{
                  menu:{
                    title: "Membership Point Report",
                    selected: false,
                    expanded: false
                  }
                }
              },
              // {
              //   path:'price-changelog-report',
              //   menuType:[0],
              //   data:{
              //     menu:{
              //       title: 'Price Change Log Report',
              //       selected: false,
              //       expanded: false
              //     }
              //   }
              // },
              {
                path: "raw-data-report",
                menuType: [0],
                data: {
                  menu: {
                    title: "Raw Data Report"
                  }
                }
              },
              {
                path:'rate-group-report',
                menuType:[0],
                data:{
                  menu:{
                    title: 'Rate Group Report',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path:'salesprice-changelog-report',
                menuType:[0],
                data:{
                  menu:{
                    title: 'Sales Price Change Log Report',
                    selected: false,
                    expanded: false
                  }
                }
              },

            ]
          },
          {
            path: '',
            menuType: [0],
            data:{
              menu:{
                title: 'Recurring Report',
                selected: false,
                expanded: false
              }
            },
            children:[
              {
                path: 'recurring-transaction-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Recurring Transaction Report',
                    selected: false,
                    expanded: false
                  }
                }
              },
              {
                path: 'recurring-transaction-analysis-report',
                menuType: [0],
                data: {
                  menu: {
                    title: 'Recurring Transaction Analysis Report',
                    selected: false,
                    expanded: false
                  }
                }
              },
            ]
          },
          {
            path: 'utilities',
            menuType: [0],
            data: {
              menu: {
                title: 'Utilities',
                selected: false,
                expanded: false
              }
            },
            children: [
                  {
                    path: 'itemwarrantyinfo',
                    menuType: [0],
                    data: {
                      menu: {
                        title: 'Item Warranty Information'
                      }
                    }
                  },
                  {
                    path:'customertransactionhistory',
                    menuType:[0],
                    data:{
                      menu:{
                        title: 'Customer Transaction History'
                      }
                    }
                  },
                  {
                    path:'warrantyinformation',
                    menuType:[0],
                    data:{
                      menu:{
                        title: 'Warranty Information Upload'
                      }
                    }
                  },
                  {
                    path:'changedivision',
                    menuType:[0],
                    data:{
                      menu:{
                        title: 'Change Division'
                      }
                    }
                  },                  
                  {
                    path:'changePhiscalYear',
                    menuType:[0],
                    data:{
                      menu:{
                        title: 'Change Phiscal Year'
                      }
                    }
                  },
            ]
          },
          {
            path: 'additionalReport',
            menuType: [0],
            data: {
              menu: {
                title: 'Additional Report',
                selected: false,
                expanded: false
              }
            },
            
          },
        ]
      },
      // {
      //   // path: "masters",
      //   path: 'account',
      //   menuType: [0],
      //   data: {
      //     menu: {
      //       title: 'Financial Account',
      //       icon: 'fa fa-usd'
      //     }
      //   }, children: [
      //     {
      //       path: 'test',
      //       data: {
      //         menu: {
      //           title: 'Test',
      //           icon: 'ion-android-people',
      //           selected: false,
      //           expanded: false,
      //           order: 1000
      //         }
      //       }
      //     }]
      // },

      
      {
        path: 'configuration',
        menuType: [0],
        data: {
          menu: {
            title: 'Configuration',
            icon: 'ion-gear-b',
            selected: false,
            expanded: false,
            order: 2
          }
        },
        children: [
          {
            path: 'userManager',
            menuType: [0],
            data: {
              menu: {
                title: 'User Manager',
                icon: 'ion-android-people',
                selected: false,
                expanded: false,
                order: 90
              }
            },
            children: [
              {
                path: 'userlist',
                menuType: [0],
                data: {
                  menu: {
                    title: 'User List'
                  }
                }
              }
            ]
          },
          {
            path: "master-migration",
            menuType: [0],
            data: {
              menu: {
                title: "Master Migration"
              }
            }
          },
         
       
        ]
      }

      // {
      //   path: "help",
      //   data: {
      //     menu: {
      //       title: "Help",
      //       icon: "ion-android-home",
      //       url: "./../../../assets/help.pdf",
      //       target: "_blank",
      //       selected: false
      //     }
      //   }
      // }



      // {
      //   path: "configure-printer",
      //   data: {
      //     menu: {
      //       title: "Configure Printer",
      //       icon: "ion-android-print",
      //       url: "http://www.imsnepal.com:1060/PrintSetup.exe",
      //       target: "_blank",
      //       selected: false
      //     }
      //   }
      // }

    ]
  }
]



export interface MENU {
  expanded?: boolean;
  icon?: string;
  order?: number;
  selected?: boolean;
  target?: string;
  title?: string;
  children: CHILDREN[];
}


export interface CHILDREN {
  expanded?: boolean;
  icon?: string;
  order?: number;
  selected?: boolean;
  target?: string;
  title?: string;
  pathMatch?: string;
  route?: Router[];
}



export interface Router {
  children?: RouterData[];
  data?: any;
  menuType?: string[];
  path?: string;
  paths?: string[];
}


export interface RouterData {
  data?: any;
  menuType?: string[];
  path?: string;
  paths?: string[];
}
