<style>
    td {
        border: 1px solid #e6e6e6;
        padding: 1px !important;
    }
    
    input {
        padding: 8px;
    }
    
    .form-group {
        margin-bottom: 0rem;
    }
    
    .purchaseorder_container {
        position: fixed !important;
        transform: translate(0px, -35px);
        background: #ffffff;
        z-index: 99999;
        width: 90%;
        top: 22.5%;
        left: 5%;
    }
    
    .label-class {
        width: 135px;
    }
    
    .btn {
        margin-top: 60px;
    }

    .number-field{
        text-align: right;
    }
</style>


<div *ngIf="isActive" class="purchaseorder_container" role="document" style="max-width: 100%; max-height: 500px; overflow:hidden">
    <div class="modal-content" style="padding: 14px 16px 10px 16px;margin: 0;width: 90vw; height:70vh;">

        <div class="modal-header row">
            <div class="col-md-7">
                <h4 class="modal-title pull-right">{{masterService.Popup_Heading}}</h4>
            </div>
        </div>

        <div class="table-responsive">
            <div class="row">
                <div class="col-md-4">
                    <div class="row">
                        <div class="col">
                            <label>Order No. :</label>
                            <label class="label-class">{{masterService.PO_Voucherno}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>Order Date :</label>
                            <label class="label-class">{{masterService.PO_Date}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div>
                            <label>Supplier Name :</label>
                            <label style="width: 200px !important;">{{masterService.SupplierName}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col">
                            <input type="checkbox" [(ngModel)]="sameQty" (change)="QtySameAsOrderQty(sameQty)">
                            <label class="label-class">Qty Same as Order Qty</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <input type="checkbox" [(ngModel)]="sameRate" (change)="QtySameAsOrderRate(sameRate)">
                            <label class="label-class">Rate Same as Order Rate</label>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table columformat-class" style="display: grid;font-size: 12px;">
                <thead style="background-color: rgb(184, 184, 184);">
                    <tr>
                        <th style="width: 80px; " [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'45px'}: {'width':'0px'}">SI NO </th>
                        <th scope="col" style="width: 100px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'60px'}: {'width':'100px'}">Item Code</th>
                        <th scope="col" style="width: 150px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'100px'}: {'width':'150px'}">Item Name</th>
                        <th scope="col" style="width: 65px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'65px'}: {'width':'65px'}">Unit</th>
                        <th scope="col" style="width: 120px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'80px'}: {'width':'0px'}">Order Rate</th>
                        <th scope="col" style="width: 90px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'55px'}: {'width':'0px'}">Order Qty</th>
                        <th scope="col" style="width: 90px;text-align: center; " [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'60px'}: {'width':'0px'}">Deliver Qty</th>
                        <th scope="col" style="width: 118px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'70px'}: {'width':'118px'}">Rate</th>
                        <th scope="col" *ngIf="masterService.is_PurchaseOrder" style="width: 118px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'80px'}: {'width':'118px'}">Dis%</th>
                        <th scope="col" *ngIf="masterService.is_PurchaseOrder"  style="width: 118px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'80px'}: {'width':'118px'}">Dis Amt</th>
                        <th scope="col" *ngIf="masterService.is_PurchaseOrder"  style="width: 118px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'80px'}: {'width':'118px'}">Net Rate</th>
                        <th scope="col" style="width: 180px; text-align: center;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'80px'}: {'width':'180px'}">Amount</th>
                        <th scope="col" style="width: 150px; text-align: center;">Mfg Date</th>
                        <th scope="col" style="width: 150px; text-align: center;">Exp Date</th>
                    </tr>
                </thead>
                <tbody style="font-size:17px;height: 190px;">
                    <tr *ngFor=" let column of _trnMainService.TrnMainObj.PODetailList; let i= index ;">
                        <td style="width: 60px; " class="columformat-class">
                            <label style="font-size: 12px;width: 10px;">{{i+1}}</label>
                        </td>
                        <td style="width: 120px;line-height: 32px; " class="columformat-class">
                            <input type="text" style="height:26px;margin-top: 2px;font-size: 12px;" class="form-control" [(ngModel)]="column.MENUCODE"></td>
                        <td style="width: 150px;line-height: 32px; " class="columformat-class">
                            <input type="text" class="form-control" [(ngModel)]="column.DESCA" style="color: black;height:26px;margin-top: 2px;font-size: 12px"></td>
                        <td style="width: 85px;line-height: 32px; " class="columformat-class">
                            <input type="text" class="form-control" style="height:26px;margin-top: 2px;font-size: 12px" [(ngModel)]="column.UNIT"></td>
                        <td style="width: 110px;line-height: 32px; " class="columformat-class">
                            <input type="text" style="height:26px;margin-top: 2px;font-size: 12px" class="form-control number-field" [(ngModel)]="column.RATE"></td>
                        <td style="width: 90px;line-height: 32px;font-size: 12px " class="columformat-class">
                            <input type="number" class="form-control" style="height:26px;margin-top: 2px;font-size: 12px;" [(ngModel)]="column.ORDERQTY">
                        </td>
                        <td style="width: 90px;line-height: 32px; " class="columformat-class">
                            <input type="number" class="form-control" style="height:26px;margin-top: 2px;font-size: 12px" [(ngModel)]="column.DELIVERQTY" (change)="ValidateDeliverQty(i)" (keyup.Tab)="ValidateDeliverQty(i)" (keyup.Enter)="ValidateDeliverQty(i)">
                        </td>
                        <td style="width: 120px;line-height: 32px; " class="columformat-class">
                            <input type="number" class="form-control number-field" style="height:26px;margin-top: 2px;font-size: 12px" [(ngModel)]="column.DELIVERRATE" (change)="ValidateDeliverRate(i)" (keyup.Tab)="ValidateDeliverRate(i)" (keyup.Enter)="ValidateDeliverRate(i)"></td>
                        <td *ngIf="masterService.is_PurchaseOrder" style="width: 120px;line-height: 32px; " class="columformat-class">
                            <input type="number" class="form-control number-field" style="height:26px;margin-top: 2px;font-size: 12px" [(ngModel)]="column.DISCOUNT_RATE" (change)="DiscountPercentChange(i)" (keyup.Tab)="DiscountPercentChange(i)" (keyup.Enter)="DiscountPercentChange(i)"></td>
                        <td *ngIf="masterService.is_PurchaseOrder" style="width: 120px;line-height: 32px; " class="columformat-class">
                            <input type="number" class="form-control number-field" style="height:26px;margin-top: 2px;font-size: 12px" [(ngModel)]="column.DISCOUNT_AMOUNT"></td>
                        <td *ngIf="masterService.is_PurchaseOrder" style="width: 120px;line-height: 32px; " class="columformat-class">
                            <input type="number" class="form-control number-field" style="height:26px;margin-top: 2px;font-size: 12px" [(ngModel)]="column.NET_RATE"></td>
                        <td style="width: 180px;line-height: 32px; " class="columformat-class">
                            <input type="number" class="form-control number-field" style="height:26px;margin-top: 2px;font-size: 12px" [(ngModel)]="column.AMOUNT" [readonly]="true"></td>
                        <td style="line-height: 32px; " class="columformat-class">
                            <input type="month" max="2999-12-31" class="form-control" style="height:26px;margin-top: 2px;font-size: 12px;width: 165px;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'150px'}: {'width':'165px'}" [(ngModel)]="column.MFGDATE"> </td>
                        <td style="line-height: 32px; " class="columformat-class">
                            <input type="month" max="2999-12-31" class="form-control" style="height:26px;margin-top: 2px;font-size: 12px;width: 165px;" [ngStyle]="masterService.is_PurchaseOrder==true? {'width':'150px'}: {'width':'165px'}" [(ngModel)]="column.EXPIRYDATE"> </td>
                    </tr>

                </tbody>
            </table>

        </div>

        <div class="modal-footer" style="height: 20px;">
            <button type="button" class="btn btn-primary" (click)="OkCommand()">Ok</button>

            <button type="button" class="btn btn-primary" (click)="hide()">Cancel</button>
        </div>
    </div>
</div>