import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GenericPopUpComponent } from "./generic-popup-grid.component";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  declarations: [GenericPopUpComponent],
  exports: [GenericPopUpComponent]
})
export class GenericPopupGridModule {
  static forRoot(): ModuleWithProviders<GenericPopupGridModule> {
    return {
      ngModule: GenericPopupGridModule    };
  }
}
