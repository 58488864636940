<style>
    .btn-auth{
        color: #fff;
        background: #323B7C;
       border-radius: 3px;
        border:0.5px solid;
        width: 100%;
        padding: 5px 0;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .row{
        margin-left: -15px !important;
        margin-left: -15px !important;
    }
    input{
        height: 35px;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;


    }
    input:focus-visible{
        outline: none;
    }
    label{
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1rem;
        color: #000;
        font-weight: 400;
    }
    .ims-logo{
        position: absolute;
        right: -60px;
        
    }
      .ims-logo img{
        width: 60%;

      }
      .btn-info {
        margin: 0 !important;
      }
    .loginImage{
    /* changed here for minified background image  by roshan */
    /* background-image: url('assets/login.jpg'); */
    background-image: url('assets/login_page.jpg');
     /* background-size: 100vw,80vw; */
     
     background-size: contain;
     background-repeat: no-repeat;
   }
   .form-control:focus{
       border-color: aliceblue !important;
       box-shadow: aliceblue !important;
   }
   .login-form{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    padding-top: 40px;
    padding-bottom: 60px;

   }
      .login-form .form-group {
    justify-content: center;
   }
      .username{
        position: relative;
      }
   .username::before{
    content: "";
     background: url('assets/user.png') no-repeat ;
     position: absolute;
     top: 0;
     left: 0;


   }
   

</style>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

<div class="row" style="height: 100vh;"class="loginImage">
    <div class="ims-logo">
        <img src='assets/imslogo.png'alt="">
    </div>
    <div class="login-form" style="
    width: 28vw;
    position: absolute;
    left: 50vw;
    top: 27vh;
">
    <div class="row" style="color: #000; margin-bottom:40px;">
        <h2 style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; width: 100%; text-align: center; color:#323B7C; font-weight: 700; text-transform: uppercase;">Login</h2>
    </div>
        <div class="form-group row">
            <!-- <label class="col-sm-2" for="username">Username</label> -->
            <input autocomplete="false" type="text" formControlName="username" class="username"style="width: 70%;"  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Username"/>
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
        </div>
        <div class="form-group row">
            <!-- <label class="col-sm-2" for="password">Password</label> -->
            <input autocomplete="false" type="password" formControlName="password" style="width: 70%;" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
        </div>
        <div class="form-inline" style="width: 20%;margin: 0 auto; justify-content: center; margin-top: 20px;">
            <button  [disabled]="loading" class="btn btn-default btn-info btn-auth
            ">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Sign In
            </button>
        </div>   
    </div>
</div>
</form>



<!-- <div class="col-md-6 offset-md-3 mt-5">
    <div class="card" style="    background: #6C6E6E;
    color: white;min-height: 300px;">
        <h4 class="card-header mx-auto" style="border: none;background: none;">Login</h4>
        <div class="card-body pt-1">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-inline ml-5">
                    <label class="col-sm-2 ml-5" for="username">Username</label>
                    <input autocomplete="false" type="text" formControlName="username" class="form-control col-md-6" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-inline ml-5">
                    <label class="col-sm-2 ml-5" for="password">Password</label>
                    <input autocomplete="false" type="password" formControlName="password" class="form-control col-md-6" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <div class="form-inline mx-auto" style="width: 190px;">
                    <button [disabled]="loading" class="btn btn-default btn-info btn-auth
                    ">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Sign In
                    </button>
                </div>
            </form>
        </div>
    </div>
</div> -->