<style>
   .selected-row {
    background-color: #ced6d3 !important;
  }
  .modal-sticky{
    right: 55px;
  }

  .unitLabel{
    width: auto;
    font-size: 14px;
    margin-left: 5px;
  }

</style>

<div class="modal-sticky" style="width: 50vw;">

  <div class="modal-content" style="padding: 2px !important;
  box-shadow: -10px 0px 10px 1px #aaaaaa;
  width: 101%;
  margin-left: 1pc;
  margin-top: 5pc;
  min-height: 350px;
  max-height: 350px;
  overflow: scroll;
  overflow-x: hidden;">
    <div class="modal-header" style="padding: 0px;    background: #7ed0ff;">

      <h5 class="modal-title">{{title}}</h5>
      <button class="close" aria-label="Close" (click)="itemBatchClosed()">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>
    <div *ngIf="voucherType==3 || voucherType==24">
      <div class="col-md-12" style="padding: 1px;">
        New Batch :
      </div>
      <div class="col-md-12">
        <table style="padding: 0px;margin-top: 2px;width: 100%;" class="Table1">
          <tr style="background: #7ed0ff;">
            <th>Batch No.</th>
            <th>MFG</th>
            <th>Expiry</th>
            <th>Unit</th>
            <th>Cost Price</th>
            <th>Sell Price</th>
            <th></th>
          </tr>


          <tr style="border: 1px solid #e6e6e6;">

            <td class="CellWithComment" #BatchEntry>
              <input type="text" [(ngModel)]="BatchObj.BCODE" style="height:23px;border: 1px solid #e6e6e6;width:100px">
            </td>
            <td class="CellWithComment">
              <input type="date" [(ngModel)]="BatchObj.MFGDATE"
                style="height:33px;border: 1px solid #e6e6e6;width:130px">
            </td>
            <td class="CellWithComment">
              <input type="date" [(ngModel)]="BatchObj.EXPIRY"
                style="height:33px;border: 1px solid #e6e6e6;width:130px">
            </td>

            <td class="CellWithComment">
              <select (change)="SelectUnit()" [(ngModel)]="BatchObj.ALTUNITObj"
                style="height:33px;border: 1px solid #e6e6e6;width:80px">
                <option *ngFor="let u of AlternateUnits" [ngValue]="u">{{u.ALTUNIT}}</option>

              </select>
            </td>
            <td class="CellWithComment" style="height:33px;border: 1px solid #e6e6e6;width:100px">
              {{BatchObj.PRATE | twoDigitNumber}}
            </td>
            <td class="CellWithComment" style="height:33px;border: 1px solid #e6e6e6;width:100px">
              {{BatchObj.SRATE | twoDigitNumber}}
            </td>
            <td>
              <button (click)="clickButtonClick()" class="glyphicon glyphicon-chevron-right"></button>
            </td>


          </tr>
          <tr>
            <td>
              <div class="col-md-12" style="padding: 1px;margin-top:15px">
                Existing Batch :
              </div>
            </td>
          </tr>
          <tr>

            <td colspan="6">

            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-md-12" style="padding: 0px;">

      <input #inputBox type="text" id="batchFilterPopup"
        style="width: 100%;/* padding: 1px; */padding-left: 5px;" [(ngModel)]="filter">
    </div>
    <table style="padding: 0px;margin-top: 2px;width: 100%;overflow: scroll" class="Table1">
      <tr style="background: #7ed0ff;">
        <!-- <th>Batch No.</th> -->
        <th>Sn.</th>
        <ng-container *ngFor="let head of headerKeys;let i = index">
          <th>{{head}}</th>
        </ng-container>
        <th  *ngIf="needtoShowBatchColumn()" >BATCH</th>
        <th  *ngIf="voucherType != 14 && voucherType != 1"  style="text-align: right;">P-Rate</th>
        <th  *ngIf="(voucherType == 14 && activeurlpath != 'self-billing') || voucherType == 1"  style="text-align: right;">S-Rate</th>
        <th  *ngIf="voucherType == 14 && activeurlpath == 'self-billing'"  style="text-align: right;">Cost Rate</th>

        <th *ngIf="userSetting.ENABLEDEFAULTEXPIRYDATEENTRY == 0 && BList[0]?.REQEXPDATE ===1" style="text-align: center">EXP DATE</th>
        <th *ngIf="userSetting.ENABLEDEFAULTEXPIRYDATEENTRY == 0 && BList[0]?.REQEXPDATE ===1" style="text-align: center">MFG DATE</th>
        <th style="text-align: center;">Stock</th>
        <!-- <th>Cost Price</th> -->
        <!-- <th>Holding Stock</th> -->
        <th>Unit</th>
        <th *ngIf = "activeurlpath != 'self-billing'" style="text-align: right;">MRP</th>
      </tr>
      <tbody>
        <tr aria-disabled="true" *ngFor="let BObject of BList | filter:filter;let i=index" (click)="RowClickEvent(i)"
          (dblclick)="dblClickBatchObj(BObject)" [class.selected-row]="i === selectedRowIndex" class="highlight">
          <td style="border-bottom: 1px solid #ddd; height: 23px;width: 5% ;">{{i+1}}</td>

          <!-- <td style="padding-top:2px;border-bottom: 1px solid #ddd;height: 23px;width:150px">
            {{BObject.BATCH}}</td> --> 
            <ng-container *ngFor="let key of BObject.VariantValues;let j=index">
              <td style="border-bottom: 1px solid #ddd; height:23px;width:10%">
                {{key}}
              </td>
            </ng-container>
            <td *ngIf="needtoShowBatchColumn()" style="border-bottom: 1px solid #ddd;height: 23px;width:21%">{{BObject.BATCH}}</td>
            <td *ngIf="voucherType != 14 && voucherType != 1 " style="border-bottom: 1px solid #ddd;height: 23px;width:12%;text-align: right;">{{BObject.RATE| number:'1.2-2'}}</td>
            <td *ngIf="voucherType == 14 ||  voucherType == 1 " style="border-bottom: 1px solid #ddd;height: 23px;width:12%;text-align: right;">{{BObject.RATE| number:'1.2-2'}}</td>

          <td style="border-bottom: 1px solid #ddd;height: 23px;width:18%;text-align: center;" *ngIf="userSetting.ENABLEDEFAULTEXPIRYDATEENTRY  == 0 && BList[0]?.REQEXPDATE ===1">{{BObject.EXPDATE | date}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:18%;text-align: center" *ngIf="userSetting.ENABLEDEFAULTEXPIRYDATEENTRY == 0 && BList[0]?.REQEXPDATE ===1">{{BObject.MFGDATE |date}}</td>
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:10%; text-align: center;">{{BObject.STOCK}} </td>
          <!-- <td style="padding-top:2px;border-bottom: 1px solid #ddd;height: 23px;width:100px">
            {{BObject.PRATE  |twoDigitNumber}}</td> -->
          <!-- <td style="border-bottom: 1px solid #ddd;height: 23px;width:50px">{{BObject.HOLDINGSTOCK}}</td> -->
          <td style="border-bottom: 1px solid #ddd;height: 23px;width:9%"> {{BObject.BASEUNIT}} </td>

          <td  *ngIf = "activeurlpath != 'self-billing'" style="border-bottom: 1px solid #ddd;height: 23px;width:13%;text-align: right;">{{BObject.MRP| number:'1.2-2'}}</td>
        </tr>
      </tbody>

    </table>
  </div>
</div>