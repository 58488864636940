import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { TransactionService } from '../../Transaction Components/transaction.service';
import { MasterRepo } from '@app/_services/masterRepo.service';

@Component(
    {
        selector: 'PItemGrpList',
        templateUrl: './PopItemGrp.html',

    }
)
export class PopItemGrpComponent {
    /** Input/Output/Viewchild  */
    //@Input('voucherType') voucherType:VoucherTypeEnum;
    @Input() title: string;
    @Input() tabindex;
    @Input() inputSearch: string;
    @Output() dbClickEvent = new EventEmitter();
    @Output() BatchClosedClick = new EventEmitter();
    @Output() searchItem = new EventEmitter();
    @Output() returnBatchValue = new EventEmitter();
    @ViewChild("BatchEntry") _BE: ElementRef;
    @ViewChild("SearchBox") _Search: ElementRef;

    /** Object Declaration  */
    selectPopupRowList: Function;
    selectedRow: Number;
    selectedRowIndex: Number;
    isFocus: boolean = true;

    /** List Declaration  */
    p: number[] = [];
    setClickedRow: Function;
    BatchObj: any = <any>{};
    BatchList: any[] = [];
    @Input() BList: any[] = [];
    unitList: any[] = [];
    @Input() voucherType: any;
    @Input() activerowIndex: number;
    @Input() AlternateUnits: any[] = [];
    constructor(public masterService: MasterRepo, public _trnMainService: TransactionService) {
        // this.masterService.getAllUnitList().subscribe(data => {
        //     this.unitList.push(data);
        // })
        this.setClickedRow = function (index) {
            this.selectedRow = index;
        }

    }
    ngAfterViewInit() {
        if (this.voucherType == 3) {

        }
    }
    ngOnInit() {

    }
    SelectUnit() {
        this.BatchObj.UNIT = this.BatchObj.ALTUNITObj.ALTUNIT;
        this.BatchObj.PRATE = this.BatchObj.ALTUNITObj.PRATE;
        this.BatchObj.SRATE = this.BatchObj.ALTUNITObj.RATE;

    }
    pagingChange(value) {
        this.p[this.tabindex] = value;
    }
    setFocus() {
        // this._BE.nativeElement.focus();
    }
    SearchList(value) {
        // this.itemChanged(value);

    }



    PopupItemListRowClick(index) {
        this.selectedRowIndex = index;
    }

    itemBatchClosed() {
        this.BatchClosedClick.emit();
    }
    keydownClick() {

    }
    AddNewBatch() {
        //  this.BatchList=this.BList;
        setTimeout(() => {
            // this._BE.nativeElement.focus();
        }, 50)

    }

    tabClick() {
        this.returnBatchValue.emit(this.BatchObj);
    }
    clickPlusButton() {
        this.tabClick()
    }
    clickButtonClick() {
        if (this.BatchObj.BCODE == null) {
            alert("Please enter Batch no.");
            // this.childModal.show();
            // this.DialogMessage="Please enter Batch no.";
            // setTimeout(()=>{
            //     this.childModal.hide();
            // },500)

            return;
        }
        else if (this.BatchObj.MFGDATE > this.BatchObj.EXPDATE) {
            alert("Manufacture date cannot be greater than Expiry date!!")
            // this.childModal.show();
            // this.DialogMessage="Manufacture date cannot be greater than Expiry date!";
            // setTimeout(()=>{
            //     this.childModal.hide();
            // },1500)
            return;
        }
        else if (this.BatchObj.SRATE > this.BatchObj.MRP) {
            // this.childModal.show();
            // this.DialogMessage="SP cannot be greater than MRP!";
            // setTimeout(()=>{
            //     this.childModal.hide();
            // },800)
            // alert("SP cannot be greater than MRP!")
            // return;
        }
        else if (this.BatchObj.MFGDATE == null || this.BatchObj.EXPDATE == null) {
            alert("MFGDate and ExpDate cannot be empty!")
            // this.childModal.show();
            // this.DialogMessage="MFGDate and ExpDate cannot be empty!";
            // setTimeout(()=>{
            //     this.childModal.hide();
            // },800)
            return;
        }
        this.tabClick()


    }
    dblClickBatchObj(value) {
        this.dbClickEvent.emit(value)

    }
    getCatList() {

    }

}