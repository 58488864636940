import { Component, OnInit, HostListener, ElementRef, ViewChild, Input} from '@angular/core';
import { MasterRepo } from '@app/_services/masterRepo.service';
import { TransactionService } from '@app/common/Transaction Components/transaction.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
// import { importDetails, importDetailsTotal } from '@app/common/interfaces/importDetails.interface';
import { IMPORT_DETAILS, IMPORT_DETAILS_PROD, TrnProd } from '@app/common/interfaces/TrnMain';
import { AuthService } from '@app/_helperServices/authService.service';
import { GenericPopUpComponent, GenericPopUpSettings } from '../generic-grid/generic-popup-grid.component';

@Component({
  selector: 'import-purchase-details',
  templateUrl: './import-purchase-details.component.html',
  styleUrls: ['./import-purchase-details.component.css'],
})
export class ImportPurchaseDetails implements OnInit {
  isActive:boolean;
  // @ViewChild ('DocumentNo') DocumentNo:ElementRef;
  @Input() tranMode: string;
  productList: any = [];
  importrowindex: number = 0;
  userSetting:any;
  
  @ViewChild("genericGridCreditAccount") genericGridCreditAccount: GenericPopUpComponent;
  genericGridCreditAccountSetting: GenericPopUpSettings = new GenericPopUpSettings();
  
  constructor(
    public masterservice: MasterRepo,
    public _trnMainService: TransactionService, 
    public spinnerService:SpinnerService,
    public alertservice: AlertService,
    public authservice:AuthService,
  ) {
    this.userSetting = this.authservice.getSetting();
    if (_trnMainService.TrnMainObj.IMPORTDETAILS == null) {
        this._trnMainService.TrnMainObj.IMPORTDETAILS = <any>{};
      }
  }

  ngOnInit() {
    // console.log("MODE IMPOR DETAILS",this.tranMode);
   }

  onclear() {
    this._trnMainService.TrnMainObj.IMPORTDETAILS = <any>{};
    this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList = [];
    this.masterservice.allowAddNextRow = true;
    this.addRow();
}

  closeDialog() {
    this._trnMainService.TrnMainObj.IMPORTDETAILS = <any>{};
    this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList = [];
    this.isActive=false;
    // this.importDetailsArray = [];
  }

  show() {
    this.isActive = true;
    // setTimeout(() => {
    //   this.DocumentNo.nativeElement.focus();
    // }, 10);
    setTimeout(()=>{
      document.getElementById("documentno").focus();
    },500);
   
    if((!this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList) || (!this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.length) || (this._trnMainService.TrnMainObj.IMPORTDETAILS == null)){
      this._trnMainService.TrnMainObj.IMPORTDETAILS = <IMPORT_DETAILS>{};
      this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList = [];
       this.addRow();

    }

    this.productList = this._trnMainService.TrnMainObj.ProdList.filter(x=> x.MCODE !=  null && x.MENUCODE != null);
    console.log("product list", this.productList);
}

hide() {
    this.isActive = false;
}

ok(){
  console.log("IMPORTDETAILS",this._trnMainService.TrnMainObj.IMPORTDETAILS)
  if(this._trnMainService.TrnMainObj.Mode == 'NEW' || this._trnMainService.TrnMainObj.Mode == 'EDIT'){
    if(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.length>0 && this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[0].ITEMNAME){
      if(this._trnMainService.TrnMainObj.IMPORTDETAILS.DOCUMENTNO ===''|| this._trnMainService.TrnMainObj.IMPORTDETAILS.DOCUMENTNO===null || 
      this._trnMainService.TrnMainObj.IMPORTDETAILS.DOCUMENTNO===undefined){
       this.alertservice.info("Please enter import document no.");
       return;
      }
 
      if(this._trnMainService.TrnMainObj.IMPORTDETAILS.TRNAC ===''|| this._trnMainService.TrnMainObj.IMPORTDETAILS.TRNAC===null || 
      this._trnMainService.TrnMainObj.IMPORTDETAILS.TRNAC===undefined){
       this.alertservice.info("Please select credit a/c");
       return;
      }
    }
  }
    this.isActive  = false;
}

  @HostListener("document : keydown", ["$event"])
  handleKeyDownboardEvent($event: KeyboardEvent) {
    if ($event.code == "Escape") {
      $event.preventDefault();
        this.closeDialog();
    }    
    }

    addRow(){
        try{
          console.log("add row");
          var newRow = <IMPORT_DETAILS_PROD>{};
        //   let item = this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.filter( x=> x.ITEMNAME == "" || x.ITEMNAME == null || x.ITEMNAME == undefined)
        //  if(item.length > 0) return;
          this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.push(newRow);
        }catch(ex){
          console.log(ex);
          alert(ex);
        }
    }

    rowClick(index){
      this.importrowindex = index;
    }

    focusToQty(){
      let Index = this.importrowindex;  
      setTimeout(() => {
        document.getElementById('importQty'+ Index).focus();
        }, 200);
      var result : any[] = this.masterservice.groupBy(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList, function (x) {
        return [x.ITEMNAME];
      });

      for(const item of result){
        if(item.length> 1){
          this.alertservice.warning("Cannot enter same item");
          return;
        }
        
      }
    }
    
    nextRow(index){
      if (this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].ITEMNAME == "" || this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].ITEMNAME == null || this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].ITEMNAME == undefined) {
        this.alertservice.error("Item Description is missing");
        return;
  
      }
      if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].QUANTITY) == 0) {
        this.alertservice.error("Quantity is missing");
        return;
  
      }
      if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].NONTAXABLE) == 0) {
        if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].TAXABLE) == 0) {
          this.alertservice.error("Taxable amount is missing");
          return;
  
        }
        if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].VAT) == 0) {
          this.alertservice.error("Vat amount is missing");
          return;
  
        }
  
      }
      if (this.masterservice.nullToZeroConverter(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList[index].NETAMOUNT) == 0) {
        this.alertservice.error("Net Amount is missing");
        return;
  
      }

      this.RecalculateImportDetails();

      let nextindex = this.importrowindex+1; 
      if(this.masterservice.allowAddNextRow == true){
        console.log("allow add next row is false", this.masterservice.allowAddNextRow);
        this.addRow();
        setTimeout(() => {
        document.getElementById('importItemname'+nextindex).focus();
        }, 200);
      }
      if(this.masterservice.allowAddNextRow == false){
        console.log("allow add next row is false", this.masterservice.allowAddNextRow);
        setTimeout(() => {
        document.getElementById('importItemname'+nextindex).focus();
        }, 200);
      }
    
    }

    enterName(i){
      setTimeout(() => {
        document.getElementById("importQty"+i).focus();
      }, 200);
    }


    RecalculateImportDetails(){
      try{
        if (this._trnMainService.TrnMainObj.IMPORTDETAILS && this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.length) {
    
          
          this._trnMainService.calculateImportProdTotalValue();
        }

      } catch(error){
        this.alertservice.error('Error in import details calculation: '+ error);
      }

    }

    initializeTotalCalcProperty(){

    }





    onNonTaxableChange() {
      this.RecalculateImportDetails();
    }
    onTaxableChange() {
      this.RecalculateImportDetails();
    }
    onVatChange() {
      this.RecalculateImportDetails();
    }
    onQuantityChange() {
      this.RecalculateImportDetails();
    }

    deleteRow(index){
      if(confirm("Are you sure you want to delete this row ?")){
        this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.splice(index,1);
        if(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.length === 0){
          this.addRow();
        }
      }
    }

    loadImportDetails(){
      this.masterservice.allowAddNextRow = false;
      this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList = [];
      this.initializeTotalCalcProperty();  
      for (const iterator of this._trnMainService.TrnMainObj.ProdList) {
        let importProdObj = <IMPORT_DETAILS_PROD>{};
        importProdObj.ITEMNAME = iterator.ITEMDESC;
        importProdObj.QUANTITY = iterator.Quantity;
        importProdObj.NETAMOUNT = iterator.Quantity * iterator.RATE;
        importProdObj.VAT = iterator.ISVAT ==1? ((iterator.AMOUNT - iterator.DISCOUNT)*this.userSetting.VATRate ):0;
        importProdObj.TAXABLE =0;
        importProdObj.NONTAXABLE =0;
        if(iterator.ISVAT== 1){  importProdObj.TAXABLE = (iterator.AMOUNT - iterator.DISCOUNT) }
        if(iterator.ISVAT ==0){  importProdObj.NONTAXABLE = (iterator.AMOUNT - iterator.DISCOUNT) }
                
        this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList.push(importProdObj);

        this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList = this.removeInvalidRowFromImportDetils(this._trnMainService.TrnMainObj.IMPORTDETAILS.prodList);
        
        this._trnMainService.calculateImportProdTotalValue();
      
      }

    }

    removeInvalidRowFromImportDetils(importDetailsList:IMPORT_DETAILS_PROD[]): IMPORT_DETAILS_PROD[] {
      return importDetailsList = importDetailsList.filter(x=> x.ITEMNAME !=  null && x.ITEMNAME != null);
    }


    saveImportDetails(){
 
      let bodyData = {
        VCHRNO: this._trnMainService.TrnMainObj.VCHRNO,
        SUPPLIER: this._trnMainService.TrnMainObj.PARAC,
        DOCUMENTNO: this._trnMainService.TrnMainObj.IMPORTDETAILS.DOCUMENTNO,
        LCNUM: this._trnMainService.TrnMainObj.IMPORTDETAILS.LCNUM,
        NETAMOUNT: this._trnMainService.TrnMainObj.IMPORTDETAILS.NETAMOUNT,
        TRNAC: this._trnMainService.TrnMainObj.IMPORTDETAILS.TRNAC
        // NONTAXABLE: this._trnMainService.TrnMainObj.IMPORTDETAILS.NONTAXABLE,
        // TAXABLE: this._trnMainService.TrnMainObj.IMPORTDETAILS.TAXABLE,
        // VAT: this._trnMainService.TrnMainObj.IMPORTDETAILS.VAT
      }
      // this.spinnerService.show("Saving import details.....")
      // this.masterservice.saveImportDetail(bodyData).subscribe( res=>{
      //   if(res.status == "ok"){
      //     this.spinnerService.hide();
      //     this.alertservice.success("Import details saved Successfully !");
      //     setTimeout(()=>{
      //       this.hide();
      //     },1200);
      //   }else{
      //     this.spinnerService.hide();

      //     this.alertservice.error(res.result);
      //   }
       
      // }, error=>{
      //   this.spinnerService.hide();
      //   this.alertservice.error(error);
      // });
    }

    AccountEnterCommand(event){
      this.genericGridCreditAccountSetting = {
        title: "Accounts",
        apiEndpoints: `/getAccountPagedListByMapId/Master/AccountOpeningBalance`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: "ACCODE",
            title: "AC CODE",
            hidden: false,
            noSearch: false
          },
          {
            key: "ACNAME",
            title: "A/C NAME",
            hidden: false,
            noSearch: false
          }
        ]
      };
      this.genericGridCreditAccount.show();
  
    }

    onAccountSelect(ac) {
      this._trnMainService.TrnMainObj.IMPORTDETAILS.TRNAC=ac.ACID;
      this._trnMainService.TrnMainObj.IMPORTDETAILS.TRNACNAME=ac.ACNAME;
        }
  }

