import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { AuthService } from '@app/_helperServices/authService.service';
import { CacheService } from '@app/_helperServices/cacheService.service';
import { MasterRepo } from '@app/_services/masterRepo.service';
import moment from 'moment';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    @Output('signedIn') signedIn = new EventEmitter();
    userProfile: any = <any>{};


    constructor(

        public formBuilder: FormBuilder,
        public route: ActivatedRoute,
        public router: Router,
        public authenticationService: AuthenticationService,
        public authService: AuthService,
        public cacheService: CacheService
    ) {
        // redirect to home if already logged in
        //uncommented by roshan
        if (this.authenticationService.currentUserValue) {
            // this.router.navigate(['/pages/dashboard']);
            this.authenticationService.logout();
            console.log("cleaned")
        }
        // this.authenticationService.InitialLoad();
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        // // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/pages/dashboard';
    }
    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.code === "Enter") {
            this.onSubmit();
        }
    }


    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        try {
            // stop here if form is invalid
            if (this.loginForm.invalid) {
                return;
            }

            this.loading = true;
            this.authenticationService.login(this.f.username.value, this.f.password.value)
                .pipe(first())
                .subscribe(
                    data => {
                        this.signedIn.emit(true);
                        this.router.navigate(["/pages/dashboard"]);

                        let today_date = new Date().toJSON().split('T')[0];
                        console.log("@@today_Date", today_date);

                        this.userProfile = this.authService.getUserProfile();
                        var tomorrow = moment(this.userProfile.CompanyInfo.FEDATE).add(1, 'days');
                        var tomorrow_Date = tomorrow.format('YYYY-MM-DD')
                        console.log("tomorrow_Date", tomorrow_Date);

                        if (today_date == tomorrow_Date) {
                            if (this.userProfile.CompanyInfo.FBDATE.split('T')[0] != tomorrow_Date) {
                                this.authService.showfiscalPopup = true;
                            }
                        }
                    },
                    error => {
                        // alert("reached222")
                        this.error = error;
                        this.loading = false;
                    });
        }
        catch (ex) {
            console.log("CheckEr$", ex)
        }
        this.submitted = true;
    }
}
