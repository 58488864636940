import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpdescriptionFormComponent } from "./helpdescription-form.component";

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [HelpdescriptionFormComponent],
  exports: [HelpdescriptionFormComponent]
})
export class  HelpdescriptionFormModule {
  static forRoot(): ModuleWithProviders<HelpdescriptionFormModule> {
    return {
      ngModule: HelpdescriptionFormModule,
    };
  }
}
