import { Injectable } from '@angular/core';
import { IRateGroup, IDivision } from '@app/common/interfaces/commonInterface.interface';
import { AcListTree, TAcList } from '@app/common/interfaces/Account.interface';
import { TrnMain, TSubLedger, VoucherTypeEnum } from '@app/common/interfaces/TrnMain';
import { Item, Product, ProductGroup } from '@app/common/interfaces/ProductItem';
import { BehaviorSubject, Observable, Subject, Subscriber, of, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { AuthService } from '@app/_helperServices/authService.service';
import { GlobalState } from '@app/_helperServices/global.state';
import { SettingService } from '@app/_helperServices/setting.service';
import { SpinnerService } from '@app/common/shared/spinner/spinner.service';
import { AlertService } from '@app/common/shared/alert/alert.service';
import moment from 'moment';
import { Company } from '@app/common/interfaces/CompanyInfo.interface';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestOptions } from 'https';
import { stringify } from '@angular/compiler/src/util';
import { FormGroup } from '@angular/forms';







@Injectable({ providedIn: 'root' })
export class MasterRepo {
  sortProductGrp: any;
  mainGroupList:any = [];
  subGroupAList: any = [];
  subGroupBList: any = [] ;
  partyGroupList
  subGroupLevel: number;
  productImgURL: string = "";
  subGroupCList: any = [];
  disabledSubgroupA: boolean =true;
  disabledSubgroupB: boolean = true;
  disabledSubgroupC: boolean = true;
  disableOnCheckTran: boolean = false ;
  prevMgroup: string;
  TempTrnMainObj: TrnMain = <TrnMain>{};
  customerpopup: boolean =false;
  filter: string;
  filters: string;
  currentPage: number =1;
  port: any = {} as any;
  subPartyCList:any = [];
  disableSubPartyA: boolean = true;
  disableSubPartyB: boolean = true;
  disableSubPartyC: boolean = true;
  subPartyAList:any = [];
  subPartyBList:any = [];
  SelectedGroup: any;
  form: FormGroup;

  
  getAccountTreeObservable: Observable<AcListTree[]>;
  getPartyTreeObservable: any;
  AppMode: string;

  public get isInventryYearEnd(): number { return this.authService.getSessionVariable('isIYE'); }
  public get isAccountYearEnd(): number { return this.authService.getSessionVariable('isAYE'); }
  public get itemList(): Item[] {
    return this._itemList;
  }
  headerMode = "(NEW)";
  public get accountList(): TAcList[] {
    return this._account;
  }
  public appType = 2;
  isTreeNodeClicked = false;
  TreeID:string;
  SelectedProductPrarentID:string;
  isSubmitting = false;
  // constructor
  public ProductTreeObjectSubject = new Subject<{}>();
  public ObservableProductTree = this.ProductTreeObjectSubject.asObservable();
  public isGroup : boolean;
  public ProductMcode: string;
  isAddFormShown_1:boolean;
  modeIs: string;
  isMainGroup : boolean;
  date1: any;
  editMode: boolean;
  date2: any;
  reportName:string;
  productDetailsListHold :any[]=[];
  PO_Voucherno:any;
  PO_Date:any;
  SupplierName:any;
  productLists:any[]=[];
  productGroupLists:any[]=[];
  productSearchTotalCount =0;
  productGroupSearchTotalCount =0;
  Popup_Heading:any;
  pagesubject = new Subject<number>();
  updateBrandTreeSubject= new Subject<any>();
  ProdGroupSubject = new Subject<number>();
  is_PurchaseOrder:boolean=false;
  groupSelectObj: ProductGroup = <ProductGroup>{};
  ITEM_LEVEL:any = '';
  SelectedProductGrpID:string;
  public variantmaster = [];
  PhiscalObj: any = <any>{};
  showAll: boolean;
  allowAddNextRow: boolean;
  refreshForQRCode: boolean;
  
  
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public state: GlobalState,
    public setting: SettingService,
    public spinnerService: SpinnerService,
    public alertService: AlertService,
    public arouter: ActivatedRoute,
    public router: Router
    
  ) {
    this.isMainGroup = false;
    this.isAddFormShown_1 =false;
    this.isTreeNodeClicked = false;
    this.appType = 2;
    this.userProfile = authService.getUserProfile();
    this.userSetting = authService.getSetting();
    this.PhiscalObj = authService.getPhiscalInfo();
    console.log("phisobj",authService.getPhiscalInfo());
    // this.port = authService.getPort();
   // this.apiUrl = this.state.getGlobalSetting('apiUrl');
    this.apiUrl = environment.apiUrl;
    this.AppMode = environment.appMode;
    const FBDATE = (this.userProfile?.CompanyInfo.FBDATE)?.substr(0, 10);
    this.dateFilterRange = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Pre Fiscal Year': [moment(new Date(FBDATE)).set('date', 1).set('month', 3).subtract(1, 'year'), moment(new Date(FBDATE)).endOf('month').set('month', 2)],
      'Fiscal Year': [moment(new Date(FBDATE)).set('date', 1).set('month', 3), moment(new Date(FBDATE)).endOf('month').set('month', 2).add(1, 'year')],

    }

    this.date1 = new Date().toJSON().split('T')[0];
    this.date2 = this.userProfile?.CompanyInfo.FEDATE.split('T')[0];

    
      if(this.PhiscalObj){
        var y = this.PhiscalObj.BeginDate
        y = y.substring(0, 10);
        this.date2 = y;
        if (this.userProfile?.CompanyInfo.ActualFY == this.PhiscalObj.PhiscalID) {
          this.date1 = new Date().toJSON().split('T')[0];
        }
        else {
          var x = this.PhiscalObj.EndDate
          x = x.substring(0, 10);
          this.date1 = x;
        } 
      
      }

      this.AppMode = environment.appMode;
 


    
  }
  public UpdateProductTree(value){
    this.ProductTreeObjectSubject.next(value);
  }
  public _rateGroups: Array<IRateGroup> = [];
  public serverDate: Date = new Date();
  public _account: TAcList[] = [];
  public _subLedger: TSubLedger[] = [];
  public _itemList: Item[] = [];
  public PartialProductList: any[] = [];
  public ProductGroupTree: any[] = [];
  public _accountTree: any[] = [];
  public _accountPartyTree: any[] = [];
  public _ptypeList: any[] = [];
  public _Units: any[] = [];
  public _Chanels: any[] = [];
  public Currencies: any[] = [];
  public AllDivisions: any[] = [];
  apiUrl: any;
  public MCODEWISE_ITEMSTOCKLIST: any[] = [];
  public _divisionListSubject: BehaviorSubject<IDivision[]> = new BehaviorSubject<IDivision[]>([]);
  public divisionList$: Observable<IDivision[]> = this._divisionListSubject.asObservable();

  public accountSubject: BehaviorSubject<TAcList[]> = new BehaviorSubject<TAcList[]>([]);
  public accountList$: Observable<TAcList[]> = this.accountSubject.asObservable();
  public _divisions: Array<IDivision> = [];
  public _divisionListObservable: Observable<Array<IDivision>>;
  public rateGroups: Subject<Array<IRateGroup>> = new Subject();
  public Subscriptions: any[];
  public PartialAccountList: any[] = [];
  ShowMore = false;
  PType: string;
  partyPopUpHeaderText = '';
  PlistTitle = '';
  userProfile: any = {} as any;
  userSetting: any = {} as any;
  fiscalYearList: any[] = [];
  dateFilterRange: any;

  public IsLoginDialogOpened = false;
  isSalesPage: boolean;


  // CREATION MODE OF VOUCEHRS
  // if it is import from another voucher then _PREFIX is added to the  creation mode
  po_manual = 'MANUAL_PO';
  po_excelimport = 'EXCELIMPORT_PO';
  so_manual = 'MANUAL_SO';
  so_excelimport = 'EXCELIMPORT_SO';
  so_mobileimport = 'MOBILE_SO';
  pp_manual = 'MANUAL_PP';
  ti_manual = 'MANUAL_TI';

  getAccountObservable: Observable<TAcList[]>;

  ValidateNepaliDate(Engdate){
    // console.log('date1',this.date1);
    // console.log('date2',this.date2);
    // console.log('Engdate',Engdate);
    if(Engdate <= (this.date1) && Engdate >= this.date2){
      return true;

    }else{
      return false;
    }
  }
  public getRGroupList() {
    return this.http
      .get(this.apiUrl + '/getRGroupList')

  }

  public getBrandList() {
    return this.http
      .get(this.apiUrl + '/getBrandList')

  }

  public getAcList() {
    return this.http
      .get(this.apiUrl + '/getAcList')

  }


  public savePrintLog(vchrno: string, division: string, phiscalID: string, trnUser: string) {
    // let bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,TRNUSER:trnUser };
    return this.http
      .get(this.apiUrl + `/savePrintLog?VCHRNO=${vchrno}&DIVISION=${division}&PHISCALID=${phiscalID}&TRNUSER=${trnUser}`)
  }

  // Title for Tax Invoice
  public getPrintTitle(vchrno: string, division: string, phiscalID: string, trnUser: string) {
    return this.http
      .get(this.apiUrl + `/getPrintInvoiceTitle?VCHRNO=${vchrno}&DIVISION=${division}&PHISCALID=${phiscalID}&TRNUSER=${trnUser}`)
  }

  // Title ffor other print invoices except Tax Invoice
  public getPrintTitleAsCopy(vchrno: string, division: string, phiscalID: string, trnUser: string) {
    return this.http
      .get(this.apiUrl + `/getPrintInvoiceTitleAsCopy?VCHRNO=${vchrno}&DIVISION=${division}&PHISCALID=${phiscalID}&TRNUSER=${trnUser}`)
  }
  public getBillUnits(vchrno) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getBillUnits/?vchrno=${vchrno}`)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }

  getAllChalanSeries(vType: string) {
    return this.http.get(`${this.apiUrl}/getChalanSeriesByVoucherType?vType=${vType}`);
  }


  getAccountNameForMaster(ACID: string) {
    return this.http.get(`${this.apiUrl}/getMasterAccountName?ACID=${ACID}`);
  }





  public getAllCostCenter(refresh: boolean = false) {
    return this.http
      .get(this.apiUrl + '/getAllCostCenterList')
  }



  getDateMiti(fiscalid: string) {
    return this.http.get(`${this.apiUrl}/DateMiti?fiscalId=${fiscalid}`);
  }


  public getDivisions(refresh: boolean = false) {
    let dlist = [];
    let returnDiv = [];
    this.getAllDivisions().subscribe(res => {
      dlist.push(<any>res);
      if (this.setting.appSetting.UserwiseDivision === 1) {
        let divs = [];
        let userdivs = <string[]>this.setting.appSetting.userDivisionList;
        dlist.forEach(div => {
          let divitem = userdivs.find(d => d === div.INITIAL);
          if (divitem) {
            divs.push(div);
          }
        });
        returnDiv = divs;
      } else {
        returnDiv = dlist;
      }
    });
    return of(returnDiv);
  }

  public SaveApprovedStockTransfer(mode: string, trnmain: any) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { mode: mode, data: trnmain };
    this.http
      .post(this.apiUrl + '/saveApprovedStockTransfer', bodyData)
      .subscribe(
        retData => {
          if (retData['status'] === 'ok') {
            res.status = 'ok';
            res.result = retData['result'];
            returnSubject.next(res);
          } else {
            res.status = 'error1';
            res.result = retData['result'];
            returnSubject.next(res);
          }
        },
        error => {
          let er = error.json();
          returnSubject.next(er);
        }
      );
    return returnSubject;
  }
  updateCompanyValues(value2) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { BILLUNITS: value2 };
    this.http.post(`${this.apiUrl}/updateCompanyInfo`, bodyData)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }
  public saveDamageSalesReturnToTMP(mode: string, trnmain: any) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { mode: mode, data: trnmain };
    this.http
      .post(this.apiUrl + '/saveSalesReturnDamageToTMP', bodyData)
      .subscribe(
        retData => {
          if (retData['status'] === 'ok') {
            res.status = 'ok';
            res.result = retData['result'];
            returnSubject.next(res);
          } else {
            res.status = 'error1';
            res.result = retData['result'];
            returnSubject.next(res);
          }
        },
        error => {
          let er = error.json();
          returnSubject.next(er);
        }
      );
    return returnSubject;
  }


  public saveApprovalTmptoActual(mode: string, trnmain: any) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = { mode: mode, data: trnmain };
    this.http
      .post(this.apiUrl + '/saveApprovalTransaction', bodyData)
      .subscribe(
        retData => {
          if (retData['status'] === 'ok') {
            res.status = 'ok';
            res.result = retData['result'];
            returnSubject.next(res);
          } else {
            res.status = 'error1';
            res.result = retData['result'];
            returnSubject.next(res);
          }
        },
        error => {
          let er = error.json();
          returnSubject.next(er);
        }
      );
    return returnSubject;
  }



// public saveScheme(
//     mode: string,
//     Account: any[],
//     Flag,
//     data: any,
//     rangeList: any[]
//   ) {
//     let res = { status: "error", result: "" };
//     let returnSubject: Subject<any> = new Subject();
//     let opt = this.getRequestOption();
//     let hd: Headers = new Headers({ "Content-Type": "application/json" });
//    // let op: RequestOptions = new RequestOptions();
   
//     let bodyData = {
//       mode: mode,
//       data: data,
//       dataList: Account,
//       flag: Flag,
//       dataRange: rangeList
//     };
//     this.http
//       .post(this.apiUrl + "/schemeSave", bodyData, options)
//       .subscribe(
//         data => {
//           let retData = data.json();
//           if (retData.status == "ok") {
//             res.status = "ok";
//             res.result = retData.result;
//             returnSubject.next(res);
//             returnSubject.unsubscribe();
//           } else {
//             res.status = "error1";
//             res.result = retData.result;
//             returnSubject.next(res);
//             returnSubject.unsubscribe();
//           }
//         },
//         error => {
//           (res.status = "error2"), (res.result = error);
//           returnSubject.next(res);
//           returnSubject.unsubscribe();
//         }
//       );
//     return returnSubject;
//   }

  public getRouteListBasedUponSalesman(salesmanid) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getRouteBySalesMan?SALESMANID=${salesmanid}`)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }


  public getMCatList() {
    return this.http
      .get<any[]>(this.apiUrl + '/getMCatList')

  }

  public getTaxCategoryList(){
    return this.http
    .get<any[]>(this.apiUrl + '/getTaxCatList')
  }

  public getMemCatList(){
    return this.http
    .get<any[]>(this.apiUrl + '/getMemberSchemeList')

  }


  public getAllSchemeList(){
    return this.http
    .get<any[]>(this.apiUrl + '/getAllSchemeList')

  }
  

  public getBCodeDetails() {
    return this.http
      .get(this.apiUrl + '/getBCodeDetails')

  }





  public getUnits() {
    return this.http
      .get<any[]>(this.apiUrl + '/getUnits')

  }

  public getPTypeList() {
    return this.http
      .get(this.apiUrl + '/getPType')

  }


  public getMotherPack() {
    return this.http
      .get(this.apiUrl + '/getMotherPackList')

  }

  public getModelList() {
    return this.http
      .get(this.apiUrl + '/getModelList')

  }

  public getMCat1List() {
    return this.http
      .get<any[]>(this.apiUrl + '/getMCat1List')

  }

  public saveProduct(
    mode: string,
    prodObj: any,
    RGLIST: any[],
    AlternateUnits: any[],
    PBarCodeCollection: any[],
    BrandModelList: any[],
    PMultipleRetailPrice: any[]
  ) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    let hd: Headers = new Headers({ 'Content-Type': 'application/json' });
    
    let bodyData = {
      mode: mode,
      data: {
        product: prodObj,
        rglist: RGLIST,
        alternateunits: AlternateUnits,
        bcodeList: PBarCodeCollection,
        bmList: BrandModelList,
        multipleretailpriceList: PMultipleRetailPrice
      }
    };
    let data = JSON.stringify(bodyData, undefined, 2);
    this.http
      .post(
        this.apiUrl + '/saveProductMaster',
        bodyData

      )
      .subscribe(
        data => {
          let retData = data;
          if (retData['status'] === 'ok') {
            res.status = 'ok';
            res.result = retData['result'];
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = retData['result'];
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  public saveProductGroup(
    mode: string,
    prodObj: any,
    groupType:any
  ) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();

    let hd: Headers = new Headers({ 'Content-Type': 'application/json' });

    let bodyData = {
      mode: mode,
      data: {
        product: prodObj,
   
      },
      groupType:groupType
    };
    let data = JSON.stringify(bodyData, undefined, 2);
    this.http
      .post(
        this.apiUrl + '/saveProductMasterGroup',
        bodyData

      )
      .subscribe(
        data => {
          let retData = data;
          if (retData['status'] === 'ok') {
            res.status = 'ok';
            res.result = retData['result'];
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = retData['result'];
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  public getFiscalYearList() {
    return this.http
      .get(this.apiUrl + '/fiscalYear')
  }

  public loadCompany() {
    return new Observable((observer: Subscriber<Company>) => {
      const res = { status: 'error', result: '' };
      this.http
        .get(this.apiUrl + '/loadCompanyInfo')
        .subscribe(
          (data: any) => {
            observer.next(data.result);
          },
          error => {
            observer.next(null);
            observer.complete();
          }
        );
    });
  }


  public resolveError(error: any, callFrom: string) {
    try {
      let dialogResult;
      if (error.statusText === 'Unauthorized') {
        if (this.IsLoginDialogOpened === true) {
          return;
        }
        this.IsLoginDialogOpened = true;
        // let dialogRef = this.dialog.open(LoginDialog, { disableClose: true });
        // dialogRef.afterClosed().subscribe(result => {
        //   this.IsLoginDialogOpened = false;
        // });
        return null;
      }
      const err = error;
      if (
        err &&
        err === 'The ConnectionString property has not been initialized.'
      ) {
        if (this.IsLoginDialogOpened === true) {
          return;
        }
        this.IsLoginDialogOpened = true;
        // let dialogRef = this.dialog.open(LoginDialog, { disableClose: true });
        // dialogRef.afterClosed().subscribe(result => {
        //   this.IsLoginDialogOpened = false;
        // });
        return null;
      }
      return err;
    } catch (ex) {
    }
  }

  public getStockForCheck(vchrno) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getStockForSalesReturn/?vchrno=${vchrno}`)
      .subscribe((response: any) => {
        const data = response.json();
        if (data.status === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }

  getProductDetailsFromMCode(pdData) {
    return this.http.post(`${this.apiUrl}/getProductDetailsFromMCode`, pdData);
  }

  getStockBatchWise(mcode) {
    let data = { mcode: mcode, Quantity:0}
    return this.http.post(`${this.apiUrl}/getbatchWiseStock`, data);
  }


  getbarCodeWiseProductList(apiEndpoints) {

    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + `/${apiEndpoints}`).subscribe((response: any) => {
      const data = response.json();
      if (data.status === 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      } else {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  public saveCompany(costCenter: any) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    const bodyData = { data: costCenter };
    this.http
      .post(this.apiUrl + '/saveCompanyInfo', bodyData)
      .subscribe(
        (data: any) => {
          const retData = data;
          if (retData.status === 'ok') {
            res.status = 'ok';
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          returnSubject.next(error);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  focusAnyControl(id: string) {
    const control = document.getElementById(id);
    console.log("ID",id);
    console.log("Control",control);
    if (control != null) {
      setTimeout(() => {
        control.focus();
        control.click();
      });
    }
  }
  RemoveFocusFromAnyControl(id: string) {
    const control = document.getElementById(id);
    if (control != null) {
      control.blur();
    }
  }
  scrollToControlInTable(id: string) {
    const elmnt = document.getElementById(id);
    if (elmnt != null) {
      elmnt.scrollIntoView();
    }
  }


  getSettlementMode() {
    return this.http
      .get<any[]>(this.apiUrl + "/getSettlementMode")
  }
  public LoadStockSettlement(vchrno: string) {
    let bodyData = { VCHRNO: vchrno };
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    this.http
      .post(
        this.apiUrl + '/getStockSettlement', bodyData)
      .subscribe(
        data => {
          if (data['status'] === 'ok') {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;


  }

  public getRefBillCheck(vchrno) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getRefBillNoForCheck/${vchrno}`)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }

  public getVoucherNo(TrnMainObj: any) {
    const returnSubject: Subject<any> = new Subject();
    this.userProfile = this.authService.getUserProfile();
    TrnMainObj.PhiscalID = this.userProfile.PhiscalYearInfo.PhiscalID;
    this.http
      .post(this.apiUrl + '/getVoucherNo', TrnMainObj)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
        } else {
          returnSubject.next(data);
        }
      });
    return returnSubject;
  }

 

  public getCurrentDate() {
    const date: Subject<any> = new Subject();
    this.http
      .get(this.apiUrl + '/getCurrentDate')
      .subscribe((response: any) => {
        date.next(response);
        date.unsubscribe();
      });
    return date;
  }




  getAccountWiseTrnAmount(trnDate: string, companyId: string, acid: string, division: string, requestType: number = 0) {
    return new Observable((observer: Subscriber<any>) => {
      const url = `${this.apiUrl}/getAccountWiseTrnAmount/${requestType}?trnDate=${trnDate}&companyId=${companyId}&acid=${acid}&division=${division}`;
      this.http
        .get(url)
        .subscribe(
          (res: any) => {
            const data = res;
            if (data.status === 'ok') {
              observer.next(data);
              observer.unsubscribe();
            }
            else {
              observer.next(data)
              observer.unsubscribe();
            }
          },
          error => {
            observer.next({} as any);
          });
    });
  }

  public getWarehouseList() {
    return this.http
      .get<any[]>(this.apiUrl + "/getWarehouseList")
  }

  public getItemTypeList(){
    return this.http.get<any[]>(this.apiUrl + "/getReceipeItemTypeList");
  }

  public getNotLoginWarehouseList(){
    return this.http.get<any[]>(this.apiUrl+"/getNotLoginWarehouseList");
  }
  public getWarehouseListInReqApproval(division:string){
    return this.http.get<any[]>(this.apiUrl+`/getWarehouseListInRequisitionApproval?division=${division}`);
  }

  public getLoginWarehouseList(){
    return this.http.get<any[]>(this.apiUrl+'/getLoginWarehouseList');
  }

  getState() {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getStates').subscribe((data: any) => {
      if (data.status === 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  public getAllDivisionList(refresh: boolean = false) {
    return this.http
      .get(this.apiUrl + '/getDivisionList')
  }
  public getAllProductCategoryLine(refresh: boolean = false) {
    return this.http
      .get(this.apiUrl + '/getProdcutCategoryLineList')

  }

  GETTRNTYPE() {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getTrnType').subscribe(data => {
      if (data['status'] === 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }


  getAllAccount(ACID: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    const bodyData = { mode: 'query', data: { ACID } };
    this.http
      .post(this.apiUrl + '/getAccountParty', bodyData)
      .subscribe(
        data => {
          if (data['status'] === 'ok') {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }



  getAllHierachy() {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/getAllHierachy').subscribe((response: any) => {
      const data = response;
      if (data.status === 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }



  public saveTransaction(mode: string, trnmain: any, extra: any = null) {
    try {
      this.spinnerService.show('Saving data Please wait a moment...');
      const res = { status: 'error', result: '' };
      const returnSubject: Subject<any> = new Subject();
      let bodyData = {};
      if (extra == null) {
        bodyData = { mode, data: trnmain };
      } else {
        bodyData = { mode, data: trnmain, extra };
      }
      
      this.http.post(this.apiUrl + '/saveTransaction', bodyData)
        .subscribe(
          (data: any) => {
            const retData = data;
            if (retData.status === 'ok') {

             this.alertService.success('Data Saved Successfully');
              returnSubject.next(retData);
              setTimeout(() => {
                this.spinnerService.hide();
              }, 1000);
            } else {
              res.status = 'error1';
              res.result = retData.result;
              this.spinnerService.hide();
              returnSubject.next(res);
            }
          },
          error => {
            this.spinnerService.hide();
            returnSubject.next({ status: 'error', result: error });
          }
        );
      return returnSubject;
    } catch (ex) {
    }
  }

  public saveSalesManType(mode: string, salesmanType: any) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    //let opt = this.getRequestOption();
    let hd: Headers = new Headers({ "Content-Type": "application/json" });
   // let op: RequestOptions = new RequestOptions();
   const options = {headers:this.getRequestOption(), method:'post'}
    let bodyData = { mode: mode, data: salesmanType };
    this.http
      .post(this.apiUrl + '/saveSalesmanType', bodyData, options)
      .subscribe(
        (data: any) => {
          let retData = data;
          if (retData.status == "ok") {
            res.status = "ok";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  // public getRequestOption() {
  //   let headers: Headers = new Headers({
  //     "Content-type": "application/json",
  //     Authorization: this.authService.getAuth().token,
  //     "X-Requested-With": 'XMLHttpRequest'
  //   });
  //   return new RequestOptions({ headers: headers });
  // }

  private getRequestOption() {
    const headers = new HttpHeaders({
        'Content-type': 'application/json',
       Authorization: this.authService.getAuth().token,
       "X-Requested-With": 'XMLHttpRequest'
     });
     return headers;
   }

   public saveWarehouse(mode: string, warehouse: any) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    // let opt = this.getRequestOption();
    // let hd: Headers = new Headers({ "Content-Type": "application/json" });
    const options = {headers:this.getRequestOption(), method:'post'}
    // let op: RequestOptions = new RequestOptions();
    let bodyData = { mode: mode, data: warehouse };
    this.http
      .post(this.apiUrl + "/saveWarehouse", bodyData, options)
      .subscribe(
      (data: any) => {
          let retData = data;
          if (retData.status == "ok") {
            res.status = "ok";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  public saveScheme(
    mode: string,
    schemeObj: any
  ) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let opt = this.getRequestOption();
    let hd: Headers = new Headers({ "Content-Type": "application/json" });
    const options = {headers:this.getRequestOption(), method:'post'}
   
    let bodyData = {
      mode: mode,
      data: schemeObj
    };
    this.http
      .post(this.apiUrl + "/schemeSave", bodyData,options)
      .subscribe(
       (data: any) => {
          let retData = data;
          if (retData.status == "ok") {
            res.status = "ok";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  public saveMarginScheme(mode: string, marginSchemObj: any){
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let opt = this.getRequestOption();
    let hd: Headers = new Headers({ "Content-Type": "application/json" });
    const options = {headers:this.getRequestOption(), method:'post'}
   
    let bodyData = {
      mode: mode,
      data: marginSchemObj
    };
    this.http
      .post(this.apiUrl + "/saveMarginScheme", bodyData,options)
      .subscribe(
       (data: any) => {
          let retData = data;
          if (retData.status == "ok") {
            res.status = "ok";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  
  public getAllSchedule() {
    return this.http
      .get(this.apiUrl + "/getSchedule")
  }
    

  deleteTransaction(deleteObj){
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    return this.http.post(`${this.apiUrl}/DeleteTransaction`, deleteObj, options);
  }

  getColorGroup() {
    return this.http.get(`${this.apiUrl}/getAllProductColorGroup`);
  }

  getProductColorByMCode(mCode) {
    return this.http.get(`${this.apiUrl}/getProductColorByMCode/${mCode}`);
  }

  getProductIMEINOByMCodeAndColor(mCode,color,batch) {
    return this.http.get(`${this.apiUrl}/getProductIMEINOByMCodeAndColor/${mCode}/${color}/${batch}`);
  }

  getProductIMEINOTwoByMCodeAndColor(mCode,color,IMEINO1) {
    return this.http.get(`${this.apiUrl}/getProductIMEINOTwoByMCodeAndColor/${mCode}/${color}/${IMEINO1}`);
  }

  getProductDetailsbyMCodeAndIMEINO1(mCode,IMEINO1) {
    return this.http.get(`${this.apiUrl}/getProductBCodeDetail/${mCode}/${IMEINO1}`);
  }
  getProductBCodeDetailForSalsReturn(mCode,IMEINO1) {
  return this.http.get(`${this.apiUrl}/getProductBCodeDetailForSalsReturn/${mCode}/${IMEINO1}`);
  }



  public getMcodeWiseStockList() {
    return this.http.get<any[]>(this.apiUrl + '/getAllMenuitemWithMCodeWiseStock')
  }

  public LoadTransaction(vchrno: string, division: string, phiscalID: string, BARODE = '', REFBILL = '',senderDivision='',mode:string='') {
    let bodyData: any;
    if (BARODE !== '') {
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID, BARCODE: BARODE };
    }
    if (REFBILL !== '') {
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID, REFBILL: REFBILL };
    }
    else if(senderDivision !==''){
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,senderDivision:senderDivision };
    }
    else {
      console.log("intermode",mode);
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,MODE:mode };
    }
    return this.http
      .post(this.apiUrl + '/getViewVoucher', bodyData)

  }



  public LoadTransactionForDebitNote(vchrno: string, division: string, phiscalID: string,mode:string='') {
 
    let  bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,MODE:mode };

    return this.http
      .post(this.apiUrl + '/getViewVoucher', bodyData)

  }


  public loadVoucherData_BCode_piWise(vchrno: string, division: string, phiscalID: string, BARODE = '', REFBILL = '',senderDivision='',mode:string='') {
    let bodyData: any;
    if (BARODE !== '') {
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID, BARCODE: BARODE };
    }
    if (REFBILL !== '') {
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID, REFBILL: REFBILL };
    }
    else if(senderDivision !==''){
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,senderDivision:senderDivision };
    }
    else {
      console.log("intermode",mode);
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,MODE:mode };
    }
    return this.http
      .post(this.apiUrl + '/loadVoucherData_BCode_PIWise', bodyData)

  }


  public LoadTransactionForIssueReturn(vchrno: string, division: string, phiscalID: string,  REFBILL,warehouse) {
    let bodyData: any;
      bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID, REFBILL: REFBILL,WAREHOUSE: warehouse };
    return this.http
      .post(this.apiUrl + '/getViewVoucher', bodyData)

  }


  public LoadTransactionForBranchOut(vchrno: string, division: string, phiscalID: string, warehouse){     
    let  bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,WAREHOUSE: warehouse  };
    return this.http.post(this.apiUrl + '/loadPIWiseBranchTransferOutVoucherData', bodyData)

  }

  public LoadRequisitionForBranchOut(vchrno: string, division: string, phiscalID: string,  warehouse, FROMTO = ''  ){
    let bodyData = {VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,WAREHOUSE: warehouse, REFBILL: FROMTO};
    return this.http.post(this.apiUrl + '/getRequisitionVoucher', bodyData)

  }

  public LoadRequisitionForRA(vchrno: string, division: string, phiscalID: string,  warehouse, FROMRA, BILLTOADD){
    let bodyData = {VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,WAREHOUSE: warehouse, REFBILL: FROMRA, senderDivision: BILLTOADD};
    return this.http.post(this.apiUrl + '/getRequisitionVoucher', bodyData)
  }

  public LoadRequisitionForPurchaseOrder(vchrno: string, division: string, phiscalID: string,  warehouse, FROMPO = ''  ){
    let bodyData = {VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,WAREHOUSE: warehouse, REFBILL: FROMPO};
    return this.http.post(this.apiUrl + '/getRequisitionVoucher', bodyData)

  }

  public LoadRequisitionForStockIssue(vchrno: string, division: string, phiscalID: string,  warehouse, FROMPO = ''  ){
    console.log("load for stock issue");
    let bodyData = {VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,WAREHOUSE: warehouse, REFBILL: FROMPO};
    return this.http.post(this.apiUrl + '/getRequisitionVoucher', bodyData)

  }


  public LoadSalesReturnForApproval(vchrno: string, division: string, phiscalID: string) {
    let bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID };
    return this.http
      .post(this.apiUrl + '/getSalesReturnForApproval', bodyData)
  }


  public getCustomerListBasedUponRouteAndSalesman(data) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    this.http.post(`${this.apiUrl}/getCustomerBySalesmanAndRoute`, data)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }
  public getSalesmanBasedUponCustomer(salesmanid) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getSalesManListByCustomer?SALESMANID=${salesmanid}`)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }
  public getInvoiceData(vchrno: string, division: string, phiscalID: string, parac: string, tag: string = '', refbill: string = '') {
    const bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID, PARAC: parac, tag, REFBILL: refbill };
    return this.http
      .post(this.apiUrl + '/getInvoiceData', bodyData)
  }

  public saveMultiMobileSO(listOfVouchers: any, tag: string = '') {
    return this.http
      .post(`${this.apiUrl}/saveMultipleTransaction?tag=${tag}`, listOfVouchers)
  }
  public getReprintData(vchrno: string, division: string, phiscalID: string, trnUser: string, posPrintCount: number = 1) {
    return this.http
      .get(this.apiUrl + `/getReprintData?VCHRNO=${vchrno}&DIVISION=${division}&PHISCALID=${phiscalID}&TRNUSER=${trnUser}&COUNT=${posPrintCount}`)
  }

  public getMobileOrderFilteredDateWise(from, to) {
    return this.http
      .get(`${this.apiUrl}/getMobileOrderFilteredDateWise?From=${from}&To=${to}`)
  }
  
  public saveStockSettlement(mode: string, trnmain: any, extra: any = null) {
    try {
      this.spinnerService.show('Saving data Please wait a moment...')
      const res = { status: 'error', result: '' };
      const returnSubject: Subject<any> = new Subject();
      let bodyData = {};
      if (extra == null) {
        bodyData = { mode, data: trnmain };
      } else {
        bodyData = { mode, data: trnmain, extra };
      }

      //
      this.http.post(this.apiUrl + '/saveStockSettlement', bodyData)
        .subscribe(
          retData => {
            if (retData['status'] === 'ok') {
              this.alertService.success('Data Saved Successfully');
              returnSubject.next(retData);
              setTimeout(() => {
                this.spinnerService.hide();
              }, 1000);
            } else {
              res.status = 'error1';
              res.result = retData['result'];
              //
              this.alertService.error(retData['result']);
              setTimeout(() => {
                this.spinnerService.hide();
                returnSubject.next(res);
              }, 3000);
            }
          },
          error => {
            this.alertService.error(error.json());
            setTimeout(() => {
              this.spinnerService.hide();
              returnSubject.next({ status: 'error', result: error });
            }, 3000);
          }
        );
      return returnSubject;
    } catch (ex) {

    }
  }

  public saveStockSettlementApproval(mode: string, trnmain: any, extra: any = null) {
    try {
      this.spinnerService.show('Saving data Please wait a moment...')
      const res = { status: 'error', result: '' };
      const returnSubject: Subject<any> = new Subject();
      let bodyData = {};
      if (extra == null) {
        bodyData = { mode, data: trnmain };
      } else {
        bodyData = { mode, data: trnmain, extra };
      }

      //
      this.http
        .post(
          this.apiUrl + '/saveApprovalStockSettlement',
          bodyData)
        .subscribe(
          (retData: any) => {
            if (retData.status === 'ok') {
              this.alertService.success('Data Saved Successfully');
              returnSubject.next(retData);
              setTimeout(() => {
                this.spinnerService.hide();
              }, 1000);
            } else {
              res.status = 'error1';
              res.result = retData.result;
              //
              this.alertService.error(res.result);
              setTimeout(() => {
                this.spinnerService.hide();
                returnSubject.next(res);
              }, 3000);
            }
          },
          error => {
            this.alertService.error(error.json());
            setTimeout(() => {
              this.spinnerService.hide();
              returnSubject.next({ status: 'error', result: error });
            }, 3000);
          }
        );
      return returnSubject;
    } catch (ex) {

      // alert(ex);
    }
  }

  public cancelInvoice(vchrno: string, voucherType: string) {
    return this.http.post(this.apiUrl + '/cancelInvoice', { VCHRNO: vchrno, VOUCHERTYPE: voucherType })
  }
  approvePerformaInvoice(voucerNo: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/ApproveHOPerformaInvoice?voucherNo=${voucerNo}`)
      .subscribe(
        (data: any) => {
          if (data.status === 'ok') {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  rejectPerformaInvoice(voucerNo: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/RejectHOPerformaInvoice?voucherNo=${voucerNo}`)
      .subscribe(
        (data: any) => {
          if (data.status === 'ok') {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  public saveAccount(mode: string, Account: any, createMember = null, PartyAdditional: any = {}, DObj = null,SalesTargetData:any={},AdditionalBankObj=null) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let opt = this.getRequestOption();
    let hd: Headers = new Headers({ "Content-Type": "application/json" });
    let op = this.getRequestOption();
    let bodyData = { mode: mode, data: Account, PartyAdditional: PartyAdditional, createMember: createMember, DObj,SalesTargetData:SalesTargetData,AdditionalBankObj };
    this.http
      .post(this.apiUrl + "/saveAccountParty", bodyData,)
      .subscribe(
        (data:any) => {
          let retData = data;
          if (retData.status == "ok") {
            res.status = "ok";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  public saveAccounts(mode: string, Account: any,createMember, PartyAdditional:any={}) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let opt = this.getRequestOption();
    let hd: Headers = new Headers({ "Content-Type": "application/json" });
    let op = this.getRequestOption();
    let bodyData = { mode: mode, data: Account,PartyAdditional : PartyAdditional,createMember:createMember};
    this.http
      .post(this.apiUrl + "/saveAccount", bodyData)
      .subscribe(
        (data:any) => {
          let retData = data.json();
          if (retData.status == "ok") {
            res.status = "ok";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  public refreshTransactionList() {
    this.AllDivisions = [];
    this.getAllDivisions().subscribe((res) => {
      this.AllDivisions.push(res)
    })
  }
  public getAllDivisions() {
    return this.http
      .get(this.apiUrl + '/getDivisionlist')
  }


  CHECKTRANSPORT() {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/CheckTransport').subscribe((data: any) => {
      if (data.status === 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();

      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  public checkUserValid() {
    return new Observable((observer: Subscriber<boolean>) => {
      this.http
        .get(this.apiUrl + '/checkLogin')
        .subscribe(
          data => {
            this.state.setGlobalSetting('LoggedIn', ['true']);
            observer.next(true);
            observer.complete();
          },
          Error => {
            observer.next(false);
            observer.complete();
          },
          () => {
            observer.complete();
          }
        );
    });
  }



  TenderTypes() {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + '/GetTenderTypes').subscribe((data: any) => {
      if (data.status === 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  nullToZeroConverter(value) {
    if (
      value === undefined ||
      value == null ||
      value === '' ||
      value === 'Infinity' ||
      value === 'NaN' ||
      isNaN(parseFloat(value))
    ) {
      return 0;
    }
    return parseFloat(value);
  }



  getReportData(dataToSave, postUrl) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();

    this.http
      .post(this.apiUrl + postUrl, dataToSave)
      .subscribe(
        (data: any) => {
          const retData = data;
          if (retData.status === 'ok') {
            res.status = 'ok';
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  public downloadConfigExcel(importName: string) {
    const type = 'application/vnd.ms-excel';    
    return this.http
      .get(this.apiUrl + `/downloadSampleFile/${importName}`) 
      .map((response: Response) => {
        let data = {
          content: new Blob([(<any>response)._body], {
            type: response.headers.get("Content-Type")
          }),
          filename: `${importName}.xlsx`
        };
        return data;
      });

  }

  masterGetmethod(geturl) {
    const res = { status: 'error', result: '',message:'' };
    const returnSubject: Subject<any> = new Subject();

    this.http.get(this.apiUrl + geturl).subscribe(
      (data: any) => {
        if (data.status === 'ok') {
          console.log("result",data);
          res.status = 'ok';
          res.result = data.result;
          res.message = data.message;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        } else {
          res.status = 'error';
          res.result = data.result;

          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      },
      error => {
        (res.status = 'error2'), (res.result = error);

        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    );
    return returnSubject;
  }


  masterPostmethod(posturl, data) {
    console.log("masterpos",posturl);
    const res = { status: 'error', result: '', result2: '', message: '' };
    const returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:'post'}
    this.http
      .post(this.apiUrl + posturl, data,options)
      .subscribe(
        (data: any) => {
          const retData = data;
          if (retData.status === 'ok') {
            res.status = 'ok';
            res.result = retData.result;
            res.result2 = retData.result2;
            res.message = retData.message;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error';
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  getGridSetttingForMultiplePrice(mapid,MCODE,PREFIX){
    let isShowNetRATE = false;
    let isShowBatchWiseRATE = false;
    let isShowBatch = false;
    if(PREFIX == 'DN'){
      isShowNetRATE = false;
      isShowBatchWiseRATE = true;
      isShowBatch = false;
    }else if(PREFIX == 'TI'){
     isShowNetRATE = true;
     isShowBatchWiseRATE = false;
     isShowBatch = true;
    }


    if(mapid === 'multiplePrice'){
      return {
        title: "Price List  ",
        apiEndpoints: `/getBatchwisePurchaseRatePagedList/${MCODE}/${PREFIX}`,
        defaultFilterIndex: 1,
        useDefinefilterValue:null,
        columns:Object.assign([     
          {
            key: 'batch',
            title: 'BATCH',
            hidden: isShowBatch,
            noSearch: false,
            isInputRequired: true
          },
          {
            key: 'netrate',
            title: 'NETRATE',
            hidden: isShowNetRATE,
            noSearch: false,
            isInputRequired: true

          },
          {
            key: 'rate',
            title: 'RATE',
            hidden: isShowBatchWiseRATE,
            noSearch: false,
            isInputRequired: true

          },   
          {
            key: 'qty',
            title: 'Quantity',
            hidden: true,
            noSearch: false,
            isInputRequired: false
          },
          {
            key: 'stock',
            title: 'STOCK',
            hidden: false,
            noSearch: false,
            isInputRequired: true
          }


        ])
      };

    }
  }


  getGenericGridPopUpSettings(VoucherPrefix,tag:string='') {
    console.log("get generic grid pop ", VoucherPrefix);
    if (VoucherPrefix === 'PP') {
      return {
        title: 'Performa Invoice',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'VCHRNO',
            title: 'PERFORMA NO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false,
            alignment: "right",
            pipe: true
          },
          {
            key: 'PARAC',
            title: 'CUSTOMER',
            hidden: false,
            noSearch: false
          },
          // {
          //   key: 'TRNMODE',
          //   title: 'TYPE',
          //   hidden: false,
          //   noSearch: false
          // },
        ]
      };
    }
    if (VoucherPrefix === 'PQ') {
      return {
        title: 'Performa Cancel',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'VCHRNO',
            title: 'PERFORMA NO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false,
            alignment: "right",
            pipe: true
          },
          {
            key: 'PARAC',
            title: 'CUSTOMER',
            hidden: false,
            noSearch: false
          },
          // {
          //   key: 'TRNMODE',
          //   title: 'TYPE',
          //   hidden: false,
          //   noSearch: false
          // },
        ]
      };
    }
    if (VoucherPrefix === 'SO') {
      return {
        title: 'Sales Orders',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'VCHRNO',
            title: 'SONO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNMODE',
            title: 'TYPE',
            hidden: false,
            noSearch: false
          },
        ]
      };
    }
    if (VoucherPrefix === 'PO') {
      if(tag == '' || tag == null || tag === undefined){
        tag='tag';
      }
      return {
        title: 'Purchase Orders',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/${tag}`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'VCHRNO',
            title: 'PONO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false,
            alignment: "right",
            pipe: true
          },
          {
            key: 'ACNAME',
            title: 'SUPPLIER',
            hidden:false,
            noSearch:false
          }
        ]
      };

    }

    if (VoucherPrefix === 'PI') {
      if(tag == '' || tag == null || tag === undefined){
        tag='tag';
      }
      return {
        title: 'Purchase Invoices',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/${tag}`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'VCHRNO',
            title: 'VOUCHERNO',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'REFBILL',
            title: 'Invoice No',
            hidden: false,
            noSearch:false
          },
          {
            key: 'BILLTO',
            title: 'Supplier',
            hidden: false,
            noSearch:false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false,
            alignment: "right",
            pipe: true
          }
        ]
      };
    }

    if ( VoucherPrefix === 'IS') {
      if(tag == '' || tag == null || tag === undefined){
        tag='tag';
      }
      return {
        title: 'Stock Issue',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/${tag}`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'VCHRNO',
            title: 'VOUCHERNO',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'REFBILL',
            title: 'Invoice No',
            hidden: false,
            noSearch:false
          },
          {
            key: 'BILLTO',
            title: 'Supplier',
            hidden: false,
            noSearch:false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false,
            alignment: "right",
            pipe: true
          }
        ]
      };
    }

    if (VoucherPrefix === 'REFBILLFORSTOCKTRANSFERAPPROVAL') {
      return {
        title: 'Approval Invoices',
        apiEndpoints: `/getRefBillForStockTransferApproval`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'CHALANNO',
            title: 'GRNNO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          }
        ]
      };
    }




    if (VoucherPrefix === 'REFBILLOFSALESRETURN') {
      return {
        title: 'Bill Details',
        apiEndpoints: `/getTrnMainPagedList/TI/tag`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'CHALANNO',
            title: 'BILL NO',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNMODE',
            title: 'TYPE',
            hidden: false,
            noSearch: false
          },

          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          },
          {
            key: 'INVOICETYPE',
            title: 'INVOICE TYPE',
            hidden: false,
            noSearch: false
          },
        ]
      };
    }
    if (VoucherPrefix === 'REFBILLOFPURCHASERETURN') {
      return {
        title: 'Bill Details',
        apiEndpoints: `/getPruchaseListForDebitNote`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'VCHRNO',
            title: 'BILL NO',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'REFBILL',
            title: 'SUPPLIER INV NO',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNMODE',
            title: 'PAYMENT TERMS',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          }
        ]
      };
    }
    if (VoucherPrefix === 'itemlist') {
      return {
        title: 'Sales Orders',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'CHALANNO',
            title: 'SONO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNMODE',
            title: 'TYPE',
            hidden: false,
            noSearch: false
          },
        ]
      };
    }
    if (VoucherPrefix === 'HOLDBILLLIST') {
      return {
        title: 'Hold Bills',
        apiEndpoints: `/recallallholdbill`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'ACNAME',
            title: 'Customer',
            hidden: false,
            noSearch: false
          },
          {
            key: 'SNO',
            title: 'Bill No',
            hidden: false,
            noSearch: false
          },
          {
            key: 'DATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          }
          ,
          {
            key: 'USER',
            title: 'USER',
            hidden: false,
            noSearch: false
          }
        ]
      };

    }
    if (VoucherPrefix === 'RI') {
      // alert("reache23")
      return {
        title: 'Indent Request',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'INDENTNO',
            title: 'Indent No.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'DATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },

        ]
      };
    }
    if (VoucherPrefix === 'Transporter') {
      return {
        title: 'Transporter Name',
        apiEndpoints: `/getTransportFromMaster/${VoucherPrefix}`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'NAME',
            title: 'Name',
            hidden: false,
            noSearch: false
          },
          {
            key: 'ADDRESS',
            title: 'Address',
            hidden: false,
            noSearch: false
          }

        ]
      };
    }
    if (VoucherPrefix === 'INTENDLIST') {
      return {
        title: 'Intend List',
        apiEndpoints: `/getAllIndentPagedListForPO`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'INDENTNO',
            title: 'Indent No.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'EDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },

        ]
      };
    }

    if (VoucherPrefix === 'APPROVEDSALESRETURN') {
      return {
        title: 'Vouchers',
        apiEndpoints: `/getApprovedDamageSalesReturnForView`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'CHALANNO',
            title: 'VOUCHER NO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          },
          {
            key: 'REMARKS',
            title: 'REMARKS',
            hidden: false,
            noSearch: false
          },
        ]
      };

    }



    if (VoucherPrefix === 'APPROVEDSTOCKTRANSFER') {
      return {
        title: 'Vouchers',
        apiEndpoints: `/getApprovedStockTransferForView`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'CHALANNO',
            title: 'VOUCHER NO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          },
          {
            key: 'REMARKS',
            title: 'REMARKS',
            hidden: false,
            noSearch: false
          },
        ]
      };

    }



    if (VoucherPrefix === 'StockTransferForPurchaseReturn') {
      return {
        title: 'Bill Details ',
        apiEndpoints: `/getApprovedStockTransferForView`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'REFBILL',
            title: 'BILL NO',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNMODE',
            title: 'TYPE',
            hidden: false,
            noSearch: false
          },

          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          },
          {
            key: 'INVOICETYPE',
            title: 'INVOICE TYPE',
            hidden: false,
            noSearch: false
          },
        ]
      };

    }


    if (VoucherPrefix === 'DamageIssueForPurchaseReturn') {
      return {
        title: 'Bill Details ',
        apiEndpoints: `/getApprovedDamageSalesReturnForView`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'CHALANNO',
            title: 'BILL NO',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'TRNMODE',
            title: 'TYPE',
            hidden: false,
            noSearch: false
          },

          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          },
          {
            key: 'INVOICETYPE',
            title: 'INVOICE TYPE',
            hidden: false,
            noSearch: false
          },
        ]
      };

    }


    if (VoucherPrefix === 'SalesManList') {
      return {
        title: 'SalesMan',
        apiEndpoints: `/getSalesmanPagedList`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: "SALESMANID",
            title: "ID",
            hidden: false,
            noSearch: false
          },
          {
            key: "NAME",
            title: "NAME",
            hidden: false,
            noSearch: false
          },    
          {
            key: 'MOBILE',
            title: 'MOBILE',
            hidden: false,
            noSearch: false
          },

        ]
      };

    }


    if (VoucherPrefix === 'MemberList') {
      return {
        title: 'Members',
        apiEndpoints: `/getMemberPagedList`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'FNAME',
            title: 'MEMBER NAME',
            hidden: false,
            noSearch: false
          },
          // {
          //   key: 'LNAME',
          //   title: 'LastName',
          //   hidden: false,
          //   noSearch: false
          // },
          {
            key: 'MEMID',
            title: 'MEMBER ID',
            hidden: false,
            noSearch: false
          },
          {
            key: 'MOBILE',
            title: 'MOBILE NO',
            hidden: false,
            noSearch: false
          },
          {
            key: 'EMAIL',
            title: 'EMAIL',
            hidden: false,
            noSearch: false
          },

        ]
      };

    }


    if (VoucherPrefix === 'ProductList') {
      return {
        title: 'Product',
        apiEndpoints: `/getProductCodePagedList`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'MCODE',
            title: 'ID',
            hidden: false,
            noSearch: false
          },
          {
            key: 'DESCA',
            title: 'NAME',
            hidden: false,
            noSearch: false
          }

        ]
      };

    }

    if (VoucherPrefix === 'SupplierList') {
      return {
        title: 'Supplier',
      apiEndpoints: `/getAccountPagedListByPType/PA/V`,
      defaultFilterIndex: 0,
      columns: [
        {
          key: 'ACNAME',
          title: 'NAME',
          hidden: false,
          noSearch: false
        },
        {
          key: 'ERPPLANTCODE',
          title: 'CODE',
          hidden: false,
          noSearch: false
        },
        {
          key: 'ADDRESS',
          title: 'ADDRESS',
          hidden: false,
          noSearch: false
        },
        {
          key: 'EMAIL',
          title: 'EMAIL',
          hidden: false,
          noSearch: false
        }
      ]
      };

    }

    if (VoucherPrefix === 'CustomerList') {
      return {
        title: "Customers",
        apiEndpoints: `/getCustomerFromCustomerProfile`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: "CUSTNAME",
            title: "NAME",
            hidden: false,
            noSearch: false
          },
  
          {
            key: "ADDRESS",
            title: "ADDRESS",
            hidden: false,
            noSearch: false
          },
          {
            key: "MOBILENO",
            title: "MOBILE",
            hidden: false,
            noSearch: false
          }
        ]
      };

    }


    if (VoucherPrefix === 'barcodeList') {
      return {
        title: "Barcode",
        apiEndpoints: `/getAllBarcodePagedList`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: "BARCODE",
            title: "NAME",
            hidden: false,
            noSearch: false
          },  
          {
            key: "COLOR",
            title: "COLOR",
            hidden: false,
            noSearch: false
          }
     
        ]
      };

    }



    if (VoucherPrefix === 'ItemsList') {
      return {
        title: "Products",
        apiEndpoints: `/getItemListPagedList`,
        defaultFilterIndex: this.userSetting.DefaultFilterIndex ? this.userSetting.DefaultFilterIndex : 0,
        columns: [
          {
            key: "MENUCODE",
            title: "ITEM CODE",
            hidden: false,
            noSearch: false
          },
          {
            key: "DESCA",
            title: "DESCRIPTION",
            hidden: false,
            noSearch: false
          }
        ]
      };

    }


    if(VoucherPrefix === "SI" && tag=='MembershipSalesInvoice'){
  
      return{
        title: 'Vouchers',
      apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/${tag}`,
      defaultFilterIndex: 1,
      columns: [
        {
          key: 'TRNDATE',
          title: 'DATE',
          hidden: false,
          noSearch: false
        },
        {
          key: 'VCHRNO',
          title: 'VOUCHER NO.',
          hidden: false,
          noSearch: false
        },
        {
          key: 'REFBILL',
          title: 'Ref Bill No.',
          hidden:false,
          noSearch: false
        },
        {
          key: 'BILLTO',
          title: 'Supplier',
          hidden: false,
          noSearch:false
        },
        {
          key: 'NETAMNT',
          title: 'AMOUNT',
          hidden: false,
          noSearch: false,
          alignment: "right",
          pipe: true
        },
        {
          key: 'REMARKS',
          title: 'REMARKS',
          hidden: false,
          noSearch: false
        },
      ]

      };
    }


    if(VoucherPrefix === "DN" || VoucherPrefix === "TI" || VoucherPrefix ==="CN"){
      if(tag == '' || tag == null || tag === undefined){
        tag='tag';
      }
      return{
        title: 'Vouchers',
      apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/${tag}`,
      defaultFilterIndex: 1,
      columns: [
        {
          key: 'TRNDATE',
          title: 'DATE',
          hidden: false,
          noSearch: false
        },
        {
          key: 'VCHRNO',
          title: 'VOUCHER NO.',
          hidden: false,
          noSearch: false
        },
        {
          key: 'REFBILL',
          title: 'Ref Bill No.',
          hidden:false,
          noSearch: false
        },
        {
          key: 'BILLTO',
          title: 'Supplier',
          hidden: false,
          noSearch:false
        },
        {
          key: 'NETAMNT',
          title: 'AMOUNT',
          hidden: false,
          noSearch: false,
          alignment: "right",
          pipe: true
        },
        {
          key: 'REMARKS',
          title: 'REMARKS',
          hidden: false,
          noSearch: false
        },
      ]

      };
    }

    if(VoucherPrefix == "TO"){

      return{
        title: 'Vouchers',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'VCHRNO',
            title: 'VOUCHER NO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false,
            pipe:true
            
          },
          {
            key: 'STATUS',
            title: 'Sync Status',
            hidden: false,
            noSearch:false,
          },
          {
            key: 'BILLTONAME',
            title: 'FROM DIV',
            hidden: false,
            noSearch: false
          },
          {
            key: 'BILLTOADDNAME',
            title: 'TO DIV',
            hidden: false,
            noSearch: false
          },
          {
            key: 'REMARKS',
            title: 'REMARKS',
            hidden: false,
            noSearch: false
          },
        ]
      }
    }

    if(VoucherPrefix == "RQ"){
      return {
        title: 'Vouchers',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'VCHRNO',
            title: 'VOUCHER NO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false
          },
          {
            key: 'STATUS',
            title: 'Sync Status',
            hidden: false,
            noSearch:false,
          },
          {
            key: 'REMARKS',
            title: 'REMARKS',
            hidden: false,
            noSearch: false
          },
        ]
      };
    }

    if(VoucherPrefix == "QT"){

      return{
        title: 'Vouchers',
        apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'TRNDATE',
            title: 'DATE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'VCHRNO',
            title: 'VOUCHER NO.',
            hidden: false,
            noSearch: false
          },
          {
            key: 'NETAMNT',
            title: 'AMOUNT',
            hidden: false,
            noSearch: false,
            alignment: "right",
            pipe: true
          },
          {
            key: 'BILLTO',
            title: 'CUSTOMER NAME',
            hidden: false,
            noSearch : false
          }
        ]
      }
    }

    if (VoucherPrefix === 'ParentItemsList') {
      return {
        title: "Parent Products",
        apiEndpoints: `/getParentItemListPagedList`,
        defaultFilterIndex: 2,
        columns: [
          {
            key: "MCODE",
            title: "CODE",
            hidden: true,
            noSearch: true
          },
          {
            key: "MENUCODE",
            title: "CODE",
            hidden: false,
            noSearch: false
          },
          {
            key: "DESCA",
            title: "DESCRIPTION",
            hidden: false,
            noSearch: false
          }
        ]
      };

    }

    if (VoucherPrefix == "AccountLedgerList") {
      return {
        title: "Accounts",
        apiEndpoints: `/getAccountPagedListByMapId/Details/Party Payment Expense`,
        defaultFilterIndex: 1,
        columns: [
          {
            key: 'ACID',
            title: 'AC CODE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'ACNAME',
            title: 'A/C NAME',
            hidden: false,
            noSearch: false
          },
        ]
      };
    }

    if (VoucherPrefix == "BilledCustomerList") { 
      return {
        title: "Customers",
        apiEndpoints: `/getBilledCustomerPagedList`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: "ACNAME",
            title: "NAME",
            hidden: false,
            noSearch: false
          },
          {
            key: "customerID",
            title: "CODE",
            hidden: false,
            noSearch: false
          },
          {
            key: "ADDRESS",
            title: "ADDRESS",
            hidden: false,
            noSearch: false
          },
          {
            key: "EMAIL",
            title: "EMAIL",
            hidden: false,
            noSearch: false
          }
        ]
      };
    }
 
    if (VoucherPrefix === 'CustomerCategoryList') {
      return {
        title: 'Category',
      apiEndpoints: `/getCustomerCategoryList`,
      defaultFilterIndex: 0,
      columns: [
        {
          key: 'ORGTYPENAME',
          title: 'Category Name',
          hidden: false,
          noSearch: false
        }
      ]
      };

    }

    if(VoucherPrefix === 'GroupsList'){
      return {
        title: 'Group List',
        apiEndpoints: `/getMainGroupListforExcelExport`,
        defaultFilterIndex: 0,
        columns: [
          {
            key: 'MENUCODE',
            title: 'CODE',
            hidden: false,
            noSearch: false
          },
          {
            key: 'DESCA',
            title: 'GROUP',
            hidden: false,
            noSearch: false
          }
        ]
      };
    }

    
    if (VoucherPrefix === 'CustomerGroupList') {
      return {
        title: 'Group',
      apiEndpoints: `/getCustomerGroupList`,
      defaultFilterIndex: 0,
      columns: [
        {
          key: 'ACID',
          title: 'ACID',
          hidden: false,
          noSearch: false
        },
        {
          key: 'ACNAME',
          title: 'NAME',
          hidden: false,
          noSearch: false
        }
      ]
      };

    }


    return {
      title: 'Vouchers',
      apiEndpoints: `/getTrnMainPagedList/${VoucherPrefix}/tag`,
      defaultFilterIndex: 1,
      columns: [
        {
          key: 'TRNDATE',
          title: 'DATE',
          hidden: false,
          noSearch: false
        },
        {
          key: 'VCHRNO',
          title: 'VOUCHER NO.',
          hidden: false,
          noSearch: false
        },
        {
          key: 'NETAMNT',
          title: 'AMOUNT',
          hidden: false,
          noSearch: false
        },
        {
          key: 'REMARKS',
          title: 'REMARKS',
          hidden: false,
          noSearch: false
        },
      ]
    };

  }


  getGenericGridSettintForSalemanRoute(route) {
    return {
      title: 'Route',
      apiEndpoints: `/getPagedRouteListBySalesman/${route}`,
      defaultFilterIndex: 1,
      columns: [
        {
          key: 'RouteCode',
          title: 'RouteCode',
          hidden: false,
          noSearch: false
        },
        {
          key: 'RouteName',
          title: 'RouteName',
          hidden: false,
          noSearch: false
        },

      ]
    };

  }



  getGridSettingForCustomerBySalesmanRoute(salemanId, route) {
    return {
      title: 'Customers',
      apiEndpoints: `/getPagedCustomerListBySalesmanAndRoute/${salemanId}/${route}`,
      defaultFilterIndex: 0,
      useDefinefilterValue: salemanId,
      columns: [
        {
          key: 'ACNAME',
          title: 'NAME',
          hidden: false,
          noSearch: false
        },
        {
          key: 'ACCODE',
          title: 'CODE',
          hidden: false,
          noSearch: false
        },
        {
          key: 'ADDRESS',
          title: 'ADDRESS',
          hidden: false,
          noSearch: false
        },
        {
          key: 'EMAIL',
          title: 'EMAIL',
          hidden: false,
          noSearch: false
        }
      ]
    };
  }

  ValidateDate(date: string) {
    const dateFieldArray = date.split('/');
    const year = parseInt(dateFieldArray[2]);
    const month = parseInt(dateFieldArray[1]) - 1;
    const day = parseInt(dateFieldArray[0]);
    const d = new Date(year, month, day);
    if (d.getFullYear() === year && d.getMonth() === month && d.getDate() === day) {
      return true;
    }
    return false;
  } changeIMsDateToDate(date) {
    const dateFieldArray = date.split('/');
    const year = dateFieldArray[2];
    const month = dateFieldArray[1];
    const day = dateFieldArray[0];
    const d = `${year}-${month}-${day}`
    return d;
  }


  customDateFormate(date: string) {
    const dateFieldArray = date.split('-');
    const year = dateFieldArray[0];
    const month = dateFieldArray[1];
    const day = dateFieldArray[2];
    const d = `${day}/${month}/${year}`;
    return d;
  }



  public getCurrencies() {
    return this.http
      .get(this.apiUrl + '/getCurrencyList')
  }

  public getPurchaseAcList() {
    return this.http
      .get(this.apiUrl + '/getPurchaseAcList')
  }
  public markAsRead(id: number) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    this.http
      .get(`${this.apiUrl}/notificationMarkAsRead/${id}`)
      .subscribe(
        retData => {
          if (retData['status'] === 'ok') {
            res.status = 'ok';
            res.result = retData['result'];
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = retData['result'];
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }



  public getCashList() {
    return this.getAccount('masterrepo-cashlist').map((data: TAcList[]) => {
      const lst = data.filter(
        fil => fil?.ACID?.substr(0, 2) === 'AT' && fil.MAPID === 'C'
      );
      return lst;
    });
  }

  public getAccount(calledFrom: string) {
    if (this._account.length > 0) {
      return of(this._account);
    } else if (this.getAccountObservable) {
      return this.getAccountObservable;
    } else {
      const aList: TAcList[] = [];
      this.getAccountObservable = this.http
        .get(this.apiUrl + '/getAccountList')
        .pipe(map(data => {
          this.getAccountObservable = null;
          aList.push(data as TAcList);
          this._account = aList;
          return aList.filter(x => x.TYPE === 'A');
        }))
      return this.getAccountObservable;
    }
  }



  loadSalesInvoiceFromSupplierHO(voucerNo: string, FROMCOMPANYID: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/getHOTaxInvoiceDetail?voucherNo=${voucerNo}&fromcompanyid=${FROMCOMPANYID}`)
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }



  loadSAPPurchaseInvoiceDetail(voucerNo: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/getSAPPurchaseInvoiceDetail?voucherNo=${voucerNo}`)
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  loadHoPerformaInvoice(voucerNo: string, fromCompany: string = null) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/getHOPerformaInvoice?voucherNo=${voucerNo}`)
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  loadHoPurchaseOrder(voucerNo: string, FromCompanyid: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/getHOPurchaseOrder?voucherNo=${voucerNo}&fromcompanyid=${FromCompanyid}`)
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {

          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  loadHoPurchaseOrderFromMobile(orderid: string, orderfrom: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/getPurchaseOrderFromMobileApi?orderid=${orderid}&orderfrom=${orderfrom}`)
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  loadPurchaseOrderForPOCancel(voucerNo: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/getPurchaseOrderForPOCancel?voucherNo=${voucerNo}`)
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }


  public getAllCategory(refresh: boolean = false) {

    return this.http
      .get(this.apiUrl + '/getAllCategoryList')
  }


  public getAllCat() {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(this.apiUrl + '/getAllCategoryList')
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }


  loadSalesInvoiceFromSalesOrder(voucerNo: string) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/GetSODetailForSaleInvoice?voucherNo=${voucerNo}`)
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  // Cost Center
  public getCostCenter(refresh: boolean = false) {
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    return this.http
      .get(this.apiUrl + '/getCostCenterList', options)
  }

  public saveCostCenter(mode: string, costCenter: any) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    let bodyData = { mode: mode, data: costCenter };
    this.http
      .post(this.apiUrl + '/saveCostCenter', bodyData, options)
      .subscribe(
        (data: any) => {
          if (data.status === 'ok') {
            res.status = 'ok';
            res.result = data.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = data.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  private _brandTree: any[] = [];
  getBrandTreeObservable: Observable<any[]>;

  public getProductGroupTree() {
      if (this._brandTree.length > 0) {
        return of(this._brandTree);
      } else if (this.getBrandTreeObservable) {
        return this.getBrandTreeObservable;
      } else {
        this.getBrandTreeObservable = null;
        var atList: Product[] = [];
        this.getBrandTreeObservable = this.http
          .get(this.apiUrl + "/getPartyListTree").pipe(
                map(data => {
                  this.getBrandTreeObservable = null;
                  atList.push(<Product>data);
                  this._brandTree = atList;
                  return atList;
                }),
                // share()
          )         
        return this.getBrandTreeObservable;
      }
    }
  // // Channel
  // getDataForExcel(from) {
  //   return this.http.get(`${this.apiUrl}/getMenuItemForExcel?from=${from}`, this.authService.getRequestOption()).map(response => response.json() || []);
  // }
  // Sales Invoice

  public getSalesmanList(refresh: boolean = false) {
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    return this.http
      .get(this.apiUrl + '/getSalesmanList', options)
  }

  public getloginDivisionName(refresh: boolean = false) {
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    return this.http
      .get(this.apiUrl + '/getUserLoginDivision', options)
  }


  public saveCustomerProfile(savedata: any,mode:string) {
    let bodyData = {mode:mode, data: savedata };
    console.log("datanew",bodyData);
    return this.http
      .post(this.apiUrl + '/SaveCustomerProfile', bodyData)
  }

  public postMembershipGroup(value){
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    return this.http.post(this.apiUrl+'/saveMembership', value, options)
  }

  getCustomerByACID(acid:string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(this.apiUrl + `/getCustomerbyCustomerID?acid=${acid}`).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  getMgroupandParentData(flag: any){
    return this.http.get(`${this.apiUrl}/getAllMGroupAndParentInSchemeMaster?tag=${flag}`);
  }

  checkBarcodeTransaction(barCode:string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
      this.http.get(this.apiUrl + '/checkBarCodeTransaction/'+ barCode).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    }

    loadItemInfo(mcode: string, barcode:string, warehouse: string) {
      console.log(mcode, barcode);
      let res = { status: "error", result: "" };
      let returnSubject: Subject<any> = new Subject();
        this.http.get(this.apiUrl + `/getItemLocationInfo?mcode=${mcode}&barcode=${barcode}&warehouse=${warehouse}`).subscribe(response => {
          let data = response;
          if (data['status'] == 'ok') {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          }
          else {
            returnSubject.next(data)
            returnSubject.unsubscribe();
          }
        }, error => {
          res.status = 'error'; res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
        );
        return returnSubject;
      }







  public getAllInvoiceData(printParams) {
   // const options = { headers: this.getRequestOptionForPdfFile(), method: 'post' };
    const options = { headers: this.authService.getRequestOptionForPdfFile(), method: 'post' };
    
      if(printParams.VoucherPrefix == 'TI' && printParams.title == 'Tax Invoice'){
        let bodyData = {
          "filename": printParams.filename,
          "Parameters": [{"voucherno":printParams.VCHRNO, "rowsnumber":printParams.rownumber,"companyname":printParams.NAME, "companyaddress":printParams.ADDRESS , 
                      "division":printParams.INITIAL,"phiscalid":printParams.PhiscalID,"companyid":printParams.COMPANYID,"numtowords":printParams.numtowords,
                      "p1":printParams.panno1,"p2":printParams.panno2,"p3":printParams.panno3,"p4":printParams.panno4,"p5":printParams.panno5,"p6":printParams.panno6,"p7":printParams.panno7,"p8":printParams.panno8,"p9":printParams.panno9,
                      "title":printParams.title ,"caption": printParams.caption,
                    "phone1":printParams.phone1,"phone2":printParams.phone2,"email":printParams.EMAIL,"vchrno":printParams.VCHRNO,"PRINTBY":printParams.PRINTBY,"companyemail":printParams.EMAIL,"companyvat":printParams.COMPANYVAT},
                    {"voucherno":printParams.VCHRNO, "rowsnumber":printParams.rownumber,"companyname":printParams.NAME, "companyaddress":printParams.ADDRESS , 
                      "division":printParams.INITIAL,"phiscalid":printParams.PhiscalID,"companyid":printParams.COMPANYID,"numtowords":printParams.numtowords,
                      "p1":printParams.panno1,"p2":printParams.panno2,"p3":printParams.panno3,"p4":printParams.panno4,"p5":printParams.panno5,"p6":printParams.panno6,"p7":printParams.panno7,"p8":printParams.panno8,"p9":printParams.panno9,
                      "title":'Invoice' ,"caption": ' ',
                    "phone1":printParams.phone1,"phone2":printParams.phone2,"email":printParams.EMAIL,"vchrno":printParams.VCHRNO,"PRINTBY":printParams.PRINTBY,"companyemail":printParams.EMAIL,"companyvat":printParams.COMPANYVAT},
                  ],
          "IsSameDataQuery": true
        };
        return this.http
        .post(`${this.apiUrl}/MultiparameterPdf`, bodyData,this.getRequestOptionForPdfFile())
      }else{
        let bodyData = {
          "filename": printParams.filename,
          "Parameter": {"voucherno":printParams.VCHRNO, "rowsnumber":printParams.rownumber,"companyname":printParams.NAME, "companyaddress":printParams.ADDRESS , 
                      "division":printParams.INITIAL,"phiscalid":printParams.PhiscalID,"companyid":printParams.COMPANYID,"numtowords":printParams.numtowords,
                      "p1":printParams.panno1,"p2":printParams.panno2,"p3":printParams.panno3,"p4":printParams.panno4,"p5":printParams.panno5,"p6":printParams.panno6,"p7":printParams.panno7,"p8":printParams.panno8,"p9":printParams.panno9,
                      "title":printParams.title ,"caption": printParams.caption,
                    "phone1":printParams.phone1,"phone2":printParams.phone2,"email":printParams.EMAIL,"vchrno":printParams.VCHRNO,"PRINTBY":printParams.PRINTBY,"companyemail":printParams.EMAIL,"companyvat":printParams.COMPANYVAT
                  
                  }
        };
        return this.http
        .post(`${this.apiUrl}/Pdf`, bodyData,this.getRequestOptionForPdfFile())
      }
 

    
  }

  getRequestOptionForPdfFile() {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(
        {          
          Authorization: this.authService.getAuth().token
      }
      )
    };
    return httpOptions;
  }

  public getPrintFileName(voucherprefix: string,companyid:string,billformats:string) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    let bodyData = { voucherprefix: voucherprefix, companyid: companyid,billformats:billformats };
    this.http
      .post(this.apiUrl + '/getPrintFileName', bodyData, options)
      .subscribe(
        (data: any) => {
          if (data.status === 'ok') {
            res.status = 'ok';
            res.result = data.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = data.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  public getNumberToWords(vchrno,division,phiscalid,companyid) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    let bodyData = { vchrno : vchrno,division : division,phiscalid : phiscalid,companyid : companyid };
    this.http
      .post(this.apiUrl + '/getNumberToWords', bodyData, options)
      .subscribe(
        (data: any) => {
          if (data.status === 'ok') {
            res.status = 'ok';
            res.result = data.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = data.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  getPartyGroupForQuickPartyMaster() {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getPartyGroupForQuickPartyMaster`).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  getGeoList() {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getGeoList`).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  
  public savePartyMaster(mode: string, Account: any,createMember, PartyAdditional:any={}) {
    let bodyData = { mode: mode, data: Account,PartyAdditional : PartyAdditional,createMember:createMember};
    return this.http
      .post(this.apiUrl + '/saveQuickAccount', bodyData)
  }

  getAreaDetail() {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getAreaDetail`).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }

  public getDetailsOfAccount(ACNAME: string) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    let bodyData = { ACNAME: ACNAME };
    this.http
      .post(this.apiUrl + '/getDetailsOfAccount', bodyData, options)
      .subscribe(
        (data: any) => {
          if (data.status === 'ok') {
            res.status = 'ok';
            res.result = data.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = 'error1';
            res.result = data.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = 'error2'), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  getCostCenterList() {
    return this.http.get(`${this.apiUrl}/getCostCenterList`);
  }
 
  getExcelReport(reportData:any){

    const type = 'application/vnd.ms-excel';
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
         
    return this.http
      .post(this.apiUrl + `/loadExcelReports/`,reportData, options,)
      .map((response: Response) => {
        let data = {
          content: new Blob([(<any>response)._body], {
            type: response.headers.get("Content-Type")
          }),
          filename: `${reportData.reportname}.xlsx`
        };
        return data;
      });

  }


  DownLoadAllExcelReportPreparedFromAPI(reportData:any){

      return this.http
      .post(this.apiUrl + `/downLoadAllReportExcel`,reportData, {responseType: 'blob'});
  }

  DownLoadAllPdfReportPreparedFromAPI(reportData:any){
      console.log("pdf download");
      return this.http
      .post(this.apiUrl + `/downLoadAllReportPdf`,reportData, {responseType: 'blob'});
  }
  
  downloadExcelReport(){
    return this.http
    .get(this.apiUrl + `/getMembershipExcelFile`, {responseType: 'blob'});
  }

  resetExcelColumnFormat(value){
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = {ReportName:value }
    let options  = {headers : this.authService.getRequestOption(),method:'post'}
    this.http.post(`${this.apiUrl}/resetExcelColumnFormat`,bodyData,options)
        .subscribe((data:any) => {
           
            if (data.status == 'ok') {
                returnSubject.next(data);
                returnSubject.unsubscribe();
  
            }
            else {
                returnSubject.next(data)
                returnSubject.unsubscribe();
            }
        }, error => {
            res.status = 'error'; res.result = error;
            returnSubject.next(res);
            returnSubject.unsubscribe();
        }
        );
    return returnSubject;
  }

  
  getAccountListACIDNotLikePA() {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getAccountLedgerForReport`)
      .subscribe((response:any) => {
        let data = response.json();
        if (data.status == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }

  getAccountListACIDLikePA() {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getPartyLedgerForReport`)
      .subscribe(
        (response:any) => {
        let data = response.json();
        if (data.status == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }
  getCostcenter() {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getAllCostCenterList`)
      .subscribe((response:any) => {
        let data = response.json();
        if (data.status == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }
  getReportTitle(reportName: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getReportTitle/${reportName}`)
      .subscribe((response:any) => {
        let data = response.json();
        if (data.status == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }

  
  public getItemGroup() {
    return this.http
      .get(this.apiUrl + '/getItemGroup')
  }

  getAreaGroup() {
    return this.http
    .get(this.apiUrl + '/getAreaMasterList');
    }

    public getAllWarehouseList() {
      return this.http
        .get<any[]>(this.apiUrl + "/getAllWarehouseList")
    }

    deleteWarehouse(NAME: string) {
      let res = { status: 'error', result: '' };
      let returnSubject: Subject<any> = new Subject();
      let bodyData = { mode: 'query', data: { NAME: NAME } };
      const options = { headers: this.authService.getRequestOption(), method: 'post' };
      this.http.post(this.apiUrl + '/deleteWarehouse', bodyData, options)
        .subscribe((data: any) => {
          if (data.status === 'ok') {
            returnSubject.next(data);
            returnSubject.unsubscribe();
    
          }
          else {
            returnSubject.next(data)
            returnSubject.unsubscribe();
          }
        }, error => {
          res.status = 'error'; res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
        );
      return returnSubject;
      /* return this.http.get("/rategroups.json").toPromise()
           .then((res) => res.json());*/    
    }
    getCustomerIDFromCProfile(acid:string) {
      let res = { status: "error", result: "" };
      let returnSubject: Subject<any> = new Subject();
      this.http.get(this.apiUrl + '/getCustomerIDFromCProfile/' + acid).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    }
    
    getMemershipFromCustID(acid:string) {
      let res = { status: "error", result: "" };
      let returnSubject: Subject<any> = new Subject();
      this.http.get(this.apiUrl + '/getMemershipFromCustID/' + acid).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    }


    getMemershipFromMemberNo(MEMID:string) {
      let res = { status: "error", result: "" };
      let returnSubject: Subject<any> = new Subject();
      this.http.get(this.apiUrl + '/getMemershipFromMemberNo/' + MEMID).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    }



    postMemberPointCalculation(value){
      let saveData = {mode:"add",data:value};
      const options = { headers: this.authService.getRequestOption(), method: 'post' };
      return this.http.post(`${this.apiUrl}/calculateMembersPoint`, saveData, options);
    }

    public getCostCenterName(CCID: string) {
      let bodyData = { CCID: CCID};
      return this.http
        .post(this.apiUrl + '/getCostCenterName', bodyData)
    }

    public getItemGroupName(Mgroup: string) {
      let bodyData = { Mgroup: Mgroup};
      return this.http
        .post(this.apiUrl + '/getItemGroupName', bodyData)
    }

    public getItemTypeName(ptype: string) {
      let bodyData = { ptype: ptype};
      return this.http
        .post(this.apiUrl + '/getItemTypeName', bodyData)
    }

    public getBillFormat(voucherno: string) {
      let bodyData = { voucherno: voucherno};
      return this.http
        .post(this.apiUrl + '/getBillFormat', bodyData)
    }


    getProductCustomerHistory(mobileNo: string) {
      let res = { status: "error", result: "" };
      let returnSubject: Subject<any> = new Subject();
      let bodyData = { MOBILENO: mobileNo };
      this.http.post(this.apiUrl + '/getProductCustomerHistory', bodyData).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    }

    checkDuplicateReverseEntry(RefBill: string) {
      let res = { status: "error", result: "" };
      let returnSubject: Subject<any> = new Subject();
      let bodyData = { REFBILL: RefBill };
      this.http.post(this.apiUrl + '/checkDuplicateReverseEntry', bodyData).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    }

    updateBillFormats(value: string) {
      let res = { status: 'error', result: '' };
      let returnSubject: Subject<any> = new Subject();
      let bodyData = { value: value  };
      const options = { headers: this.authService.getRequestOption(), method: 'post' };
      this.http.post(this.apiUrl + '/updateBillFormats', bodyData, options)
        .subscribe((data: any) => {
          if (data.status === 'ok') {
            returnSubject.next(data);
            returnSubject.unsubscribe();
          }
          else {
            returnSubject.next(data)
            returnSubject.unsubscribe();
          }
        }, error => {
          res.status = 'error'; res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
        );
      return returnSubject;
    }

    
    private getRequestOptionWithoutContent() {
     const headers = new HttpHeaders({
        Authorization: this.authService.getAuth().token
      });
      return headers;
    }

    importConfig(model: any) {
      let res = { status: "error", result: "error" };
      let returnSubject: Subject<any> = new Subject();
      const options = {headers:this.getRequestOptionWithoutContent(), method:'post'}
  
      this.http
        .post(
          this.apiUrl + `/uploadFileMasterAnonymous`,
          model,
           options
        )
        .subscribe(
          response => {
            let data : any = response;
            if (data.status == "ok") {

              returnSubject.next(data);
              returnSubject.unsubscribe();
            } else {
              returnSubject.next(data);
              returnSubject.unsubscribe();
            }
          },
          error => {
            res.status = "error";
            res.result = error;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        );
      return returnSubject;
    }

    postWarrantyImg(data){
      const options = {headers: this.authService.getRequestOption(), method:'post'}
      return this.http.post(this.apiUrl+`/uploadWarrantyImg`,data,options);
    }


    public getNotLoginDivisionList() {
      return this.http
        .get(this.apiUrl + '/getNotLoginDivisionList')
    }

    public getPaymentTermList() {
      return this.http
        .get(this.apiUrl + '/getPaymentTermList')
    }


    getUsersFromLog() {
      return this.http
        .get(this.apiUrl + '/getUsersFromLog')
      
    }
    

    getFormNameFromLog(userID){
      return this.http
      .get(`${this.apiUrl}/getFormNameFromLog?userID=${userID}`)
      
        }
    
    getHostNameFromLog(userID){

      return this.http
      .get(`${this.apiUrl}/getHostNameFromLog?userID=${userID}`)
      
    }
    
    getActionFromLog(userID){


      return this.http
      .get(`${this.apiUrl}/getActionFromLog?userID=${userID}`)
    }

   getAllExcelImportMasterList(refresh: boolean = false) {

    return this.http.get(this.apiUrl + '/getExcelConfigMasterList')
     
  }

  public getAllScheme() {
    return this.http
      .get(this.apiUrl + "/GetAllScheme")
      
  }

  public getRoleUserRights() {
    return this.http.get(this.apiUrl + "/getRoleUserRights")
  }


  discountAuthorization(disAuth: any){
    let res = {status: "error", result:""};
    let returnSubject: Subject<any> = new Subject();
    return this.http.post(this.apiUrl+"/discountAuthorization",disAuth);
  }

  NegativeBillingAuth(authObj:any){
    let res={status:"error", result: ""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}
  
    this.http.post(this.apiUrl + "/authorizeNegativeBillingRight", authObj,options).subscribe(
      (data:any) =>{
        let retData = data;
        if (retData.status == "ok") {
          res.status = "ok";
          res.result = retData.result;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        } else {
          res.status = retData.status;
          res.result = retData.result;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      },error =>{
        (res.status = "error"), (res.result = error);
                returnSubject.next(res);
                returnSubject.unsubscribe();
      });
      return returnSubject;
  }

  reverseEntryAutorisation(reverseAuth:any){
    return this.http.post(this.apiUrl+"/authorizeReverseEntry",reverseAuth);
  }
  
  groupBy(array,f){
    let groups = {};
    array.forEach(function (o){
      var group = JSON.stringify(f(o));
      console.log("group by", group);
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    
    return Object.keys(groups).map(function (group){
      if(groups[group].length >1){

      
        return groups[group];
      }else{
      return groups[group];
      }
    });
  }

  getMasterSchemeList(){
    return this.http
    .get<any[]>(this.apiUrl + '/getMasterSchemeList')
  }
  
  getSchemeByID(Initial: string, type:string){
    let bodyData = {mode: 'query',data:{schemeID: Initial, schemeType : type}};
    console.log("bodydate before getting scheme", bodyData);
    return this.http.post(this.apiUrl+'/getSchemeByID',bodyData);
  }
  
  deleteScheme(schemeID: string, schemeType: string){
    let res = {status:"error", result: ''};
    let returnSubject: Subject<any> = new Subject();
    let bodyData = {mode:'query', data:{DisID: schemeID, SchemeType: schemeType}};
    this.http.post(this.apiUrl+'/deleteScheme', bodyData)
    .subscribe((data:any)=>{
      if(data.status === 'ok'){
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
    }, error =>{
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    });
    return returnSubject;
  }
 

  syncBranchTransferOut(VCHR,DIVISION,PhiscalID) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    let bodyData = {
      data:{
        VCHRNO: VCHR,
         DIVISION: DIVISION,
          PhiscalId: PhiscalID
       }, mode:null
       };
    const options = { headers: this.authService.getRequestOption(), method: 'post' };
    this.http.post(this.apiUrl + '/syncBranchTransferout', bodyData, options)
      .subscribe((data: any) => {
        if (data.status === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }

  getExcelFile(mode: string,exportData: any) {

    let bodyData = {
      AppMode: mode,
      McodeList : exportData
    }
    return this.http
      .post(this.apiUrl + `/getProductListByFilter`,bodyData, {responseType: 'blob'});
  }

  getExcelFileForBA(){
    return this.http
    .get(this.apiUrl + `/downloadNegativeStockDetails`, {responseType: 'blob'});
  }

  validateProduct( mode: string,prodObj: any,groupType:any) {
      let bodyData = {
        mode: mode,
        data: {product: prodObj,},
        groupType:groupType
      };
    return this.http
      .post(this.apiUrl + `/validateProduct`,bodyData);
  }

  getUsername(){
    return this.http
    .get<any[]>(this.apiUrl + '/getUsernameList')
  }
  //added by roshan for getting only user wise division list
  public getDivisionByUser(user){
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getDivisionByUser?USER=${user}`)
        .subscribe(response => {
            let data = response;
            if (data['status'] == 'ok') {
                returnSubject.next(data);
                returnSubject.unsubscribe();
  
            }
            else {
                returnSubject.next(data)
                returnSubject.unsubscribe();
            }
        }, error => {
            res.status = 'error'; res.result = error;
            returnSubject.next(res);
            returnSubject.unsubscribe();
        }
        ); 
    return returnSubject;
  }

  public getVariantConfiguration(){
    this.masterPostmethod("/getcategorywiseconfiguration",{}).subscribe((res)=>{
      console.log("resultconfiguration",res);
      if(res.result && res.result?.length){
        this.variantmaster = res.result;
        
      }else{
        this.variantmaster = [];
      }
    },error =>{
      this.variantmaster = [];
    });
  }




  public saveImportDetail(bodyData){
    let res={status:"error", result:""};
    let returnSubject: Subject<any> = new Subject();

    this.http.post(this.apiUrl+'/SaveImportDetails',bodyData)
    .subscribe(response =>{
      let data= response;
      if(data['status'] == 'ok'){
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else{
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
    },error =>{
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    });
    return returnSubject;
  }

  checkUserRights(value, urlpath):boolean{
    
    const right = this.userProfile.menuRights.find( x => x.menu == urlpath);
    

    if(right){
      let userrights = right.right;
      
      let xyz ; let allow:boolean = true;
      switch(value){
        case 'add':
          xyz = userrights.find( x=> x =='add');
          if(xyz == undefined){
            allow = false;
          }
          break;

        case 'edit':
          xyz = userrights.find( x => x == "edit");
          if(xyz == undefined){
            allow = false;
          }
          break;
        
        case 'delete':
          xyz = userrights.find( x => x == "delete");
          if(xyz == undefined){
            allow = false;
          }
          break;

        case 'print':
          xyz = userrights.find( x => x == "print");
          if(xyz == undefined){
            allow = false;
          }
          break;

        case 'view':
          xyz = userrights.find( x => x == "view");
          if(xyz == undefined){
            allow = false;
          }
          break;

        default:
          break;
      }
      // console.log("allowedd?", allow);
      if(allow == false){
        this.alertService.warning("Current user has no rights to "+value);
        return false;
      }else{
        return true;
      }
    }
    

  
  }
  

  checkIsBranch():boolean{
    if(this.userProfile?.ISMAIN === 0){
      return false;
    }
    return true;
  }

  public getUnsaleableWarehouseList() {
    return this.http
      .get<any[]>(this.apiUrl + "/getUnsaleableWarehouseList")
  }

  loadReverseInvoice(voucerNo: string, fromCompany: string = null) {
    const res = { status: 'error', result: '' };
    const returnSubject: Subject<any> = new Subject();
    this.http
      .get(
        `${this.apiUrl}/getReverseInvoice?voucherNo=${voucerNo}`)
      .subscribe(
        (response: any) => {
          if (response.status === 'ok') {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          } else {
            returnSubject.next(response);
            returnSubject.unsubscribe();
          }
        },
        error => {
          res.status = 'error';
          res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }
  public getRawDataReportList() {
    return this.http
      .get<any[]>(this.apiUrl + "/getRawDataReportList")
  }


  getRecurringCategoryList(){
    return this.http.get(this.apiUrl + "/getRecurringItemCatList");
  }

  getRecurringCostcenterList(customerID:string) {
    return this.http.get(this.apiUrl+"/getRecurringCostcenterList/"+customerID);

  }

   public getRecurringItemDetails(recurringItemObj:any){
    console.log("reucrrrr", recurringItemObj);
    const returnSubject: Subject<any> = new Subject();
    this.http
      .post(this.apiUrl + '/getRecurringItemDetail', recurringItemObj)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
        } else {
          returnSubject.next(data);
        }
      });
    return returnSubject;
  };

  getRecurringModeList(){
    return this.http.get(this.apiUrl+"/getRecurringModeList");
  }

  getLastRecurringDate(mcode,trnac){
    return this.http.get(this.apiUrl+ "/getLastRecurringDate/"+mcode+"/"+trnac); 
  }

  public saveFiscalYear(isCarriedOn:number) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    let opt = this.getRequestOption();
    const options = {headers:this.getRequestOption(), method:'post'}
    let hd: Headers = new Headers({ "Content-Type": "application/json" });
    
    this.http
      .get(this.apiUrl + `/yearEndingInventory?isCarriedOn=${isCarriedOn}`)
      .subscribe(
        (data:any) => {
         let retData = data;
          //    console.log(retData);
          if (retData.status == "ok") {
            res.status = "ok";
            res.result = retData.result;
            // console.log(res);
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          // console.log(res);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      );
    return returnSubject;
  }

  public saveContractPrice(Mode:string, contractpriceObj:any){
    let res= { status:"error", result:""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}

    let bodyData = {
        mode:Mode,
        data:contractpriceObj
    };

    this.http.post(this.apiUrl+"/saveContractPricing",bodyData,options)
    .subscribe(
        (data: any) => {
            let retData = data;
            if (retData.status == "ok") {
              res.status = "ok";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            } else {
              res.status = "error1";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            }
          },
          error => {
            (res.status = "error2"), (res.result = error);
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
    );
    return returnSubject;
}

public saveJobCardRegistration(Mode: string, jobCardObj: any){
  let res= { status:"error", result:""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}

    let bodyData = {
        mode:Mode,
        data: jobCardObj
    };

    this.http.post(this.apiUrl+"/saveJobCardMaster",bodyData,options)
    .subscribe(
        (data: any) => {
            let retData = data;
            if (retData.status == "ok") {
              res.status = "ok";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            } else {
              res.status = "error1";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            }
          },
          error => {
            (res.status = "error2"), (res.result = error);
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
    );
    return returnSubject;
}

  public updateRecurringLog(recurringList:any){
    let res= { status:"error", result:""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}

    this.http.post(this.apiUrl+"/RecurringLogUpdate",recurringList,options)
    .subscribe(
        (data: any) => {
            let retData = data;
            if (retData.status == "ok") {
              res.status = "ok";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            } else {
              res.status = "error1";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            }
          },
          error => {
            (res.status = "error2"), (res.result = error);
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
    );
    return returnSubject;
}

  public holdRecurringItem(recurringLogObj: any){
    let res= { status:"error", result:""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}

    this.http.post(this.apiUrl+"/HoldRecurringLog",recurringLogObj,options)
    .subscribe(
        (data: any) => {
            let retData = data;
            if (retData.status == "ok") {
              res.status = "ok";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            } else {
              res.status = "error1";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            }
          },
          error => {
            (res.status = "error2"), (res.result = error);
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
    );
    return returnSubject;
  }

  public unHoldRecurringItem(recurringLogObj: any){
    let res= { status:"error", result:""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}

    this.http.post(this.apiUrl+"/unholdRecurringLog",recurringLogObj,options)
    .subscribe(
        (data: any) => {
            let retData = data;
            if (retData.status == "ok") {
              res.status = "ok";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            } else {
              res.status = "error1";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            }
          },
          error => {
            (res.status = "error2"), (res.result = error);
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
    );
    return returnSubject;
  }

  getAllListforJobCard(currentPage: number, maxResultCount: number){
    return this.http.get(`${this.apiUrl}/getJobCardPagedList?currentPage=${currentPage}&maxResultCount=${maxResultCount}`);
  }

  public updateItemWiseContract(Mode:string, contractpriceObj:any){
    let res= { status:"error", result:""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}

    let bodyData = {
        mode:Mode,
        data:contractpriceObj
    };

    this.http.post(this.apiUrl+"/updateItemWiseContract",bodyData,options)
    .subscribe(
        (data: any) => {
            let retData = data;
            if (retData.status == "ok") {
              res.status = "ok";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            } else {
              res.status = "error1";
              res.result = retData.result;
              returnSubject.next(res);
              returnSubject.unsubscribe();
            }
          },
          error => {
            (res.status = "error2"), (res.result = error);
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
    );
    return returnSubject;
}

public loadContractPriceDetails(CMID:number, CUSTID:string){
  let res = {status: "error", result: ""};
  let returnSubject: Subject<any> = new Subject();
  
  this.http.get(this.apiUrl + "/loadContractPriceDetails/"+CMID+"/"+CUSTID)
  .subscribe(
    (data:any) => {
      let retdata = data;
      if(retdata.status == "ok"){
        res.status = "ok";
        res.result = retdata.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      else{
        res.status = "error1";
        res.result = retdata.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    },
    error =>{
     res.status = 'error';
     res.result = error;
     returnSubject.next(res);
     returnSubject.unsubscribe();
    }
  );
  return returnSubject;

  
}

public getRecurringLogDetails(ENTRYNO: string, MODE: string){
 return this.http.get(`${this.apiUrl}/getRecurringLogDetailsForEdit/?ENTRYNO=${ENTRYNO}&MODE=${MODE}`)
}

public getDuplicateCustomer(CUSTID:string){
  let res = {status: "error", result: ""};
  let returnSubject: Subject<any> = new Subject();
  
  this.http.get(this.apiUrl + "/getDuplicateCustomer/"+CUSTID)
  .subscribe(
    (data:any) => {
      let retdata = data;
      if(retdata.status == "ok"){
        res.status = "ok";
        res.result = retdata.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      else{
        res.status = "error1";
        res.result = retdata.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    },
    error =>{
     res.status = 'error';
     res.result = error;
     returnSubject.next(res);
     returnSubject.unsubscribe();
    }
  );
  return returnSubject;
}

  
public getSettings(){
  return this.http.get(this.apiUrl + "/getSettings");
}

getAccListDivision() {
  return this.http.get(this.apiUrl + "/getAccListDivision");
}

getAccDivList() {
  this.getAccListDivision().subscribe(data => {
    if (data['status'] == 'ok' && data['result'].length>0) {
      if (data['result'][0].ISSELECTALL == "%") {
        this.showAll = true;
      }
      else {
        this.showAll = false;
      }
    }
  })
}

changePassword(userData){
  let res= { status:"error", result:""};
  let returnSubject: Subject<any> = new Subject();
  const options = {headers:this.getRequestOption(), method:"post"}


  this.http.post(this.apiUrl+"/changeOldPassword",userData,options)
  .subscribe(
      (data: any) => {
          let retData = data;
          if (retData.status == "ok") {
            res.status = "ok";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          } else {
            res.status = "error1";
            res.result = retData.result;
            returnSubject.next(res);
            returnSubject.unsubscribe();
          }
        },
        error => {
          (res.status = "error2"), (res.result = error);
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
  );
  return returnSubject;
}
getBackup(){
  let res = {status: "error", result: ""};
  let returnSubject: Subject<any> = new Subject();
  let obj = {value:""}
  this.http.post(this.apiUrl + "/Backup/",obj)
  .subscribe(
    (data:any) => {
      let retdata = data;
      if(retdata.status == "ok"){
        res.status = "ok";
        res.result = retdata.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      else{
        res.status = "error1";
        res.result = retdata.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    },
    error =>{
     res.status = 'error';
     res.result = error;
     returnSubject.next(res);
     returnSubject.unsubscribe();
    }
  );
  return returnSubject;
}
// public getList(data, url) {
//   // console.log({ getlist: data });
//   const options = {headers:this.getRequestOption(), method:"post"}
//   return this.http
//     .post(this.apiUrl + url, data)
//     .map(data => data.json())
//     .share();
// }
//load PI voucher prodlist only for barcode print
public getPIForBarcodePrint(vchrno: string, division: string, phiscalID: string) {
  let  bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID };
  return this.http
    .post(this.apiUrl + '/getPIForBarcodePrint', bodyData)

}

getDetailsByUserDivision(userDivision) {
  return this.http.get(this.apiUrl + "/getDetailsByUserDivision/"+ userDivision);
}

deleteContractPrice(CMID:number, CUSTID:string){
  let res = {status: "error", result: ""};
  let returnSubject: Subject<any> = new Subject();
  this.http.get(this.apiUrl + "/deleteContractPricing/"+CMID+"/"+CUSTID)
  .subscribe(
    (data:any) => {
      let retdata = data;
      if(retdata.status == "ok"){
        res.status = "ok";
        res.result = retdata.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      else{
        res.status = "error1";
        res.result = retdata.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    },
    error =>{
     res.status = 'error';
     res.result = error;
     returnSubject.next(res);
     returnSubject.unsubscribe();
    }
  );
  return returnSubject;
}

authorizeSalesBelowCRate(authObj:any){
  let res={status:"error", result: ""};
  let returnSubject: Subject<any> = new Subject();
  const options = {headers:this.getRequestOption(), method:"post"}

  this.http.post(this.apiUrl + "/authorizeSalesBelowCrate", authObj,options).subscribe(
    (data:any) =>{
      let retData = data;
      if (retData.status == "ok") {
        res.status = "ok";
        res.result = retData.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      } else {
        res.status = retData.status;
        res.result = retData.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    },error =>{
      (res.status = "error"), (res.result = error);
              returnSubject.next(res);
              returnSubject.unsubscribe();
    });
    return returnSubject;
  }

  authorizeApprovedQtyWLessStock(authObj:any){
    let res={status:"error", result: ""};
  let returnSubject: Subject<any> = new Subject();
  const options = {headers:this.getRequestOption(), method:"post"}

  this.http.post(this.apiUrl + "/authorizeQuantityApproval", authObj,options).subscribe(
    (data:any) =>{
      let retData = data;
      if (retData.status == "ok") {
        res.status = "ok";
        res.result = retData.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      } else {
        res.status = retData.status;
        res.result = retData.result;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    },error =>{
      (res.status = "error"), (res.result = error);
              returnSubject.next(res);
              returnSubject.unsubscribe();
    });
    return returnSubject;
  }

  authorizeDiscountLimitInSO(authObj:any){
    let res={status:"error", result: ""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}
  
    this.http.post(this.apiUrl + "/authorizeSODiscountLimit", authObj,options).subscribe(
      (data:any) =>{
        let retData = data;
        if (retData.status == "ok") {
          res.status = "ok";
          res.result = retData.result;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        } else {
          res.status = retData.status;
          res.result = retData.result;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      },error =>{
        (res.status = "error"), (res.result = error);
                returnSubject.next(res);
                returnSubject.unsubscribe();
      });
      return returnSubject;
  }

  authorizeGblDiscountLimit(authObj:any){
    let res={status:"error", result: ""};
    let returnSubject: Subject<any> = new Subject();
    const options = {headers:this.getRequestOption(), method:"post"}
  
    this.http.post(this.apiUrl + "/authorizeGblDiscountLimit", authObj,options).subscribe(
      (data:any) =>{
        let retData = data;
        if (retData.status == "ok") {
          res.status = "ok";
          res.result = retData.result;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        } else {
          res.status = retData.status;
          res.result = retData.result;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      },error =>{
        (res.status = "error"), (res.result = error);
                returnSubject.next(res);
                returnSubject.unsubscribe();
      });
      return returnSubject;
  }
  
public getRefBillNoCount(REFBILLNO) {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  const options = {headers:this.getRequestOption()}
  this.http.get(`${this.apiUrl}/getRefBillNoCount?REFBILL=${REFBILLNO}`, options)
      .subscribe((response:any) => {
          let data = response;
          if (data.status == 'ok') {
              returnSubject.next(data);
              returnSubject.unsubscribe();

          }
          else {
              returnSubject.next(data)
              returnSubject.unsubscribe();
          }
      }, error => {
          res.status = 'error'; res.result = error;
          returnSubject.next(res);
          returnSubject.unsubscribe();
      }
      );
  return returnSubject;
}

getTransportDetail() {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  const options = {headers:this.getRequestOption()}
  this.http.get(this.apiUrl + '/getTransportDetail', options).subscribe((response:any) => {
    let data = response;
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
    else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;
}

getVehicleNoDetail() {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  const options = {headers:this.getRequestOption()}
  this.http.get(this.apiUrl + '/getVehicleNoDetail',options).subscribe((response:any) => {
    let data = response;
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
     else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;
}

getDriverNameDetail() {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  const options = {headers:this.getRequestOption()}
  this.http.get(this.apiUrl + '/getDriverNameDetail', options).subscribe((response:any) => {
    let data = response;
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
    else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;
}

getSaleableQTY(MCODE:string, DIV:string, PHISCALID:string, DATE: string|Date, WAREHOUSE: string){
  console.log("mcode",MCODE," div", DIV, " phiscal", PHISCALID, " date", DATE);
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  let bodyData = {
    MCODE: MCODE,
    DIV: DIV,
    PHISCALID: PHISCALID,
    DATE:DATE,
    WAREHOUSE: WAREHOUSE
  }
  const options = {headers:this.getRequestOption(), method: "post"}
  this.http.post(`${this.apiUrl}/getSaleableQtyforSO`, bodyData,options).subscribe((response:any) => {
    let data = response;
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
    else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;
}

  loadData_barcodewise(BARCODE: string, QUANTITY = 0, WAREHOUSE: string, PREFIX: string) {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getMenuitemBarCodeWise/?barcode=${BARCODE}&showstockqty=${QUANTITY}&warehouse=${WAREHOUSE}&prefix=${PREFIX}`)
      .subscribe(data => {
        if (data['status'] === 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }

  loadVoucherData_BCode_transferInWise(vchrno: string, division: string, phiscalID: string,mode:string='') {
    let bodyData: any;
    bodyData = { VCHRNO: vchrno, DIVISION: division, PHISCALID: phiscalID,MODE:mode }
    return this.http
      .post(this.apiUrl + '/loadVoucherData_BCode_TransferInWise', bodyData)
  }

  getWarehouseListByDivisionMapped(mappeddivision) {
    let bodyData: any;
    bodyData = { INITIAL: mappeddivision };
    return this.http
      .post(this.apiUrl + '/getWarehouseListByDivisionMapped', bodyData)
  }

  // public  getbatchSelectionList() {
  //   return this.http.get(this.apiUrl + "/ getbatchSelectionList")
  // }
  getbatchSelectionList() {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    this.http.get(`${this.apiUrl}/getBatchListinStockVariance`)
      .subscribe((response:any) => {
        let data = response;
        if (data.status == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();

        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
    return returnSubject;
  }

  getItemWiseBatch(mcode,warehouse){
    return this.http.get(this.apiUrl+'/getItemWiseBatch/'+mcode+'/'+warehouse);
  }

  getAdjustingBatch(mcode,warehouse){
    return this.http.get(this.apiUrl+'/getAdjustingBatch/'+mcode+'/'+warehouse);
  }

  public getManufacturerList() {
    return this.http
      .get(this.apiUrl + '/getManufacturerList');
  }

  public getCustomerType() {
    return this.http
      .get(this.apiUrl + '/getCustomerType')

  }

  public getMemberSchemeList() {
    return this.http
      .get(this.apiUrl + '/getAllMemberSchemeList')
  }


  public getpartyListTree() {
    if (this._accountPartyTree.length > 0) {
      return Observable.of(this._accountPartyTree);
    } else if (this.getBrandTreeObservable) {
      return this.getBrandTreeObservable;
    } else {
      var apList: any[] = [];
      console.log("arrived here")
      this.getBrandTreeObservable = this.http
        .get(this.apiUrl + "/getPartyListTree", {headers:this.getRequestOption()})
        .map(data => {
          this.getBrandTreeObservable = null;
          apList.push(<any>data);
          this._accountPartyTree = apList;
          return apList;
        })        
      return this.getBrandTreeObservable;
    }
  }



deleteAccount(mcode: string) {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  this.http.get(this.apiUrl + '/DeleteAccount/' + mcode, {headers:this.getRequestOption()}).subscribe((response:any) => {
    let data = response.json();
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
    else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;
}







getChildrenPartyAccount(PARENT){
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  this.http.get(this.apiUrl + '/getChildrenPartyAccount/' + PARENT, {headers:this.getRequestOption()}).subscribe((response:any) => {
    let data = response.json();
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
    else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;


}


public inActiveAccount(status, accode: any, ACID) {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  let opt = this.getRequestOption();
  let hd: Headers = new Headers({ "Content-Type": "application/json" });
  // let op: RequestOptions = new RequestOptions();
  let bodyData = { data:{ ISACTIVE: status, ACCODE: accode,ACID:ACID } };
  this.http
    .post(this.apiUrl + "/DisableActiveAccount", bodyData,{headers:this.getRequestOption()})
    .subscribe(
      (data:any) => {
        let retData = res;
        //data.json();
        if (retData.status == "ok") {
          res.status = "ok";
          res.result = retData.result;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        } else {
          res.status = "error1";
          res.result = retData.result;
          returnSubject.next(res);
          returnSubject.unsubscribe();
        }
      },
      error => {
        (res.status = "error2"), (res.result = error);
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    );
  return returnSubject;
}

public getacListTree() {
  if (this._accountTree.length > 0) {
    return Observable.of(this._accountTree);
  } else if (this.getBrandTreeObservable) {
    return this.getBrandTreeObservable;
  } else {
    this.getBrandTreeObservable = null;
    var atList: AcListTree[] = [];
    this.getBrandTreeObservable = this.http
      .get(this.apiUrl + "/getAccountTree",{headers:this.getRequestOption()} )
      .flatMap((res:any) => {
        if (res.status == 400) {
          return Observable.of([]);
        } else if (res.status == 200) {
          return res.json() || [];
        }
      })
      .map(data => {
        this.getBrandTreeObservable = null;
        atList.push(<AcListTree>data);
        this._accountTree = atList;
        return atList;
      })
      .share();
    return this.getBrandTreeObservable;
  }
}

getDistrict() {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  this.http.get(this.apiUrl + '/getDistrict').subscribe((response:any) => {
     let data = response;
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
    else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;
}

getParentNamebyID(partyID,mode) {
  console.log('partyid,',mode);
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  let parentObj = {MODE:mode,partyCode:partyID }
  this.http.post(this.apiUrl + `/getParentName`,parentObj).subscribe((response:any) => {
    let data = response;
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
    else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;
}

getAcidWisePartyList(ACID: string,sort:string = "") {
   
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  let bodydata = {ACID:ACID, sort:sort};
  this.http.post(this.apiUrl + '/getParentWiseAccountPagedList', bodydata,).subscribe((response:any) => {
    let data = response;
    if (data.status == 'ok') {
      returnSubject.next(data);
      returnSubject.unsubscribe();

    }
    else {
      returnSubject.next(data)
      returnSubject.unsubscribe();
    }
  }, error => {
    res.status = 'error'; res.result = error;
    returnSubject.next(res);
    returnSubject.unsubscribe();
  }
  );
  return returnSubject;
}

public validateMasterCreation(value) {

  if (this.userProfile.CompanyInfo.MAIN == 0 && this.userSetting.EnableCentralMasterCreation == 1) {
    this.alertService.warning("Sorry, Cannot " + value + " masters from other branch");
    return false;
  }
  else {
    return true;
  }
}
saveBankDetail(bodyData) {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  this.http.post(this.apiUrl + "/saveBankDetails", bodyData)
    .subscribe(
      (response:any) => {
        let data = response;
        if (data.status == "ok") {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        } else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      },
      error => {
        res.status = "error";
        res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    );
  return returnSubject;
}

public checkBarcode(barcode: string){     
  // alert("hhh")   
  let returnSubject: Subject<any> = new Subject();
  return this.http.get(`${this.apiUrl}/checkBarcodeForBarcodeMapping?barcode=${barcode}`);
  //.subscribe((response:any) =>{
//     let data = response;
//     if (data.status == 'ok') {
//       returnSubject.next(data);
//       returnSubject.unsubscribe();

//     }
//     else {
//       returnSubject.next(data)
//       returnSubject.unsubscribe();
//     }
//   }
//   );
//   return returnSubject; 
// }

}
public getItemUnit(mcode:string){
  return this.http.get(`${this.apiUrl}/getAllUnitOfItemForBarcodeMapping?mcode=${mcode}`)
}

saveBarcode(data:any){
  let res = {status: "error", result: ""};
  let manualSubject: Subject<any> = new Subject();
  const options = {header:this.getRequestOption(), method:"post"}
  this.http.post(this.apiUrl + '/saveBarcodeMappingMaster', data).subscribe((data:any)=>{
  
      if(data.status == 'ok'){
          manualSubject.next(data);
          manualSubject.unsubscribe();
      }
      else{
          manualSubject.next(data);
          manualSubject.unsubscribe();
      }
  },error => {
      res.status = 'error'; res.result = error;
      manualSubject.next(res);
      manualSubject.unsubscribe();
  }
  )
  return manualSubject;
}

public getConProdList(mcode:string,division:string,warehouse:string,quantity:number,mode:string,vchrno){
  return this.http.get(this.apiUrl + `/getConsumptionProdList?mcode=${mcode}&division=${division}&warehouse=${warehouse}&quantity=${quantity}&mode=${mode}&vchrno=${vchrno}`,)
}

public getPaymentGateWayInfo(amount:number, guid:string, companyid: string, paymentmode: string){
  let bodyData : any;
  bodyData = {AMOUNT : amount, guid : guid, companyId: companyid, PaymentMode: paymentmode}
  return this.http.post(this.apiUrl + '/GetPaymentGateWayInfo', bodyData);
}

saveDenomination(bodyData) {
  let res = { status: "error", result: "" };
  let returnSubject: Subject<any> = new Subject();
  this.http.post(this.apiUrl + "/saveDenomination", bodyData)
    .subscribe(
      (response:any) => {
        let data = response;
        if (data.status == "ok") {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        } else {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
      },
      error => {
        res.status = "error";
        res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
    );
  return returnSubject;
}

 getDetailsforchartjs( date:string, divison:string, fyid:string) {
     return this.http.get(this.apiUrl + `/getDashBoardDebtorsCreditorsValue?date=${date}&div=${divison}&fyid=${fyid}`);
  }
   getdashboardmaindata(date:string, divison:string, fyid:string) {
        return this.http.get(this.apiUrl + `/getDashBoardMainData?date=${date}&div=${divison}&fyid=${fyid}`);
  }
  
  getPurchaseSalesdataforchart(date:string, divison:string, fyid:string, reportmode:number) {
        return this.http.get(this.apiUrl + `/getPurchaseSalesReport?date=${date}&div=${divison}&fyid=${fyid}&reportmode=${reportmode}`);
  }
  
  getDashboardStockPositiondataforchart(date:string, divison:string, fyid:string, reportmode:number) {
        return this.http.get(this.apiUrl + `/getDashboardStockPosition?date=${date}&div=${divison}&fyid=${fyid}&reportmode=${reportmode}`);
  }
  
  // getReceivePaymentforchart(date:string, divison:string, fyid:string) {
  //       return this.http.get(this.apiUrl + `/getDashBoardReceivePayment?date=${date}&div=${divison}&fyid=${fyid}`);
  // }

//   getReceivePaymentforchart(date:string, divison:string, fyid:string, reportmode:number) {
//     return this.http.get(this.apiUrl + `/getDashboardTopSalesByValue?date=${date}&div=${divison}&fyid=${fyid}&reportmode=${reportmode}`);
// }

  getDashboardTopSalesByQtyforchart(date:string, divison:string, fyid:string, reportmode:number) {
    return this.http.get(this.apiUrl + `/getDashboardTopSalesByQty?date=${date}&div=${divison}&fyid=${fyid}&reportmode=${reportmode}`);
}

getDashboardTopSalesByValueforchart(date:string, divison:string, fyid:string, reportmode:number) {
  return this.http.get(this.apiUrl + `/getDashboardTopSalesByValue?date=${date}&div=${divison}&fyid=${fyid}&reportmode=${reportmode}`);
}



  getDashboardSalesContributionforchart(date:string, divison:string, fyid:string, reportmode:number) {
    return this.http.get(this.apiUrl + `/getDashboardSalesContribution?date=${date}&div=${divison}&fyid=${fyid}&reportmode=${reportmode}`);
}
}
