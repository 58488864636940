<alert></alert>
<spinner></spinner>
<!-- nav -->
<div class="divbar fixed-top" *ngIf="currentUser">
    <div class="navbar-nav">
        <navigation></navigation>
    </div>
</div>

<!-- main app container -->
<div class="clearfix app-container">
    <router-outlet></router-outlet>
</div>


<div class="footer" *ngIf="currentUser">
    <app-footer></app-footer>
</div>